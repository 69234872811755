import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';
import {
  ORDER_STATUS_PAYMENT_REQUIRED, ORDER_STATUS_UNFULFILLED, ORDER_STATUS_FULFILLED, ORDER_STATUS_CANCELLED, ORDER_STATUS_ALL,
} from '../../constants';

function OrderFilters(props) {
  const { onFilter, activeFilter } = props;

  const isActive = (currentFilter) => {
    if (activeFilter === currentFilter) {
      return true;
    }
    return false;
  };

  return (

    <div className="page-header__filters">
      <Button className={isActive(ORDER_STATUS_PAYMENT_REQUIRED) ? 'active' : ''} onClick={() => onFilter(ORDER_STATUS_PAYMENT_REQUIRED)}>Payment Required</Button>
      <span className="mr-10">|</span>
      <Button className={isActive(ORDER_STATUS_UNFULFILLED) ? 'active' : ''} onClick={() => onFilter(ORDER_STATUS_UNFULFILLED)}>Unfulfilled</Button>
      <span className="mr-10">|</span>
      <Button className={isActive(ORDER_STATUS_FULFILLED) ? 'active' : ''} onClick={() => onFilter(ORDER_STATUS_FULFILLED)}>Fulfilled</Button>
      <span className="mr-10">|</span>
      <Button className={isActive(ORDER_STATUS_CANCELLED) ? 'active' : ''} onClick={() => onFilter(ORDER_STATUS_CANCELLED)}>Cancelled</Button>
    </div>

  );
}

OrderFilters.propTypes = {
  onFilter: PropTypes.func,
  activeFilter: PropTypes.string,
};

OrderFilters.defaultProps = {
  onFilter: () => {},
  activeFilter: '',
};

export default OrderFilters;
