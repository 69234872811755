import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import PlanWrapper from 'components/modals/plans/PlanWrapper';
import Link from '@material-ui/core/Link';
import { profileOperations } from '../../../state/ducks/profile';

function StandaloneOnboardingStepTwo(props) {
  const {
    profile, updateShop, updatePlan, nextStepStandalone,
  } = props;

  const handleFree = (id) => {
    updateShop(profile.shop[0].id, { onboarding_step: 3, plan: id, subscription_status: 'active' })
      .then(() => {
        // nextStepStandalone();

        // updatePlan(id, 'PENDING');
        // handleClose();
        // history.push('/?onboarding_tour=standalone');
        // window.location.href = '/?onboarding_tour=standalone';
      });
  };

  const handlePaid = (id) => {
    updateShop(profile.shop[0].id, { onboarding_step: 2, plan: id, subscription_status: 'pending' })
      .then(() => {
        // history.push(`/signup?plan_id=${plan}`);

        // updatePlan(id, 'PENDING');
      });
  };

  return (
    <div>

      <div className="pick-plan-container">
        <Typography className="text--bold text--nunito text__try-free-plan">Try any plan free for 14 days!</Typography>

        <Typography className="text--bold text__pick-plan text--georgia" variant="h4" color="secondary">Pick Your Plan</Typography>
      </div>

      <div className="plan-wrapper-container-standalone">
        <PlanWrapper />
      </div>

      <div className="try-free-container display--flex flex-row-center pt-20">
        <Typography className="text--fs-13 pr-15 text--blue text--georgia text--bold text--upper">
          Free Plan
        </Typography>

        <li style={{ color: '#246071' }}><span className="text--fs-12  text--blue text--nunito">Create 10 unbranded products</span></li>

      </div>

      <Link style={{ cursor: 'pointer', textTransform: 'uppercase', textDecoration: 'underline' }} onClick={() => handleFree(1)} className="text--fs-12 text--bold text--nunito text--blue text-upper">
        Get Started
        <i className="fas fa-chevron-right" />
      </Link>

    </div>
  );
}

StandaloneOnboardingStepTwo.propTypes = {
  profile: PropTypes.objectOf(),
  updateShop: PropTypes.func,
  updatePlan: PropTypes.func,
  nextStepStandalone: PropTypes.func,
};

StandaloneOnboardingStepTwo.defaultProps = {
  profile: {},
  updateShop: () => {},
  updatePlan: () => {},
  nextStepStandalone: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updateShop: profileOperations.updateShop,
  updatePlan: profileOperations.updatePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneOnboardingStepTwo);
