import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import {
  Redirect,
  NavLink,
} from 'react-router-dom';
import {Helmet} from "react-helmet";

import LoadingButton from '../../components/buttons/loadingButton';
import { useHistory } from 'react-router-dom';
import { profileOperations } from '../../state/ducks/profile';
import { sessionOperations } from '../../state/ducks/session';
import Logo from '../../images/logo.png';

function Login(props) {
  const {
    login, session, getProfile,setToken,logout
  } = props;
  const history = useHistory();

  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  //clear token
  useEffect(() => {

    //window.history.replaceState(null, "Login", "/login")
    logout();

    try {
      const { access_token: accessToken,  refresh_token: refreshToken, redirect_url:redirect_url } = queryString.parse(props.location.search);
      
      if (accessToken) {

          setToken(accessToken, refreshToken);
          localStorage.removeItem('items');
      
      } else {
        localStorage.removeItem('items');
     
        const token = JSON.parse(localStorage.getItem('token'));

        if (token?.access_token && token?.refresh_token) {
            setToken(token.access_token, token.refresh_token)

        } else {
            setError(true);
        }
      }
      getProfile().then(response=>{
        console.log(response);
      });

  } catch (e) { // catches a malformed URI
      //console.error(e);
      setError(true);
      
  }


}, []);
  

  const loginSubmit = (event) => {

    const { redirect_url:redirect_url } = queryString.parse(props.location.search);

    event.preventDefault();
    setError('');
    setLoading(true);

    login({ username, password })
      .then((response) => {
        if (response && response.status === 200) {
          //setLoading(false);

          // get profile after login
          getProfile();

          //redirect or go home
          redirect_url ? history.push(redirect_url) : history.push('/');

        } else {
          setError('Incorrect email or password.');
          setLoading(false);
        }
      });
  };

  // dougs implementation
  const formIsValid = () => {
    if (username === '' || password === '' || password.length < 6) {
      return false;
    }
    return true;
  };

  if (session.isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="template-login">

        <Helmet>     
          <title>login - blanka</title>
          <meta name="description" content="Official app login page app for Shopify, WooCommerce and more"/>
        </Helmet>

      <Card className="card" variant="outlined">

        <div className="welcome pb-5">
          <Typography className="text--bold text--nunito text--upper" variant="h4">Welcome To </Typography>
          <img
            src={Logo}
            alt="Logo"
            className="logo"
          />
        </div>

        <div className="header-text-container">
          <Typography className="text__login-message text--nunito">Please login to access the app.</Typography>
          <NavLink to="/signup" className="text__sign-up text--nunito">
            Or sign up for free!
          </NavLink>
        </div>

        <form id="login-form" className="form" onSubmit={loginSubmit}>
          <div className="input-fields">
            <TextField
              className="form-input"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              required
              label="Email/Shop"
              id="username"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="input-fields">
            <TextField
              className="form-input"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              id="password"
              variant="outlined"
              required
              label="Password"
              InputLabelProps={{ shrink: true }}
            />
          </div>


          

          <div className="reset-password-conatiner">
            <Typography className="text__reset-password text--nunito">Forgot your password?</Typography>
            <NavLink to="/password-reset" className="text__reset-password text--nunito">
              Reset it here.
            </NavLink>
            

            
          </div>

          <div className="login-button-container">
            <LoadingButton
              loading={loading}
              disabled={!formIsValid()}
              style={{ width: '300px' }}
              className="button__login button button--blue"
              variant="outlined"
              type="submit"
            >
              Login
            </LoadingButton>
          </div>
        </form>
        <p className="text--nunito text--error"> { error }</p>

        <Typography className="text__notice text--nunito">
        <b> Already using blanka with Shopify? </b> Our new login account system 
        requires you to first set a password in the app. 
        For steps on how to do this, 
        read our FAQ <a target="_blank" href="http://faq.blankabrand.com/en/articles/5622576-how-do-i-log-into-blanka">here.</a>
          </Typography>

      </Card>

    </div>
  );
}

Login.propTypes = {
  login: PropTypes.func,
  session: PropTypes.object,
};

Login.defaultProps = {
  login: () => {},
  session: {},
};

const mapStateToProps = (state) => ({
  session: state.session.session,
});

const mapDispatchToProps = {
  login: sessionOperations.login,
  logout: sessionOperations.logout,
  setToken: sessionOperations.setToken,
  clearToken: sessionOperations.clearToken,
  clearError: sessionOperations.clearError,
  getProfile: profileOperations.getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
