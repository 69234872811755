import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import PlanModalCard from './plans/PlanModalCard';
import { growthPlanDetials, vipPlanDetails, scalePlanDetails } from "components/modals/plans/index";

import { profileOperations } from '../../state/ducks/profile';
import { SHOP_ONBOARDING_SHOPIFY } from '../../constants';
import {notifications } from '../../components/notifications';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 'auto',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
}));

function FreePlanLimitModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    handleClose, open, updatePlan, profile, getProfile,
  } = props;

  const handlePaid = (id) => {
    // check if user has their billing details set
    if(!profile?.shop[0].billing_address) {
      //history.push('/profile/billing?needs_update=true');
      notifications.noBillingMethodError();

      return;
    }

    // if billing details are set upgrade plan
    updatePlan(id).then((response) => {
      if (response && response.status === 200 && profile.shop[0] && profile.shop[0].shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
        window.location.href = response.data.confirmation_url;
      } else if (response && response.status === 200 && profile.shop[0]) {
        // TODO: check to see if upgraded or downgraded
        getProfile().then((response) => {
          toast.success('Success! Your account has been updated');
          handleClose();
        });
      }
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Free Plan Limit Modal"
        aria-describedby="Free Plan Limit Modal"
        className={clsx(classes.modal, 'plan-limit-modal')}
      >

        <div className={classes.paper}>

          <Typography className="text--bold text__header" color="secondary">Whoops! You’ve reached your FREE plan limit.</Typography>

          <Typography onClick={handleClose} className="close-button modal-close-button">[x] close</Typography>

          <p className="text__body text--nunito">
            Sorry! Select any paid plan below to brand products.
          </p>

          <p className="text--bold text--nuntio text__try-free">
            Try a 14-day trial for free now!
          </p>

          <div className="modal__plans pb-40">
            <PlanModalCard isModal={true} handleClose={handleClose} planName="GROWTH PLAN" planDetails={growthPlanDetials()} planNumber={2} planPrice="$29/mo" />

            <PlanModalCard style={{ marginTop: '-12px' }} isModal={true} handleClose={handleClose} centerClassName="center" centerTitleText="text__title__center" planName="SCALE PLAN" planDetails={scalePlanDetails()} planNumber={3} planPrice="$59/mo" />

            <PlanModalCard isModal={true} handleClose={handleClose} planName="VIP PLAN" planDetails={vipPlanDetails()} planPrice="$99/mo" planNumber={4} />
          </div>

        </div>

      </Modal>
    </div>
  );
}

FreePlanLimitModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  updatePlan: PropTypes.func,
};

FreePlanLimitModal.defaultProps = {
  handleClose: () => {},
  open: false,
  updatePlan: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  updateShop: profileOperations.updateShop,
  getProfile: profileOperations.getProfile,
  updatePlan: profileOperations.updatePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(FreePlanLimitModal);
