import React, { useEffect } from "react";
import { connect } from "react-redux";
import {  makeStyles } from '@material-ui/core/styles';
import { cartOperations } from "../../state/ducks/cart";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TotalsArea from './totalsArea'
import CreditCardForm from '../../components/creditCardForm';
import LoadingButton from '../../components/buttons/loadingButton';

import Card from '@material-ui/core/Card';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        justifyContent: 'left'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    header:{
        borderBottom: "1px solid " + theme.palette.gray,
        marginBottom: "50px",
    },
  
    button:{
        marginTop: '25px'
    },
    text:{
        textAlign: 'left',
        color: theme.palette.gray,
    },
    card:{
        padding: '40px',
        display: 'flex',
        flexDirection:'column',
        alignItems:'flex-start',


    },
    paymentInfo:{
        marginTop: '25px'
    }

}));

function CheckoutBilling(props) {
    const classes = useStyles();
    const { error, clearError, loading,  addShipping,nextStep,clearCart,shipping, items, profile,handleConfirmCheckout } = props;
    const history = useHistory();   
    //const [loading, setLoading] = React.useState(false);


    useEffect(() => {
        if(!shipping.first_name||!shipping.last_name){
            history.push("/checkout/shipping");
        }
    }, []);


    const handleSubmit = () =>{
        addShipping(shipping);
        nextStep();
    }

    const handleCheckout = () =>{

    }

    const shippingValid = () =>{
        if(!shipping.first_name||!shipping.last_name||!shipping.address_one||!shipping.city||!shipping.country||!shipping.zip_code){
            return false   
        }       
        return true
    }




    return (
        <div className={classes.root, 'samples-checkout'}>

            <Card className={classes.card} variant="outlined">

                <div className="shipping-card-left">
                <Typography variant="h5" color="secondary">Payment Information</Typography>   
                <Typography variant="body" color="secondary">All transactions are secure and encrypted</Typography>

          
                        <div className={classes.paymentInfo}>
                    
                        
                            <Typography variant="h6" color="secondary">Credit Card Number</Typography>

                            {profile?.shop[0]?.billing_address &&
                                <div className="cc-display">
                                    XXXX XXXX XXXX {profile?.shop[0]?.billing_address.credit_card_digits}
                                </div>
                            }

                            {!profile?.shop[0]?.billing_address &&
                                <CreditCardForm ></CreditCardForm>
                            }

                            <Typography style={{marginTop:'5px'}} variant="h6" color="secondary">To Update your credit card visit <Link to="/profile/billing">My Account</Link></Typography>
                        </div>

                </div>
                <div className="shipping-card-right">

                    <TotalsArea shipping={shipping} items={items} ></TotalsArea>
                    
                </div>

            </Card>

            <div className="checkout__actions">

                <div className="checkout__breadcrumbs">
                        <Link to="/checkout">Cart </Link>
                        <ChevronRightIcon></ChevronRightIcon>
                        <Link to="/checkout/shipping" >Information </Link>
                        <ChevronRightIcon></ChevronRightIcon>
                        <p className="text--bold">Payment</p>
                    </div>

                <div className="checkout-button-container">
                    <LoadingButton loading={loading} disabled={!profile?.shop[0]?.billing_address} onClick={handleConfirmCheckout} variant="contained" color="primary" className=" button--primary"  >Complete Purchase</LoadingButton>
                </div>

            </div>

           
        </div>
    );
}

CheckoutBilling.defaultProps = {
    shipping: {},
    isLoading: false,
    hasErrored: false,
    profile: {},
};

const mapStateToProps = (state) => ({
    shipping: state.cart.shipping.shipping,
    profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
    addShipping : cartOperations.samplesAddShipping,
    clearCart: cartOperations.samplesClearCart,
    checkout: cartOperations.samplesCheckout,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBilling);
