import React, { useState } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function MyAccountIcon(props) {
  const { hover } = props;
  return (
    <>
      <SvgIcon {...props} className="icon icon--hover" viewBox="0 0 20 20">
        <path
          id="_xD83D__xDD39_-Icon-Color"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#5E5E5E"
          d="M9,2.1c1.3,0,2.4,1,2.4,2.4
            s-1,2.4-2.4,2.4s-2.4-1-2.4-2.4S7.7,2.1,9,2.1z M9,12.3c3.4,0,6.9,1.7,6.9,2.4v1.2H2.1v-1.2C2.1,13.9,5.6,12.3,9,12.3z M9,0
            C6.5,0,4.5,2,4.5,4.5S6.5,9,9,9s4.5-2,4.5-4.5S11.5,0,9,0z M9,10.1c-3,0-9,1.5-9,4.5V18h18v-3.4C18,11.6,12,10.1,9,10.1z"
        />
        <circle fillRule="evenodd" clipRule="evenodd" fill="#E88A80" cx="9" cy="4.5" r="2.4" />
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">
        <path
          id="_xD83D__xDD39_-Icon-Color"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#5E5E5E"
          d="M9,2.1c1.3,0,2.4,1,2.4,2.4
	s-1,2.4-2.4,2.4s-2.4-1-2.4-2.4S7.7,2.1,9,2.1z M9,12.3c3.4,0,6.9,1.7,6.9,2.4v1.2H2.1v-1.2C2.1,13.9,5.6,12.3,9,12.3z M9,0
	C6.5,0,4.5,2,4.5,4.5S6.5,9,9,9s4.5-2,4.5-4.5S11.5,0,9,0z M9,10.1c-3,0-9,1.5-9,4.5V18h18v-3.4C18,11.6,12,10.1,9,10.1z"
        />
      </SvgIcon>
    </>
  );
}

export default MyAccountIcon;
