import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useQueryParam } from '../../hooks/useQueryParam';
import { productOperations } from '../../state/ducks/products';

import ProductItem from './productItem';

function SupplierProductsList(props) {
  const query = useQueryParam();
  const { products } = props;
  const [selectedSku, setSelectedSku] = useState(null);

  useEffect(() => {
    if (query.get('product')) {
      setSelectedSku(query.get('product'));
    }
  }, []);

  const isOpen = (sku) => {
    if (selectedSku && sku.includes(selectedSku)) {
      console.log(selectedSku);
      return true;
    }
    return false;
  };

  // Need to reset selected sku so we dont keep showing the modal
  const resetSelectedSku = () => {
    setSelectedSku(null);
  };

  return (
    <>
      {products.map((product, index) => (
        <Grid item xs={12} md={6} lg={3} key={product.id}>
          <ProductItem product={product} isOpen={isOpen(product.sku)} resetSelectedSku={resetSelectedSku} />
        </Grid>
      ))}
    </>
  );
}

SupplierProductsList.propTypes = {

  products: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
};

SupplierProductsList.defaultProps = {
  products: [],
};

const mapStateToProps = (state) => ({
  products: state.products.getSupplierProducts.products.results,
  isLoading: state.products.getSupplierProducts.loading,
});

const mapDispatchToProps = {
  getSupplierProducts: productOperations.getSupplierProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductsList);
