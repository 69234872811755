const middlewareConfig = {
    interceptors: {
        request: [{
            success: function ({getState, dispatch, getSourceAction}, req) {
                return req;
            },
            error: function ({getState, dispatch, getSourceAction}, error) {
                return error;
            }
        }
        ],
        response: [{
            success: function ({getState, dispatch, getSourceAction}, req) {
                return req;
            },
            error: function ({getState, dispatch, getSourceAction}, error) {
                return error;
            }
        }
        ]
    }
};

export default middlewareConfig;
