import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {  makeStyles } from '@material-ui/core/styles';
import { orderOperations } from "../../state/ducks/orders";
import { cartOperations } from "../../state/ducks/cart";
import {  toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { profileOperations } from '../../state/ducks/profile';

import { brandPlateSuccess, NoLogoError} from "../../components/notifications"
import FreePlanLimitModal from "../../components/modals/freePlanLimitModal";

import BrandPlateImage from '../../images/brand-plate-mockup.jpg';
import BrandPlateLipstickImage from '../../images/blanka-lipstick-your-logo.png';
import YourLogo1 from '../../images/Your-logo-1-color.png';
import YourLogo2 from '../../images/Your-logo-2-color.png';
import YourLogo3 from '../../images/Your-logo-3-color.png';

import LoadingButton from "../../components/buttons/loadingButton";

import {PLAN_FREE_ID,CUSTOM_PRODUCT_STATUS_IN_PROGRESS, BRAND_PLATE_COST} from '../../constants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        textTransform: 'uppercase',
        color: '#5e5e5e',
        fontSize: '8px',
        fontWeight: '100',
        letterSpacing: '2.4px',
        cursor: 'pointer',
        top: '12px',
        right: '9px',
    },
    dialogContainer: {
        alignItems: 'center',
    },
}));

function BrandPlate(props) {
    const classes = useStyles();
    const { orderBrandPlate, profile,  getProfile } = props;
    const [open, setOpen] = useState(false);
    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [brandPlateType, setBrandPlateType] = React.useState();

  
    useEffect(() => {
        if(profile?.shop[0].plan === PLAN_FREE_ID){
            setUpgradeModalOpen(true)
        }
    }, []);
  

    const handleChange = (event) => {
      setBrandPlateType(event.target.value);
    };

    const handleClickOpen = () => {
    
        if(profile?.shop[0].logo_uploaded){
            setOpen(true);
        }else{
            toast.error(NoLogoError);
        }

       
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleCheckout = () => {
        setOpen(false);

        setIsLoading(true);
        orderBrandPlate(brandPlateType)
        .then((response)=>{
            setIsLoading(false);
            if(response && response.status==201){
                toast.success(brandPlateSuccess);
                getProfile()
            }
        }).catch(err=>{
            setIsLoading(false);
        });
    }


    const isFreePlan = () => {
        if(profile?.shop[0].plan===PLAN_FREE_ID){
            return true;
        }
        return false;
    }

    const hasBrandPlatePurchase = (type,purchases ) =>{
        if (purchases && purchases.includes(type)){
            return true
        }
        return false
    }
    
    return (
        <div className="brand-plate">

            <Grid className="page-header mb-25" container >
                <Typography className="pb-25" variant="h6">My Brand Plate</Typography>
            </Grid>

            <Grid className={classes.paper} container>
                
                <Grid item xs={12} md={12} className="text--left">

                    <Typography variant="h4" className="text__title text--goergia text--upper">
                        Your brand's custom printing plate
                    </Typography>
                    <Typography className="text__subtitle text--nunito text--upper">
                        One, two or multi-color options!
                    </Typography>

                </Grid>


                <Grid item xs={12} md={6}>
                
                    <img src={profile?.shop[0].brand_plate_created ? profile.shop[0].logo : BrandPlateImage} className="img"></img>

                    <img src={BrandPlateLipstickImage} className="img"></img>
                    

                </Grid>
                <Grid item xs={12} md={6}>

                    

                    {!isFreePlan() &&
                        <>
                            <div className="brandplate__options-title">
                                <Typography className="row__header text--nunito text--upper">
                                    Select option
                                </Typography>
                                <Typography className="row__header text--nunito text--upper">
                                    1 time fee
                                </Typography>
                                <Typography className="row__header--hidden">
                                    hidden
                                </Typography>
                            </div>


                            <div className="brandplate__options">


                                <RadioGroup aria-label="Brand Plate Type" name="gender1" value={brandPlateType} onChange={handleChange}>
                                                    
                                    <div className="radio-row">
                                        <FormControlLabel  value="ONE_COLOR" control={<Radio />} label=""/>
                                        <div className="color-type">
                                            <Typography className="row__subtitle text--nunito text--upper">
                                                1 color
                                            </Typography>
                                            <Typography variant="h4" className="row__title text--nunito text--upper">
                                                $149 USD
                                            </Typography>                                  
                                        </div>
                                        <div className="radio__logo">
                                        <img src={YourLogo1} className="row__img"></img>
                                        
                                        </div>                
                                    </div>
                                    <div className="radio-row">
                                        <FormControlLabel value="TWO_COLOR" control={<Radio />} label=""/>
                                        <div className="color-type">
                                            <Typography className="row__subtitle text--nunito text--upper">
                                            2 COLOR
                                            </Typography>
                                            <Typography variant="h4" className="row__title text--nunito text--upper">
                                                $199 USD
                                            </Typography>                                  
                                        </div>
                                        <div className="radio__logo">
                                        <img src={YourLogo2} className="row__img"></img>
                                        </div>                
                                    </div>
                                    <div className="radio-row">
                                        <FormControlLabel  value="MULTI_COLOR" control={<Radio />} label=""/>
                                        <div className="color-type">
                                            <Typography className="row__subtitle text--nunito text--upper">
                                                MULTI-COLOR
                                            </Typography>
                                            <Typography variant="h4" className="row__title text--nunito text--upper">
                                                $249 USD
                                            </Typography>                                  
                                        </div>
                                        <div className="radio__logo">
                                            <img src={YourLogo3} className="row__img"></img>
                                        </div>                
                                    </div>
                                
                                </RadioGroup>
                            
            
                        
                                <div className="brand-plate__actions">
                                    <LoadingButton loading={isLoading} disabled={!profile?.shop[0].billing_address||isFreePlan()||!brandPlateType||isLoading||isFreePlan(profile)} onClick={handleClickOpen} style={{ width: '315px' }} variant="outlined" color="primary" className="button--primary">Order Brand Plate</LoadingButton>
                                
                                    {!profile?.shop[0].billing_address && 
                                        <Typography className="text__invalid-billing text--nunito" variant="body1">Please enter your billing details in the account page to order a brand plate</Typography>
                                    }
                                </div>
                                

                            </div>
                        </>
                    }

                    {isFreePlan() &&
                    <div className="free-plan-upgrade">
                        <Typography variant="h5" color="primary" className="text__subtitle text--nunito text--upper text--peach text--bold">
                       Whoops!
                        </Typography>
                        <Typography variant="body1" color="primary" className="text__subtitle text--nunito text--peach">
                        In order to purchase a brand plate, you must be on a paid plan.
                        </Typography>


                        <LoadingButton  onClick={()=>setUpgradeModalOpen(true)} style={{ width: '315px' }} variant="outlined" color="primary" className="button--primary">Upgrade Plan</LoadingButton>
                    </div>
                    }



                    <div className="text--left brand-plate__content">
                     

                        <Typography variant="body1" className="text--bold text--nunito">
                            What's a brand plate?
                        </Typography>

                        <Typography variant="body1" className="text--nunito pt-15">
                            A brand plate is your first step to putting your logo onto products.  
                            Before you can place your private label order, you must order a logo plate design.  
                            The plate is a  piece of carefully created metal with your logo cut out of it in up to 4 different sizes to fit on 
                            various products.
                        </Typography>

                        <Typography variant="body1" className="text--nunito pt-15">
                            The brand plate is a necessary piece of your inventory order and only needs to be purchased once! 
                            From here, you can brand as many products as your plan allows. 
                            The only time you will need a new brand plate is if you change your logo.
                        </Typography>

                
                        <Typography variant="body1" className="text--bold text--nunito pt-15">
                            What are my color options?
                        </Typography>


                        <Typography variant="body1" className="text--nunito pt-15">
                            1 color = any single solid color including black or white<br></br>
                            2 color = any 2 single solid colors including black or white<br></br>
                            Multi-color = any combination of solid colors
                        </Typography>

                        <Typography variant="body1" className="text--nunito pt-15">
                        Congrats on the first step of building your amazing brand!
                        </Typography>

                    </div>


                </Grid>
            </Grid>

            <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    alignItems: 'center',
                    borderRadius: '0px',
                }
            }}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className=""
            >
                <div className="flex-column-center dialog dialog--brand-plate-confirm">
                    <Typography onClick={handleClose} className={classes.closeButton}>[x] close</Typography>
                    <DialogTitle className="pt-20 text--fs-21 text--georgia text--bold text--medium-gray" id="alert-dialog-slide-title">
                    Confirm your brand plate purchase!
                    </DialogTitle>
                    <DialogContent>
                        <div className="dialog-content-container">
                            <DialogContentText className="text--nunito" id="alert-dialog-slide-description">
                            Your credit card on file will be charged ${BRAND_PLATE_COST[brandPlateType]} immediately and your order will be processed.
                            </DialogContentText>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton variant="contained" color="primary" className="button--primary" style={{ width: '300px' }} onClick={handleCheckout} >
                            PLACE ORDER
                            <i className="fas fa-chevron-right" />
                        </LoadingButton>
                    </DialogActions>
                </div>
            </Dialog>
            
            <FreePlanLimitModal handleClose={() => setUpgradeModalOpen(false)} open={upgradeModalOpen} profile={profile}/>
        </div>
    );
}

BrandPlate.defaultProps = {
    orders: [],
    isLoading: false,
    hasErrored: false,
    profile: {}
};

const mapStateToProps = (state) => ({
    orders: state.orders.getOrders.orders,
    profile: state.profile.getProfile.profile,
    //error: state.orders.getBrandPlate.error,
    //loading: state.orders.getBrandPlate.loading,
});

const mapDispatchToProps = {
    getOrders: orderOperations.getOrders,
    getProfile: profileOperations.getProfile,
    orderBrandPlate : cartOperations.orderBrandPlate
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandPlate);

