import React, { useState } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function HelpIcon(props) {
  const { hover } = props;
  return (
    <>
      <SvgIcon {...props} className="icon icon--hover" viewBox="0 0 20 20">
        <g id="Icons">
          <g id="Two-Tone" transform="translate(-238.000000, -1306.000000)">
            <g id="Communication" transform="translate(100.000000, 1162.000000)">
              <g id="Two-Tone-_x2F_-Communication-_x2F_-textsms" transform="translate(136.000000, 142.000000)">
                <g>
                  <path
                    id="_xD83D__xDD39_-Primary-Color"
                    className="st0"
                    style={{ 'fill-rule': 'evenodd', 'clip-rule': 'evenodd', fill: '#5E5E5E' }}
                    d="M20,2H4C2.9,2,2,2.9,2,4v18l4-4h14c1.1,0,2-0.9,2-2V4
						C22,2.9,21.1,2,20,2z M20,16H6l-2,2V4h16V16z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <rect x="5" y="7" className="st1" width="2" height="2" style={{ 'fill-rule': 'evenodd', 'clip-rule': 'evenodd', fill: '#D56F5B' }} />
        <rect x="9" y="7" className="st1" width="2" height="2" style={{ 'fill-rule': 'evenodd', 'clip-rule': 'evenodd', fill: '#D56F5B' }} />
        <rect x="13" y="7" className="st1" width="2" height="2" style={{ 'fill-rule': 'evenodd', 'clip-rule': 'evenodd', fill: '#D56F5B' }} />
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">
        <g id="Icons">
          <g id="Two-Tone" transform="translate(-238.000000, -1306.000000)">
            <g id="Communication" transform="translate(100.000000, 1162.000000)">
              <g id="Two-Tone-_x2F_-Communication-_x2F_-textsms" transform="translate(136.000000, 142.000000)">
                <g>
                  <path
                    id="_xD83D__xDD39_-Primary-Color"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#5E5E5E"
                    d="M20,2H4C2.9,2,2,2.9,2,4
						        v18l4-4h14c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M20,16H6l-2,2V4h16V16z M7,9h2v2H7V9z M11,9h2v2h-2V9z M15,9h2v2h-2V9z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </SvgIcon>
    </>
  );
}

export default HelpIcon;
