import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {toast} from 'react-toastify';

import { sessionOperations } from '../../state/ducks/session';
import { profileOperations } from '../../state/ducks/profile';
import LoadingButton from '../../components/buttons/loadingButton';

function SignUpForm(props) {
  useEffect(() => {
    if (profile?.name && profile?.email) {
      setUser({ ...user, first_name: profile.first_name, last_name: profile.last_name, email: profile.email });
    }
  }, []);

  const {
    profile,
    createProfile,
    updateUser,
    login,
    changePassword,
    updateShop,
    getProfile,
    nextStepShopify,
    nextStepStandalone,
  } = props;

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    accepts_terms: false,
  });

  const mediumRegex = new RegExp(
    '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
  );

  const [loading, setLoading] = useState(false);
  const [passwordState, setPasswordState] = useState(false);
  const [error, setError] = useState('');
  const [fieldError, setFieldError] = useState();

  const handlePasswordChange = (event) => {
    setUser({ ...user, password: event.target.value });

    if (!mediumRegex.test(event.target.value)) {
      setError('Password strength too low');
      return false;
    }

    if (mediumRegex.test(event.target.value)) {
      setPasswordState(true);
      setError('');
    }
  };

  const handleSubmitShopify = () => {
    //Change User Password
    changePassword ({ new_password: user.password })
      //After changing User password
      .then((response) => {
        if(response?.status === 200) {
            //Update user information
            updateUser({ first_name: user.first_name, last_name: user.last_name, email: user.email })
            //After updating user information
          .then((response) => {
            if(response?.status === 200) {
              //Go to next step
              nextStepShopify();
            } else {
              //Show error if not status 200
              toast.success(response.data.message)
            }
          })
        } else {
          toast.success(response.data.message)
        }
    })
  }

  const handleSubmitStandalone = () => {
    setLoading(true);

    try {
      // create new user
      createProfile(user)
        .then((response) => {
          // if user created successfully
          if (response.status === 201) {
            // login user in
            login({ username: user.email, password: user.password }).then(
              (response) => {
                // adjust shop onboarding step
                getProfile().then((response) => {
                  updateShop(response.data.shop[0].id, {
                    onboarding_step: 1,
                  }).then(() => {
                    setLoading(false);
                  });
                });
              }
            );
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            setError(
              <NavLink to='login' className='text__error-email-used'>
                {error.response.data.message}
              </NavLink>
            );
          }

          if (error.response.data.email) {
            setFieldError({ email: error.response.data.email });
          }

          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  const isValid = () => {
    if (
      user.first_name &&
      user.last_name &&
      user.email &&
      user.password &&
      passwordState &&
      user.accepts_terms
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className='modal__step'>
      <div className='welcome-container mt-25'>
        <form className='form pt-10'>
          <TextField
            className='form-text-field form-text-field__name text--nunito'
            variant='outlined'
            name='first_name'
            required
            InputLabelProps={{ shrink: true }}
            value={user.first_name}
            label='First Name'
            onChange={(e) => setUser({ ...user, first_name: e.target.value })}
          />
          <TextField
            className='form-text-field__name form-text-field text--nunito'
            variant='outlined'
            name='last_name'
            required
            InputLabelProps={{ shrink: true }}
            label='Last Name'
            value={user.last_name}
            onChange={(e) => setUser({ ...user, last_name: e.target.value })}
          />
          <TextField
            className='form-text-field text--nunito'
            variant='outlined'
            label='Email Address'
            required
            InputLabelProps={{ shrink: true }}
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            helperText={fieldError?.email}
          />
          <TextField
            className='form-text-field text--nunito'
            variant='outlined'
            label='Password'
            required
            InputLabelProps={{ shrink: true }}
            id='password'
            value={user.password}
            type='password'
            onChange={handlePasswordChange}
          />
        </form>

        <div className='terms-conditions-container'>
          <Checkbox
            id='check'
            checked={user.accepts_terms}
            size='small'
            className='check'
            onChange={(e) =>
              setUser({ ...user, accepts_terms: e.target.checked })
            }
          />

          <p className='terms-text text--nunito'>
            Yes, I agree to Blanka's{' '}
            <a
              target='__blank'
              href='https://blankabrand.com/blanka-terms-and-conditions/'>
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a
              target='__blank'
              href='https://faq.blankabrand.com/en/articles/5924132-how-does-blanka-handle-subscription-refunds'>
              Refund &amp; Cancellation Policy.
            </a>
          </p>
        </div>
      </div>

      <div>
     
        <LoadingButton
          disabled={!isValid()}
          loading={loading}
          onClick={handleSubmitStandalone}
          color='primary'
          variant='contained'
          className='button--blue'>
          Sign Me Up
          <i className='fas fa-chevron-right' />
        </LoadingButton>
        

        <p className='text__error'>{error || <a> </a>}</p>
        <p className='text__error'>{}</p>
      </div>
    </div>
  );
}

SignUpForm.propTypes = {
  createProfile: PropTypes.func,
  updateUser: PropTypes.func,
  changePassword: PropTypes.func,
  login: PropTypes.func,
  updateShop: PropTypes.func,
  getProfile: PropTypes.func,
};

SignUpForm.defaultProps = {
  createProfile: () => {},
  updateUser: () => {},
  changePassword: () => {},
  login: () => {},
  updateShop: () => {},
  getProfile: () => {},
};

const mapStateToProps = (state) => ({
  session: state.session.session,
  profile: state.profile.getProfile.profile,
  profileLoading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  updateUser: sessionOperations.updateUser,
  changePassword: sessionOperations.changePassword,
  createProfile: profileOperations.createProfile,
  updateShop: profileOperations.updateShop,
  getProfile: profileOperations.getProfile,
  login: sessionOperations.login,
  logout: sessionOperations.logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpForm);
