import React, { useState } from 'react';
import { connect } from "react-redux";
import Card from '@material-ui/core/Card';
import { toast } from 'react-toastify';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { productOperations } from "../../state/ducks/products";

import { 
  PRODUCT_TYPE_SUPPLIER, 
  PRODUCT_TYPE_UNBRANDED, 
  PRODUCT_TYPE_CUSTOM,
  ERROR_PLAN_EXCEEDED
} from '../../constants';

import { cartOperations } from "../../state/ducks/cart";
import {notifications} from "../../components/notifications";

import { publishToStoreSuccess, InventoryWithSampleError } from '../../components/notifications';
import ProductForm from './productForm';
import ProductLogoModal from './productLogoModal';
import ProductInventoryModal from './productInventoryModal';
import FreePlanLimitModal from "../../components/modals/freePlanLimitModal";

import ConfirmModal from "../../components/modals/confirmModal";

import LoadingButton from "../../components/buttons/loadingButton";

function ProductItem(props) {

  const { product, publishToStore,  addToCart, sampleCart, profile, unpublishProduct } = props;

  const [inventoryModalOpen, setInventoryModalOpen] = useState(false);
  const [logoModalOpen, setlogoModalOpen] = useState(false);
  const [openFreePlanLimitModal, setOpenFreePlanLimitModal] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [published, setpublished] = useState(product.published);

  const handleLogoModalClose = () => {
    setlogoModalOpen(false);
  };


  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 33,
      height: 18,
      padding: 0,
      margin: theme.spacing(1),
      backgroundColor: '#FFFFFF',
      "&:hover": {
        backgroundColor: "none !important",
        boxShadow: "none !important",
      },
      boxShadow: "none !important",
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#00AA51',
          opacity: 1,
          border: 'none',
          boxShadow: "none !important",
        },
        boxShadow: "none !important",
      },
      '&$focusVisible $thumb': {
        color: '#00AA51',
      },
    },
    thumb: {
      width: 15,
      height: 16,
      boxShadow: "none !important",
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: '#CCCCCC',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
      "&:hover": {
        backgroundColor: "none !important",
        boxShadow: "none !important",
      },
      boxShadow: "none !important",
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });


  const handleInventoryModalClose = () => {
    setInventoryModalOpen(false);
  };

  const handleInventoryModalOpen = () => {
    setInventoryModalOpen(true);
  };

  const isDisabled = () => {

    if (product.published || published) {
      return true;
    }

    if (product.inventory > 0) {
      return false;
    }

    if(product.supplier_product.product_type === PRODUCT_TYPE_UNBRANDED){
      return false;
    }

    return true;

  }

  const handleUnpublishProduct = () => {
    setLoading(true);
    //make sure not to double up requests
    if (!loading) {
      setLoading(true);
      unpublishProduct(product.id)
        .then((response) => {
          setLoading(false);
          if (response?.status) {
            toast.success("Success: Product Unpublished");
            setpublished(false);
          }
        }).catch((error) => {
       
          setLoading(false);
        });
    }

  }



  const publishProduct = () => {

    setLoading(true);

    //check if store has been connected to the users account 
    if(!profile?.shop[0].store_connected){
      notifications.noStoreConnectedError();
      setLoading(false);
      return;
    }
    
    
    //make sure not to double up requests
    if (!loading) {
      setLoading(true);
      publishToStore(product.id)
        .then((response) => {
          setLoading(false);
          if (response?.status) {
            toast.success(publishToStoreSuccess);
            setpublished(true);
          }
        }).catch((error) => {
          
          if(error?.response.data?.error_code ==  ERROR_PLAN_EXCEEDED){
            setOpenFreePlanLimitModal(true);
          }else{
            toast.error(error?.response.data?.message);
          }

          setLoading(false);
        });
    }

  }


  const handleAddInventory = (amount) => {
    //clearCart();
    if (sampleCart.items.length === 0) {
      toast.success('Product Added To Cart');
      addToCart(product, amount);
    } else {
      toast.error(InventoryWithSampleError);
    }
  }

  /*
  const isPrivateLabel = () => {
    if (product && product.supplier_product.product_type === PRODUCT_TYPE_SUPPLIER) {
      return true;
    }
    return false;
  }
  */

  const getTag = () => {
    if (product && product.supplier_product.product_type === PRODUCT_TYPE_SUPPLIER) {
      return <div className="tag tag--blanka">Private Label</div>;
    } else if (product && product.supplier_product.product_type === PRODUCT_TYPE_UNBRANDED) {
      return <div className="tag tag--unbranded">Unbranded</div>;
    } else if (product && product.supplier_product.product_type === PRODUCT_TYPE_CUSTOM) {
      return <div className="tag tag--custom">Custom Product</div>
    }
    return false;
  }

  const expiringSoon = () => {
    if(product.supplier_product.is_expiring) {
      return <div className="tag tag--expiring-soon">Discontinuing SOON</div>
    }

    return false;
  }

  const checkPublishTag = () => {
    if(product.published) {
      return <Typography className="text__publish-tag text--nuntio">PUBLISHED</Typography>
    } else {
      return <Typography className="text__publish-tag text--nuntio">UNPUBLISHED</Typography>
    }
  }

  const isUnbranded = () => {
    if (product && product.supplier_product.product_type === PRODUCT_TYPE_UNBRANDED) {
      return true;
    } else
      return false;
  }

  const label = { inputProps: { 'aria-label': 'Switch' } };

  const handleSwitchChange = () => {
    if(!published) {
      publishProduct(product.id)
    } else {
      setConfirmOpen(true)
    }
  }

  return (
    <div className="template-my-products">
      <Card elevation='0' className="root shop-card">
        <CardContent container>
          <Grid container spacing={2}>
            <Grid className="shop-card__img" xs={12} md={3} >

              <div className="product__image">
                <div className="tag-container">
                  {getTag()}
                  {expiringSoon()}
                </div>

                <img className="image mt-15" src={product.supplier_product.image} alt="Product " ></img>
              </div>

            </Grid>

            <Grid className="shop-card__details" xs={12} md={9}>

              <div className="flex--flex">
                <Typography variant="h5" className="text__product-name text--bold text--nunito text--upper">
                  {product.supplier_product.name} &#xb7; {product.supplier_product.color_name}
                </Typography>

                <div className="flex--flex ml-auto pr-10 text--nunito">
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        {...label}
                        checked={published}
                        onChange={handleSwitchChange}
                      />
                    }
                    label={published ? 'PUBLISHED' : 'UNPUBLISHED'}
                    labelPlacement="start"
                  />
                </div>
              </div>

              <div dangerouslySetInnerHTML={{ __html: product.description }} />

              <ProductForm product={product} />

              <CardActions className="actions">
                <Button
                  disabled={isUnbranded()}
                  onClick={() => handleInventoryModalOpen()}
                  variant="contained" color="primary"
                  className={isUnbranded() ? "hidden" : "button button--primary"}>
                  Order Inventory
                </Button>

              
            
              {!published &&
                <LoadingButton
                  className="button button--primary"
                  loading={loading}
                  onClick={() => publishProduct(product.id)}
                  variant="contained"
                  color="primary" >
                  Publish to store
                </LoadingButton>
                }

              {published &&
                <LoadingButton
                  disabled={false}
                  className="button button--red-outline"
                  loading={loading}
                  onClick={() => setConfirmOpen(true)}
                  variant="outlined"
                  color="secondary" >
                  Unpublish Product
                </LoadingButton>
                }
  

              </CardActions>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ProductLogoModal product={product} handleClose={handleLogoModalClose} open={logoModalOpen} />
      <ProductInventoryModal product={product} handleClose={handleInventoryModalClose} onOrder={handleAddInventory} open={inventoryModalOpen} />
      <FreePlanLimitModal handleClose={() => setOpenFreePlanLimitModal(false)} open={openFreePlanLimitModal} />
      

      <ConfirmModal handleClose={()=>setConfirmOpen(false)} handleConfirm={() => {handleUnpublishProduct();setConfirmOpen(false)}} open={confirmOpen}></ConfirmModal>
    </div>
  );
}

ProductItem.defaultProps = {
  product: {}
};


const mapStateToProps = (state) => ({
  //products: state.products.getMyProducts.products,
  sampleCart: state.cart.samplesCart,
  profile: state.profile.getProfile.profile,
});


const mapDispatchToProps = {
  publishToStore: productOperations.publishToStore,
  unpublishProduct: productOperations.unpublishProduct,
  addInventory: productOperations.addInventory,
  addToCart: cartOperations.addToCart,
  clearCart: cartOperations.samplesClearCart
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);


 