import React, { useEffect, useState  } from "react";
import { connect, useSelector } from "react-redux";
import {  toast } from 'react-toastify';
import { useHistory, useLocation, useSear } from "react-router-dom";

import {  makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import Grid from '@material-ui/core/Grid';

import FilterMenu from '../../components/filterMenu/FilterMenu';
import { useQueryParam } from "../../hooks/useQueryParam";

import {
  PLAN_FREE_ID,
  CUSTOM_PRODUCT_STATUS_COMPLETE,
  CUSTOM_PRODUCT_STATUS_IN_PROGRESS,
  PRODUCT_TYPE_SUPPLIER,
  PRODUCT_TYPE_UNBRANDED,
  PRODUCT_TYPE_CUSTOM,
  FILTER_ALL,
} from '../../constants';

import LoadingButton from "../../components/buttons/loadingButton";
import FreePlanLimitModal from "../../components/modals/freePlanLimitModal";

import CustomProductsList from './customProductsList'
import SkeletonList from "./skeletonList";
import SupplierProductsList from './supplierProductsList'
import BlankProductsList from './blankProductsList'
import {NoLogoError} from '../../components/notifications';

import { productOperations } from "../../state/ducks/products";
import { profileOperations } from "../../state/ducks/profile";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: "50px",
    },
    table: {
        borderCollapse: "collapse",
        margin: 16,
        width: '50%',
        //margin: 'auto'
        minWidth: '350px'
    },
    tableData: { padding: 8 },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '48%',

    }
}));

// function useUrlQuery() {
//     const { search } = useLocation();
  
//     return useMemo(() => new URLSearchParams(search), [search]);
// }

function FindProducts(props) {
    const classes = useStyles();
    let queryParams = useQueryParam();
    let history = useHistory();

    const { profile, getProfile,updateShop,isLoading, getSupplierProducts, products, } = props;
    const [productType,setProductType] = useState();
    const [visualizeLogoLoading,setVisualizeLogoLoading] = useState(false);
    const [openFreePlanLimitModal, setOpenFreePlanLimitModal] = useState(false);
    const profileLoading = useSelector((state) => state.profile.getProfile.loading);
    const [catgeoryFilter, setCatgeoryFilter] = useState(FILTER_ALL);

    
  
    useEffect(() => {
        
        if(!profile?.shop[0].custom_product_status){
            return;
        }

        ///check if url param is set
        if(queryParams.get("product_type")){
            setProductType(queryParams.get("product_type"));
        }else{
            profile?.shop[0].custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE?setProductType(PRODUCT_TYPE_CUSTOM):setProductType(PRODUCT_TYPE_UNBRANDED);
        }

        if(queryParams.get("category")){
            setCatgeoryFilter(queryParams.get("category"));
        }

        async function fetchData(catgeoryFilter, productType) {
            await getSupplierProducts(catgeoryFilter, productType);
        }
       

        //wait till profile loads to fetch products
        if(!profileLoading && profile?.shop[0].custom_product_status && productType){
            fetchData(catgeoryFilter,productType);
        }
    
    }, [profile]);


   const setParams = (category, productType) =>{
        const params = new URLSearchParams()
        params.append('category',category);
        params.append('product_type',productType);
        history.push({search: params.toString()})
   }


    const fetchFilter = async (category) => {
        //set order type state to trigger api call
        setCatgeoryFilter(category);
        setParams(category,productType);
        await getSupplierProducts(category, productType);
    }

    const handleSetProductType = async (productType) => {
        setProductType(productType)
        setParams(catgeoryFilter,productType);
        await getSupplierProducts(catgeoryFilter, productType);
    }
    

    const visualizeLogo = (open) => {
        //check if user is on free plan or does not have a logo uploaded yet
        if(profile?.shop[0].plan === PLAN_FREE_ID || !profile?.shop[0]?.plan ){ // only use double equals since possibly comparing a string and integer
            setOpenFreePlanLimitModal(true);
            return;
        }else if(!profile?.shop[0].logo_uploaded){
            toast.error(NoLogoError);
            return;
        }
        setVisualizeLogoLoading(true);

        updateShop(profile.shop[0].id, {custom_product_status: CUSTOM_PRODUCT_STATUS_IN_PROGRESS, logo_uploaded: true})
        .then((response)=>{
            getProfile();
            setVisualizeLogoLoading(false);
        })
    }


    const showCustomFilters = () => {
        if(profile?.shop[0].custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE){
            return true;
        }
        return false;
    }

    const formatTime = (row) => {
        const start = moment(row.orderDeliveryTime).format("MMMM D, h:mma");
        const end = moment(row.orderDeliveryTime).add(1, 'hours').format("ha");
        return `${start} - ${end}`
    }

    const isActive = (currentProductType) => {
        if(productType===currentProductType){
            return true;
        }
        return false;
    }


    const getVisualizationLabel = () =>{
        if(profile?.shop[0].custom_product_status===CUSTOM_PRODUCT_STATUS_IN_PROGRESS){
            return 'Visualization in Progress';
        }
        return "Add my logo to products";
    }

    const showVisualizationButton = () =>{

        if(profile?.shop[0].custom_product_status!==CUSTOM_PRODUCT_STATUS_COMPLETE && !profileLoading){
            return true;
        }
        return false;
    }

    const showUpgradeButton = () =>{
        if(profile?.shop[0].custom_product_status==CUSTOM_PRODUCT_STATUS_COMPLETE && profile?.shop[0].plan==PLAN_FREE_ID && !profileLoading){
            return true;
        }
        return false;
    }
 
  
    return (
        <div className="template-find-products">
            <Grid className="page-header mb-25" container >
             
                <Typography className="pb-25" variant="h6">Find Products</Typography>

                {showCustomFilters() && //TODO: Break into seperate component
                    <div className="page-header__filters">
                        <Typography variant="h6">Filter:</Typography>   
                        
                        <Button className={isActive(PRODUCT_TYPE_CUSTOM)?'active button__filters':'button__filters'}  onClick={()=>handleSetProductType(PRODUCT_TYPE_CUSTOM)}>Custom</Button>
                        <span>|</span>
                        <Button  className={isActive(PRODUCT_TYPE_UNBRANDED)?'active button__filters':'button__filters'}  onClick={()=>handleSetProductType(PRODUCT_TYPE_UNBRANDED)}>Unbranded</Button>
                    </div>
                }

                { showVisualizationButton() && //TODO: Break into seperate component
                    <div className="page-header__visualize">

                        <LoadingButton disabled={profile?.shop[0].custom_product_status==="IN_PROGRESS"} loading={visualizeLogoLoading} onClick={visualizeLogo} variant="contained" className="button--primary">

                            {getVisualizationLabel()}
                            
                        </LoadingButton>
                    </div>
                }
            </Grid>

            <FilterMenu catgeoryFilter={catgeoryFilter} fetchFilter={fetchFilter}/>
                
            <Grid className="paper" container spacing={2} >
            
                {products.length>0 &&
                    <SupplierProductsList products={products}/>
                }

              

            </Grid>

            <FreePlanLimitModal handleClose={() => setOpenFreePlanLimitModal(false)} open={openFreePlanLimitModal} profile={profile}/>
        </div>
    );
}

//{isLoading &&
//    <SkeletonList numItems={12}/>
//}


FindProducts.defaultProps = {
    products: [],
    isLoading: true,
    hasErrored: false
};

const mapStateToProps = (state) => ({
    products: state.products.getSupplierProducts.products.results,
    profile: state.profile.getProfile.profile,
    customProducts: state.products.custom_products.products,
    isLoading: state.products.getSupplierProducts.loading,
    hasError: state.products.getSupplierProducts.error
});

const mapDispatchToProps = {
    getSupplierProducts: productOperations.getSupplierProducts,
    getProfile: profileOperations.getProfile,
    updateShop: profileOperations.updateShop,
};





export default connect(mapStateToProps, mapDispatchToProps)(FindProducts);
