import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../components/buttons/loadingButton';

import { cartOperations } from '../../state/ducks/cart';

const useStyles = makeStyles((theme) => ({

}));

function InventoryTotalsArea(props) {
  const classes = useStyles();
  const {
    shipping, items, shipping_cost, shipping_cost_loading, shippingError,
  } = props;
  const [code, setCode] = useState();
  const [coupon, setCoupon] = useState();
  const [loading, setLoading] = useState(false);

  const getSubtotal = () => {
    let total = 0;
    items.forEach((item) => {
      total += (Number(item.price) * item.toOrder);

      if (item.add_branded_box) {
        total += (0.40 * item.toOrder);
      }
    });

    return total.toFixed(2);
  };

  const calcFees = () => {
    const total = getSubtotal(items);

    return (((Number(total) + (shipping_cost || 0)) * 0.029) + 0.3).toFixed(2);
  };

  const calcTotal = (items) => {
    let total = 0;
    total = Number(getSubtotal(items));

    if (shipping_cost) {
      total += shipping_cost;
      total = (total + (total * 0.029) + 0.3);
    } else {
      total = (total + (total * 0.029) + 0.3);
    }

    return Number(total).toFixed(2);
  };

  const getShippingCostState = () => {
    if (shipping_cost_loading) {
      return <CircularProgress size={16} color="secondary" />;
    }
    return '*';
  };

  return (

    <TableContainer className={classes.totalsContainer}>
      <Table className={classes.totalsTable} aria-label="">

        <TableBody>

          <TableRow>
            <TableCell>Subtotal</TableCell>
            <TableCell>
              $
              {getSubtotal(items)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Shipping</TableCell>
            <TableCell className={`${shippingError && 'shipping-error'}`}>
              {shipping_cost
                ? `$${Number(shipping_cost).toFixed(2)}`
                : <span>{getShippingCostState()}</span>}

            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Processing Fee</TableCell>
            <TableCell>
              $
              {Number(calcFees(items)).toFixed(2)}
            </TableCell>
          </TableRow>

          <TableRow className={classes.total}>
            <TableCell>Total</TableCell>
            <TableCell>
              $
              {Number(calcTotal(items)).toFixed(2)}
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>
      {shippingError
      && <p className="text--error">Invalid Shipping Address</p>}
    </TableContainer>

  );
}

InventoryTotalsArea.propTypes = {

};

InventoryTotalsArea.defaultProps = {
  shipping: () => {},
  items: [],
};

const mapStateToProps = (state) => ({
  shipping_cost: state.cart.shipping.shipping_cost,
  shipping_cost_loading: state.cart.shipping.shipping_cost_loading,
  profile: state.profile.getProfile.profile,
  couponData: state.cart.samplesCheckout.coupon,
  shippingError: state.cart.shipping.error,
});

const mapDispatchToProps = {
  addShipping: cartOperations.samplesAddShipping,
  clearCart: cartOperations.samplesClearCart,
  checkout: cartOperations.samplesCheckout,
  checkCoupon: cartOperations.checkCoupon,
  clearCoupon: cartOperations.samplesClearCoupon,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTotalsArea);
