import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  useHistory, useLocation, Switch, Route, Link,

} from 'react-router-dom';
import { toast } from 'react-toastify';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { cartOperations } from '../../state/ducks/cart';
import { productOperations } from '../../state/ducks/products';

import SamplesCheckoutTable from './SamplesCheckoutTable';
import SamplesCheckoutSuccess from './SampleCheckoutSuccess';
import CheckoutBilling from './CheckoutBilling';
import CheckoutShipping from './CheckoutShipping';

function getSteps() {
  return ['Shipping', 'Confirm'];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: 16,
    width: '95%',
    // margin: 'auto'
    minWidth: '350px',
  },
  tableData: { padding: 8 },
  colorSwatch: {
    width: '50px',
    height: '50px',
  },
  totalsArea: {
    textAlign: 'right',
    marginTop: '20px',
  },
  total: {
    borderTop: `1px solid ${theme.palette.gray}`,

  },
  totalsTable: {
    width: '300px',
    marginLeft: 'auto',
    textAlign: 'right',
    marginBottom: '25px',
    '& td': {
      padding: '10px 0px',
      textAlign: 'right',
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SampleCheckout(props) {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();

  const {
    error, clearError, items, profile, checkout, shipping, clearCart, discount,
  } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [loading, setLoading] = React.useState(false);

  const formatTime = (row) => {
    const start = moment(row.orderDeliveryTime).format('MMMM D, h:mma');
    const end = moment(row.orderDeliveryTime).add(1, 'hours').format('ha');
    return `${start} - ${end}`;
  };

  const handleCheckout = (items) => {
    const checkoutData = [];
    items.forEach((item) => {
      checkoutData.push({ id: item.id, quantity: item.toOrder });
    });
  };
  const calcTotal = (items) => {
    let total = 0;
    items.forEach((item) => {
      total += (Number(item.price) * item.toOrder);
    });
    return total.toFixed(2);
  };

  const handleConfirmCheckout = () => {
    setLoading(true);
    const checkoutData = [];
    items.forEach((item) => {
      checkoutData.push({ product_id: item.sku, quantity: item.toOrder });
    });
    // order sample
    checkout({ items: checkoutData, shipping, discount_id: discount?.discount.id })
      .then((response) => {
        if (response.status == 200) {
          toast.success('Checkout Success');
          setLoading(false);
          clearCart();
          history.push('/thank-you');
        } else {
          setLoading(false);
          console.log(response.response);
          toast.error(`${response.response.data.message}`);
        }
      }); 
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    history.push(`/checkout/?step=${activeStep}`);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <SamplesCheckoutTable items={items} nextStep={handleNext} />;
      case 1:
        return <CheckoutShipping items={items} nextStep={handleNext} exact />;
      case 2:
        return <CheckoutBilling loading={loading} items={items} handleConfirmCheckout={handleConfirmCheckout} nextStep={handleNext} />;

        // case 3:
        //    return <OnboardingModalStepPayment handleClose={handleClose} />;
      default:
        return '';
    }
  };

  // {getStepContent(activeStep)}

  return (
    <div className={classes.root}>

      <Switch>
        <Route path="/checkout" exact>
          <SamplesCheckoutTable items={items} nextStep={handleNext} />
        </Route>
        <Route path="/checkout/shipping" exact>
          <CheckoutShipping items={items} nextStep={handleNext} />
        </Route>
        <Route path="/checkout/billing" exact>
          <CheckoutBilling loading={loading} items={items} handleConfirmCheckout={handleConfirmCheckout} nextStep={handleNext} />
        </Route>

      </Switch>

    </div>
  );
}

SampleCheckout.defaultProps = {
  items: [],
  isLoading: false,
  hasErrored: false,
};

const mapStateToProps = (state) => ({
  items: state.cart.samplesCart.items,
  discount: state.cart.samplesCheckout.coupon,
  profile: state.profile.getProfile.profile,
  shipping: state.cart.shipping.shipping,
});

const mapDispatchToProps = {
  checkout: cartOperations.samplesCheckout,
  clearCart: cartOperations.samplesClearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleCheckout);
