import * as types from './types';
import * as appTypes from '../app/types';
import { apiService } from '../../middlewares';

export function getOrders(filter, page = 1) {
  return (dispatch) => {
    dispatch({ type: appTypes.IS_LOADING, payload: true });

    // convert values to backend values
    let apiFilter = '';

    console.log(filter);
    // change status so backend knows what to retrieve
    switch (filter) {
      case 'ALL':
        apiFilter = null;
        break;
      case 'UNFULFILLED':
        apiFilter = 'UNFULFILLED|ORDERED';
        break;
      case 'SHIPPED':
        apiFilter = 'SHIPPED|FULLFILLED_BY_MERCHANT';
        break;
      default:
        apiFilter = filter;
    }

    const orderUrl = apiFilter ? `/orders/?status=${apiFilter}&page=${page}` : '/orders/';

    return apiService.get(orderUrl)
      .then((response) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_LIST_SUCCESS,
          data: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_LIST_FAILED,
          isLoading: false,
          error,
        });
        throw error;
      });
  };
}

export function getInventoryOrders() {
  return (dispatch) => {
    dispatch({ type: appTypes.IS_LOADING, payload: true });
    return apiService.get('/inventory-orders/')
      .then((response) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_INVENTORY_ORDERS_SUCCESS,
          payload: {
            data: response.data,
          },
        });
        return response;
      })
      .catch((error) => {
        dispatch({ type: appTypes.IS_LOADING, payload: false });
        dispatch({
          type: types.FETCH_INVENTORY_ORDERS_FAILED,
          payload: {
            isLoading: false,
            error,
          },

        });
      });
  };
}

export function updateOrder(order) {
  return (dispatch) => apiService.patch(`/orders/update/${order.id}`, order)
    .then((response) => {
      dispatch({
        type: types.UPDATE_ORDER_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_ORDER_ERROR,
        error,
      });
      throw error;
    });
}

export function payCustomerOrder(orderID, boxData, use_own_inventory = false) {
  return (dispatch) => {
    dispatch({ type: types.PAY_CUSTOMER_ORDER });
    return apiService.post(`/orders/${orderID}/place_order/`, { items: boxData, use_own_inventory })
      .then((response) => {
        dispatch({
          type: types.PAY_CUSTOMER_ORDER_SUCCESS,
          data: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: types.PAY_CUSTOMER_ORDER_FAILED,
          error,
        });
        throw error;
      });
  };
}
