import PropTypes from 'prop-types';
import React from 'react';

import clsx from 'clsx';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6, 8, 6),
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: '525px',
  },
}));

function ConfirmOrderModal(props) {
  const classes = useStyles();
  const {
    handleClose, handleConfirm, open, orderTotal,
  } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Confirm Modal"
        aria-describedby="Confirm Modal"
        className={clsx(classes.modal, 'bl-modal', 'confirm-order-modal')}
      >
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>

          <div className={`${classes.paper} modal__wrapper`}>

            <Typography onClick={handleClose} className="close-button modal-close-button">[x] close</Typography>

            <Typography className="text--fs-20 text--georgia  mb-10 text--center text--medium-gray text--ls-0" variant="h5">Please confirm your order!</Typography>

            <Typography variant="body" className="text--gray mb-10 text--center text-secondary text--nunito">
              Your credit card on file will be charged $
              {orderTotal}
              {' '}
              immediately and your order will be processed.
            </Typography>

            <Button variant="contained" color="primary" className="button--primary button" onClick={handleConfirm}>
              Place Order
              {' '}
              <i className="fas fa-chevron-right" />

            </Button>

          </div>

        </Slide>
      </Modal>
    </div>
  );
}

ConfirmOrderModal.propTypes = {
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  open: PropTypes.bool,
};

ConfirmOrderModal.defaultProps = {
  handleClose: () => {},
  handleConfirm: () => {},
  open: false,
};

export default ConfirmOrderModal;
