import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { reduxStore } from '../../index';
import { NetworkError } from '../../components/notifications';

// import { notificationOperations } from "../ducks/notifications";
import { sessionOperations } from '../ducks/session';

let BASE_URL = '';

if (process.env.REACT_APP_API_URL) {
  BASE_URL = process.env.REACT_APP_API_URL;
} else {
  BASE_URL = 'http://blanka-dev.ngrok.io/api/';
}

// if(process.env.NODE_ENV === 'production'){
//  var BASE_URL = process.env.REACT_APP_API_URL ||
// }

// BASE_URL = 'https://admin.blankabrand.com/api/';

const apiService = axios.create({
  baseURL: BASE_URL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
apiService.interceptors.request.use((config) => {
  // add token to request

  if (reduxStore) {
    const state = reduxStore.getState();
    if (state.session.session.token.access_token !== '') {
      config.headers.Authorization = `Bearer ${state.session.session.token.access_token}`;
    }
  }

  return config;
}, (error) =>
// Do something with request error
  Promise.reject(error));

// Add a response interceptor
apiService.interceptors.response.use((response) => response, async (error) => {
  const originalConfig = error.config;

  if (!error.response) {
    toast.error(<NetworkError />, {

    });
    return Promise.reject(error);
  }

  if (reduxStore) {
    const { dispatch } = reduxStore;
    const state = reduxStore.getState();
    // console.error(error.response);

    //if(!error.response.data.error_code)
    switch (error.response?.status) {
      case 400:
        console.log(error.response.data);
        if(state.session.session.isLoggedIn && state.profile.getProfile?.profile?.shop[0]?.onboarding_complete && !error.response.data.error_code){
          
          toast.error(error.response.data.message);
        }
        
        return Promise.reject(error);
        break;
      case 401:
        dispatch(sessionOperations.logout());
        // window.location.href = 'https://blankabrand.com/';
        break;
      case 403:
        
        //if(state.session.session.isLoggedIn){
          //toast.error('Session Timed Out, Please login again');
        //}
        
        if(error.response?.data.code == "token_not_valid"){
          if (originalConfig.url !== "/login" && !originalConfig._retry){
            originalConfig._retry = true;
            try{
              await dispatch(sessionOperations.getNewRefreshToken(state.session.session.token.refresh_token))
              return apiService(originalConfig);
            }catch(_error){

              dispatch(sessionOperations.logout());
              return Promise.reject(_error);
            }
          }
        }
        //dispatch(sessionOperations.logout());
        // window.location.href = 'https://blanka.blankabrand.com/login';
        break;

      case 500: // network or server error
        toast.error('Oops, an error occured. We are currently working to fix the issue');
        break;
        // case 404:
        // break;
      default:
          // nothing
    }
  }

  // return error;
  return Promise.reject(error);
});

export default apiService;
