import { combineReducers } from "redux";
import * as types from "./types";


function state(state = {loading:false, error: false}, action) {
    switch (action.type) {
        case types.IS_LOADING:
            return { ...state, loading: action.payload};
        default:
            return state;
    }
}
export default combineReducers({
    state
});
