import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  colorSwatch: {
    width: '50px',
    height: '50px',
  },
}));

function OrderInventoryItemRow(props) {
  const classes = useStyles();
  const { product, order } = props;

  /*
  const [price, setPrice] = useState(product.price);

  const handleChange = (prop) => (event) => {
    setPrice(event.target.value);
  };

  const calcProfit = (cost, price) => (price - cost).toFixed(2);
  */

  return (

    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="">
        <TableHead>
          <TableRow>
            <TableCell>Color</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Variant</TableCell>
            <TableCell align="right">Unit Cost</TableCell>
            <TableCell align="right">Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><div className={classes.colorSwatch} style={{ backgroundColor: product.color_code }} /></TableCell>
            <TableCell>{product.name}</TableCell>
            <TableCell>{product.color_name}</TableCell>
            <TableCell align="right">{product.cost}</TableCell>

            <TableCell align="right">{order.amount}</TableCell>

          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
}

OrderInventoryItemRow.propTypes = {
  product: PropTypes.objectOf,
  order: PropTypes.objectOf,
};

OrderInventoryItemRow.defaultProps = {
  product: {},
  order: {},
};

export default OrderInventoryItemRow;
