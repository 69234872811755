import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import FormGroup from '@material-ui/core/FormGroup';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { useHistory } from 'react-router-dom';

import { cartOperations } from '../../state/ducks/cart';
import { productOperations } from '../../state/ducks/products';

import LoadingButton from '../../components/buttons/loadingButton';

import CheckoutItem from './CheckoutItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: 16,
    width: '95%',
    // margin: 'auto'
    minWidth: '350px',
  },
  tableData: { padding: 8 },
  colorSwatch: {
    width: '50px',
    height: '50px',
  },
  totalsArea: {
    textAlign: 'right',
    marginTop: '20px',
    border: 'none',
  },
  total: {
    borderTop: `1px solid ${theme.palette.gray}`,

  },
  totalsContainer: {
    border: 'none',
  },
  totalsTable: {
    width: '300px',
    marginLeft: 'auto',
    textAlign: 'right',
    marginBottom: '25px',
    marginRight: '50px',
    '& td': {
      padding: '10px 0px',
      textAlign: 'right',
    },
  },
}));

function InventoryCheckoutCart(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    items, addInventory, clearCart, nextStep, updateHoldInventory, clearShipping,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [holdOnInv, setHoldOnInv] = React.useState(false);

  useEffect(() => {
    clearShipping();
  }, []);

  const calcFees = () => {
    let total = 0;
    items.forEach((item) => {
      total += (Number(item.supplier_product.cost) * item.toOrder);
      if (item.add_branded_box) {
        total += (0.40 * item.toOrder);
      }
    });
    return Number((total * 0.029) + 0.3).toFixed(2);
  };

  const getSubtotal = () => {
    let total = 0;
    items.forEach((item) => {
      total += (Number(item.price) * item.toOrder);
      if (item.add_branded_box) {
        total += (0.40 * item.toOrder);
      }
    });

    return total.toFixed(2);
  };

  return (
    <div className={`${classes.root} template-inventory-checkout`}>

      <Card className={classes.root} variant="outlined">
        <TableContainer component={Paper} className="item__table">
          <Table className={classes.table} aria-label="">
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>sku</TableCell>
                <TableCell>BRANDED BOXES</TableCell>
                <TableCell align="left">Quantity</TableCell>
                <TableCell align="right">Unit Cost (USD)</TableCell>
                <TableCell align="right">Total Cost (USD)</TableCell>
                <TableCell align="right">Remove</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>

              {items.map((product) => <CheckoutItem product={product} />)}

            </TableBody>
          </Table>
        </TableContainer>

      </Card>

      <div className="tracking-number-container">
        <div className="flex--flex">
          <div className="checkout__breadcrumbs">
            <p className="text--bold">Cart </p>
            <ChevronRightIcon />
            <p>Shipping</p>
            <ChevronRightIcon />
            <p>Payment</p>
          </div>
        </div>

      </div>

      <div className="totals-container">
        <div className="totals-container__values-container">
          <Typography className="text__totals text--nunito ">Subtotal</Typography>
          <Typography className="text__totals text--bold text--nunito">
            $
            {getSubtotal()}
          </Typography>
        </div>
        <div className="totals-container__values-container">
          <Typography className="text__totals text--nunito ">Shipping</Typography>
          <Typography className="text__totals text--bold text--nunito">-</Typography>
        </div>
        <div className="pb-10 totals-container__values-container">
          <Typography className="text__totals text--nunito ">Processing Fee</Typography>
          <Typography className="text__totals text--bold text--nunito">
            $
            {Number(calcFees()).toFixed(2)}
          </Typography>
        </div>
        <div className="totals-container__values-container totals-container--border-top">

          <Typography className="text__totals text--nunito text--left">Total</Typography>
          <Typography className="text__totals text--bold text--nunito">
            $
            {(Number(getSubtotal()) + Number(calcFees())).toFixed(2)}
          </Typography>

        </div>

      </div>
      <div className="btn--continue-shipping">
        <LoadingButton loading={loading} onClick={() => nextStep()} variant="outlined" color="primary" className=" button--red-outline ">Continue to shipping </LoadingButton>
      </div>

    </div>
  );
}

InventoryCheckoutCart.propTypes = {
  items: PropTypes.arrayOf,
  addInventory: PropTypes.func,
  clearCart: PropTypes.func,
};

InventoryCheckoutCart.defaultProps = {
  items: [],
  addInventory: () => {},
  clearCart: () => {},
};

const mapStateToProps = (state) => ({
  shipping: state.cart.shipping.shipping,
});

const mapDispatchToProps = {
  checkout: cartOperations.checkout,
  addInventory: productOperations.addInventory,
  clearCart: cartOperations.clearCart,
  updateHoldInventory: cartOperations.updateCartHoldOwnInventory,
  clearShipping: cartOperations.samplesClearShipping,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCheckoutCart);
