import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6, 8, 6),
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: '500px',
  },
}));

function FreePlanLimitModal(props) {
  const classes = useStyles();
  const {
    handleClose, handleConfirm, open,
  } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Confirm Modal"
        aria-describedby="Confirm Modal"
        className={clsx(classes.modal, 'bl-modal', 'confirm-modal')}
      >

        <div className={`${classes.paper} modal__body`}>

          <Typography onClick={handleClose} className="close-button modal-close-button">[x] close</Typography>

          <Typography className="text--nunito text--white mb-25 text--upper" variant="h5" color="">Warning!</Typography>

          <Typography variant="body" className="mb-10">
            <span className="text--bold">Are you sure you want to unpublish this product?</span>
            {' '}
            This process
            {' '}
            <span className="text--bold">CANNOT BE UNDONE.</span>
          </Typography>

          <Typography variant="body" className="mb-10">
            This will delete the product and all associated data from your Shopify store. We recommend you export your product from Shopify first to save any customizations.
            {' '}
            <a target="_blank" href="https://faq.blankabrand.com/en/articles/5265782-how-do-i-delete-or-unpublish-a-product" rel="noreferrer">Learn more.</a>
          </Typography>

          <Button variant="contained" className="button button--blue" onClick={handleConfirm}>Unpublish Product</Button>

        </div>

      </Modal>
    </div>
  );
}

FreePlanLimitModal.propTypes = {
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  open: PropTypes.bool,
};

FreePlanLimitModal.defaultProps = {
  handleClose: () => {},
  handleConfirm: () => {},
  open: false,
};

export default FreePlanLimitModal;
