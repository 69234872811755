import React from "react";
import { connect } from "react-redux";
import { productOperations } from "../../state/ducks/products";
import { cartOperations } from "../../state/ducks/cart";
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import SamplesCheckoutItem from './SamplesCheckoutItem';
import LoadingButton from '../../components/buttons/loadingButton';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    header: {
        borderBottom: "1px solid " + theme.palette.gray,
        marginBottom: "50px",
    },
    table: {
        borderCollapse: "collapse",
        margin: 16,
        width: '95%',
        //margin: 'auto'
        minWidth: '350px'
    },
    tableData: { padding: 8 },
    colorSwatch: {
        width: '50px',
        height: '50px'
    },
    totalsArea: {
        textAlign: 'right',
        marginTop: '20px',
        border: 'none'
    },
    total: {
        borderTop: "1px solid " + theme.palette.gray,

    },
    totalsContainer: {
        border: 'none',
    },
    totalsTable: {
        width: '300px',
        marginLeft: 'auto',
        textAlign: 'right',
        marginBottom: '25px',
        marginRight: '50px',
        "& td": {
            padding: '10px 0px',
            textAlign: 'right'
        }
    }
}));

function SamplesCheckoutTable(props) {
    const classes = useStyles();
    const history = useHistory();
    const { items, addShipping, shipping, profile } = props;
    // const [success, setSuccess] = useState(null);


    // const formatTime = (row) => {
    //     const start = moment(row.orderDeliveryTime).format("MMMM D, h:mma");
    //     const end = moment(row.orderDeliveryTime).add(1, 'hours').format("ha");
    //     return `${start} - ${end}`
    // }


   
    const handleContinue = (items) => {
        addShipping(shipping);
        
        history.push("/checkout/shipping");

    }

   

    const caclFees = (items) =>{
        var total = 0;
        items.forEach((item) => {
            total += (Number(item.cost) * item.toOrder);
        });
        return ((total*0.029) + 0.3).toFixed(2);
    }



    const caclSubTotal = (items) =>{
        var total = 0;
        items.forEach((item) => {
            total += (Number(item.cost) * item.toOrder);
        });
        return total.toFixed(2);
    }

    const calcTotal = (items) => {
        var total = 0;
        items.forEach((item) => {
            total += (Number(item.cost) * item.toOrder);
        });
        return (total + (total*0.029) + 0.3).toFixed(2); 
    }


    //if no billing, send them to billing section
    if(!profile?.shop[0].billing_address){
        //return <CheckoutPayment/>;
    }   



    return (
        <div className={classes.root}>
        
            <Card className={classes.root} variant="outlined">
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="">
                        <TableHead>
                            <TableRow>
                                <TableCell>Color</TableCell>
                                <TableCell>Color name</TableCell>
                                <TableCell>Product Name</TableCell>
                                <TableCell >Unit Cost (USD)</TableCell>
                                <TableCell >Quantity</TableCell>
                                <TableCell >Total Cost (USD)</TableCell>
                                <TableCell >Remove</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>


                            {props.items.map((product) =>
                                <SamplesCheckoutItem product={product}></SamplesCheckoutItem>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>


            </Card>

            <div className={classes.totalsArea}>
            <Typography variant="h5" color="primary" className="text--upper">Note: All sample orders are shipped with unbranded products</Typography>
                <TableContainer className={classes.totalsContainer}>
                    <Table className={classes.totalsTable} aria-label="">

                        <TableBody>
                            <TableRow>
                                <TableCell>Subtotal</TableCell>
                                <TableCell>${caclSubTotal(items)}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Shipping</TableCell>
                                <TableCell>*</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Processing Fee</TableCell>
                                <TableCell>${caclFees(items)}</TableCell>
                            </TableRow>

                            

                            <TableRow className={classes.total}>
                                <TableCell>Total</TableCell>
                                <TableCell>${calcTotal(items)}</TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </TableContainer>


                <LoadingButton onClick={() => handleContinue()} variant="outlined" color="primary" className="button button--primary">Continue To Shipping </LoadingButton>
                <sub style={{'display':'block', 'marginTop': '5px'}}>shipping calculated at checkout</sub>


            </div>
        </div>
    );

}

SamplesCheckoutTable.defaultProps = {
    items: [],
    shipping: {},
    isLoading: false,
    hasErrored: false,
    profile: {}
};

const mapStateToProps = (state) => ({
    shipping: state.cart.shipping.shipping,
    profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
    //getSupplierProducts: productOperations.getSupplierProducts,
    checkout: cartOperations.samplesCheckout,
    addShipping : cartOperations.samplesAddShipping,
    clearCart: cartOperations.samplesClearCart,
    addInventory: productOperations.addInventory,
};

export default connect(mapStateToProps, mapDispatchToProps)(SamplesCheckoutTable);
