export const LOGIN = 'session/LOGIN';
export const LOGIN_SUCCESS = 'session/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'session/LOGIN_FAILED';
export const CLEAR_LOGIN_ERROR = 'session/CLEAR_LOGIN_ERROR';

export const PASSWORD_RESET = 'session/PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS = 'session/PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILED = 'session/PASSWORD_RESET_FAILED';

export const PASSWORD_RESET_TOKEN = 'session/PASSWORD_RESET_TOKEN';
export const PASSWORD_RESET_TOKEN_SUCCESS = 'session/PASSWORD_CHANGE_TOKEN_SUCCESS';
export const PASSWORD_RESET_TOKEN_FAILED = 'session/PASSWORD_CHANGE_TOKEN_FAILED';

export const PASSWORD_CHANGE = 'session/PASSWORD_RESET';
export const PASSWORD_CHANGE_SUCCESS = 'session/PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAILED = 'session/PASSWORD_CHANGE_FAILED';

export const LOGOUT = 'session/LOGOUT';

export const CLEAR_TOKEN = 'session/CLEAR_TOKEN';

export const SET_TOKEN = 'session/SET_TOKEN';

export const REFRESH_TOKEN_GET = 'session/REFRESH_TOKEN_GET';
export const REFRESH_TOKEN_SUCCESS = 'session/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'session/REFRESH_TOKEN_ERROR';

export const UPDATE_USER = 'session/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'session/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'session/UPDATE_USER_FAILED';
