import { combineReducers } from 'redux';
import * as types from './types';

function getSupplierProducts(state = { products: [], loading: false, error: false }, action) {
  switch (action.type) {
    case types.FETCH_SUPPLIER_PRODUCTS:
      return {
        ...state, loading: true, error: false,
      };
    case types.FETCH_SUPPLIER_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: action.data };
    case types.FETCH_SUPPLIER_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.CLEAR_FETCH_SUPPLIER_PRODUCTS_ERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}

function custom_products(state = { products: [], loading: false, error: false }, action) {
  switch (action.type) {
    case types.FETCH_CUSTOM_PRODUCTS:
      return { ...state, loading: true, error: false };
    case types.FETCH_CUSTOM_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: action.data };
    case types.FETCH_CUSTOM_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.CLEAR_FETCH_CUSTOM_PRODUCTS_ERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}

function blank_products(state = { products: [], loading: false, error: false }, action) {
  switch (action.type) {
    case types.FETCH_BLANK_PRODUCTS:
      return { ...state, loading: true, error: false };
    case types.FETCH_BLANK_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: action.data };
    case types.FETCH_BLANK_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.CLEAR_FETCH_BLANK_PRODUCTS_ERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}

function getMyProducts(state = { products: [], loading: false, error: false }, action) {
  switch (action.type) {
    case types.FETCH_MY_PRODUCTS:
      return { ...state, loading: true, error: false };
    case types.FETCH_MY_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: action.data };
    case types.FETCH_MY_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.CLEAR_FETCH_MY_PRODUCTS_ERROR:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}

function addProductToStore(state = { loading: false, error: false }, action) {
  switch (action.type) {
    case types.ADD_PRODUCT_TO_STORE:
      return { ...state, loading: true, error: false };
    case types.ADD_PRODUCT_TO_STORE_SUCCESS:
      return { ...state, loading: false };
    case types.ADD_PRODUCT_TO_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

function publishToStore(state = { loading: false, error: false }, action) {
  switch (action.type) {
    case types.PUBLISH_TO_STORE:
      return { ...state, loading: true, error: false };
    case types.PUBLISH_TO_STORE_SUCCESS:
      return { ...state, loading: false };
    case types.PUBLISH_TO_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

function addInventory(state = { loading: false, error: false }, action) {
  switch (action.type) {
    case types.ADD_INVENTORY:
      return { ...state, loading: true, error: false };
    case types.ADD_INVENTORY_SUCCESS:
      return { ...state, loading: false };
    case types.ADD_INVENTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default combineReducers({
  getSupplierProducts,
  getMyProducts,
  addProductToStore,
  addInventory,
  publishToStore,
  custom_products,
  blank_products,
});
