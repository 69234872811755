import * as types from './types';
import { apiService } from '../../middlewares';

export function createProfile(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_PROFILE, payload: true });
    return apiService.post('/users/register/', data)
      .then((response) => {
        dispatch({
          type: types.CREATE_PROFILE_SUCCESS,
          data: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_PROFILE_FAILED,
          isLoading: false,
          error,
        });
        throw error;
      });
  };
}

export function getProfile() {
  return (dispatch) => {
    dispatch({ type: types.FETCH_PROFILE, payload: true });
    return apiService.get('/users/me/')
      .then((response) => {
        dispatch({
          type: types.FETCH_PROFILE_SUCCESS,
          data: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: types.FETCH_PROFILE_FAILED,
          isLoading: false,
          error,
        });
        throw error;
      });
  };
}

export function clearError() {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_FETCH_PROFILE_ERROR });
  };
}

/*
export function updateShopLogo(id, image) {
    return (dispatch) => {
        return apiService.put(`/shop/${id}/`, data)
            .then((response) => {
                dispatch({
                    type: types.UPDATE_SHOP_SUCCESS,
                    data:  response.data,
                });
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: types.UPDATE_SHOP_FAILED,
                    isLoading: false,
                    error: error
                });
            });
    };
}
*/

export function updatePayment(data) {
  return (dispatch) => apiService.put('/shop/')
    .then((response) => {
      dispatch({
        type: types.UPDATE_PAYMENT_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_PAYMENT_FAILED,
        isLoading: false,
        error,
      });
      throw error;
    });
}

export function updateProfile(id, data) {
  return (dispatch) => apiService.put(`/shop/${id}/`, data)
    .then((response) => {
      dispatch({
        type: types.UPDATE_SHOP_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_SHOP_FAILED,
        isLoading: false,
        error,
      });
      throw error;
    });
}

export function updatePlan(data, status = '') {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_PLAN });
    return apiService.put('/shop/update_plan/', { plan_id: data })
      .then((response) => {
        dispatch({
          type: types.UPDATE_PLAN_SUCCESS,
          data: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_PLAN_FAILED,
          isLoading: false,
          error,
        });
        throw error;
      });
  };
}

export function addPayment(data) {
  return (dispatch) => apiService.post('/shop/update_payment/', data)
    .then((response) => {
      dispatch({
        type: types.ADD_PAYMENT_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.ADD_PAYMENT_FAILED,
        isLoading: false,
        error,
      });
      throw error;
    });
}

export function updateShop(id, data) {
  const bodyFormData = new FormData();
  if (data.logo) { bodyFormData.append('logo', data.logo); }

  if (data.logo_uploaded) { bodyFormData.append('logo_uploaded', data.logo_uploaded); }
  if (data.url) { bodyFormData.append('url', data.url.toLowerCase()); }
  if (data.subscription_status) { bodyFormData.append('subscription_status', data.subscription_status); }
  if (data.onboarding_step) { bodyFormData.append('onboarding_step', data.onboarding_step); }
  if (data.plan) { bodyFormData.append('plan', data.plan); }
  if (data.custom_product_status) { bodyFormData.append('custom_product_status', data.custom_product_status); }

  return (dispatch) => apiService.patch(`/shop/${id}/`, bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then((response) => {
      dispatch({
        type: types.UPDATE_SHOP_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_SHOP_FAILED,
        isLoading: false,
        error,
      });
      throw error;
    });
}
