import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function MyOrdersIcon(props) {
  return (
    <>
      <SvgIcon {...props} className="icon icon--hover" viewBox="0 0 20 20">>
        <g>
          <defs>
            <rect id="SVGID_1_" width="18" height="18" />
          </defs>
          <clipPath id="SVGID_00000042002992205980823540000002724733668690753977_">
            <use xlinkHref="#SVGID_1_" overflow="visible" />
          </clipPath>
          <g id="Icons" clipPath="url(#SVGID_00000042002992205980823540000002724733668690753977_)">
            <g id="Outlined" transform="translate(-783.000000, -337.000000)">
              <g id="Action" transform="translate(100.000000, 100.000000)">
                <g id="Outlined-_x2F_-Action-_x2F_-list" transform="translate(680.000000, 230.000000)">
                  <g>
                    <path
                      id="_xD83D__xDD39_-Icon-Color"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="#5E5E5E"
                      d="M7,17h14v-2H7V17z M7,21h14
							v-2H7V21z M7,11v2h14v-2H7z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>

          <rect y="8" clipPath="url(#SVGID_00000042002992205980823540000002724733668690753977_)" fillRule="evenodd" clipRule="evenodd" fill="#E88A80" width="2" height="2" />

          <rect y="12" clipPath="url(#SVGID_00000042002992205980823540000002724733668690753977_)" fillRule="evenodd" clipRule="evenodd" fill="#E88A80" width="2" height="2" />

          <rect y="4" clipPath="url(#SVGID_00000042002992205980823540000002724733668690753977_)" fillRule="evenodd" clipRule="evenodd" fill="#E88A80" width="2" height="2" />
        </g>
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">>
        <g>
          <defs>
            <rect id="SVGID_1_" width="18" height="18" />
          </defs>
          <clipPath id="SVGID_00000008137546937223620380000018066210911732328333_">
            <use xlinkHref="#SVGID_1_" overflow="visible" />
          </clipPath>
          <g id="Icons" clipPath="url(#SVGID_00000008137546937223620380000018066210911732328333_)">
            <g id="Outlined" transform="translate(-783.000000, -337.000000)">
              <g id="Action" transform="translate(100.000000, 100.000000)">
                <g id="Outlined-_x2F_-Action-_x2F_-list" transform="translate(680.000000, 230.000000)">
                  <g>
                    <path
                      id="_xD83D__xDD39_-Icon-Color"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill="#5E5E5E"
                      d="M3,17h2v-2H3V17z M3,21h2v-2
							H3V21z M3,13h2v-2H3V13z M7,17h14v-2H7V17z M7,21h14v-2H7V21z M7,11v2h14v-2H7z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </SvgIcon>
    </>
  );
}

export default MyOrdersIcon;
