import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { profileOperations } from '../../../state/ducks/profile/index';

import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';

import ShopifyOnboardingStepOne from './shopifyOnboardingStepOne';
import ShopifyOnboardingStepTwo from './shopifyOnboardingStepTwo';
import ShopifyOnboardingStepThree from './shopifyOnboardingStepThree';
import ProgressStepper from '../../../components/progressStepper';

import Logo from '../../../images/logo.png';

function getSteps() {
    return ['Confirm who you are', 'Upload Your Logo', 'Pick your plan'];
}

function ShopifyOnboarding(props) {
    const { profile, updateShop } = props;
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const steps = getSteps();

    useEffect(() => {
        if (profile?.shop[0]?.onboarding_step < 3) {
            setOpen(true);
            setActiveStep(profile?.shop[0].onboarding_step);
        }
    },[]);

    const handleNext = () => {
        setLoading(true);
        updateShop(profile.shop[0].id, { onboarding_step: activeStep + 1})
          .then(() => {
            setLoading(false);
            setActiveStep((activeStep) => activeStep + 1)
          })
    };

    const handleBack = () => {
        if(activeStep>0){
          setActiveStep((activeStep) => activeStep - 1);
        }
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <ShopifyOnboardingStepOne loading={loading} nextStep={handleNext} />;
            case 1:
                return <ShopifyOnboardingStepTwo loading={loading} nextStep={handleNext} />;
            case 2:
                return <ShopifyOnboardingStepThree loading={loading} handleBack={handleBack} nextStep={handleNext} />;
            default:
                return '';
        }
    }

    return (
      <div className="root template-onboarding">
        <Paper className="paper" variant="outlined">

          <div className="welcome">
            <Typography className="text--bold text--nunito text--upper" variant="h4">Welcome To </Typography>
            <img
            src={Logo}
            alt="Logo"
            className="logo"
            />
          </div>

          <div className="modal-content">
            {getStepContent(activeStep)}
          </div>

          <div className="stepper pt-10">
            {activeStep < 3 &&
              <ProgressStepper alternativeLabel activeStep={activeStep} steps={steps} handleBack={handleBack}/>
            }
          </div>
        </Paper>
      </div>
    );
}

ShopifyOnboarding.propTypes = {
  profile: PropTypes.objectOf(),
  getProfile: PropTypes.func,
  updateShop: PropTypes.func,
} 

ShopifyOnboarding.defaultProps = {
    profile: {},
    getProfile: () => {},
};


const mapStateToProps = (state) => ({
    profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
    getProfile: profileOperations.getProfile,
    updateShop: profileOperations.updateShop,
};


export default connect(mapStateToProps, mapDispatchToProps)(ShopifyOnboarding);
