import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function MyProductsIcon(props) {
  return (
    <>
      <SvgIcon {...props} className="icon icon--hover" viewBox="0 0 20 20">
        <g>
          <path
            id="_xD83D__xDD39_-Primary-Color"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#5E5E5E"
            d="M13.1,9.9
		c0.6,0,1.3-0.4,1.5-0.9l3.2-5.9c0.3-0.6-0.1-1.4-0.8-1.4H3.8L3,0H0v1.8h1.8L5,8.6l-1.3,2.2C3.2,12,4,13.5,5.4,13.5h10.8v-1.8H5.4
		l1-1.8H13.1z M4.7,3.6h10.9l-2.5,4.5H6.8L4.7,3.6z M5.4,14.4c-1,0-1.8,0.8-1.8,1.8S4.4,18,5.4,18s1.8-0.8,1.8-1.8
		S6.4,14.4,5.4,14.4z M14.4,14.4c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S15.4,14.4,14.4,14.4z"
          />
          <polygon
            id="_xD83D__xDD39_-Secondary-Color"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#E88A80"
            points="13.1,8.1 15.6,3.6
		4.7,3.6 6.8,8.1"
          />
        </g>
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">
        <g id="Icons">
          <g id="Outlined" transform="translate(-169.000000, -464.000000)">
            <g id="Action" transform="translate(100.000000, 100.000000)">
              <g id="Outlined-_x2F_-Action-_x2F_-shopping_x5F_cart" transform="translate(68.000000, 362.000000)">
                <g>
                  <path
                    id="_xD83D__xDD39_-Icon-Color"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill="#5E5E5E"
                    d="M14.1,11.9
						c0.6,0,1.3-0.4,1.5-0.9l3.2-5.9c0.3-0.6-0.1-1.4-0.8-1.4H4.8L4,2H1v1.8h1.8L6,10.6l-1.3,2.2C4.2,14,5,15.5,6.4,15.5h10.8v-1.8
						H6.4l1-1.8H14.1z M5.7,5.6h10.9l-2.5,4.5H7.8L5.7,5.6z M6.4,16.4c-1,0-1.8,0.8-1.8,1.8S5.4,20,6.4,20s1.8-0.8,1.8-1.8
						S7.4,16.4,6.4,16.4z M15.4,16.4c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S16.4,16.4,15.4,16.4z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </SvgIcon>
    </>
  );
}

export default MyProductsIcon;
