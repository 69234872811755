import React, { useState } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function FindProductsIcon(props) {
  const { hover } = props;
  return (
    <>
      <SvgIcon {...props} className="icon icon--hover" viewBox="0 0 20 20">>
        <g id="Icons">
          <g id="Two-Tone" transform="translate(-443.000000, -377.000000)">
            <g id="Action" transform="translate(100.000000, 100.000000)">
              <g id="Two-Tone-_x2F_-Action-_x2F_-search" transform="translate(340.000000, 274.000000)">
                <g>
                  <polygon style={{ fill: 'none' }} id="Path" className="st0" points="0,0 24,0 24,24 0,24" />
                  <path
                    id="_xD83D__xDD39_-Primary-Color"
                    className="st1"
                    style={{ 'fill-rule': 'evenodd', 'clipRule': 'evenodd', fill: '#5E5E5E' }}
                    d="M15.5,14h-0.8l-0.3-0.3c1-1.1,1.6-2.6,1.6-4.2C16,5.9,13.1,3,9.5,3
                    S3,5.9,3,9.5S5.9,16,9.5,16c1.6,0,3.1-0.6,4.2-1.6l0.3,0.3v0.8l5,5l1.5-1.5L15.5,14z M9.5,14C7,14,5,12,5,9.5S7,5,9.5,5
                    S14,7,14,9.5S12,14,9.5,14z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <path style={{ 'fill-rule': 'evenodd', 'clipRule': 'evenodd', fill: '#E88A80' }} className="st2" d="M5.9,11c-2.5-0.4-4.1-2.7-3.8-5.1C2.3,4.3,3.4,3,4.9,2.4l5.8,5.9C9.9,10.1,8,11.3,5.9,11z" />
      </SvgIcon>

      <SvgIcon {...props} className="icon icon--normal" viewBox="0 0 20 20">
        <g id="Icons">
          <g id="Two-Tone" transform="translate(-443.000000, -377.000000)">
            <g id="Action" transform="translate(100.000000, 100.000000)">
              <g id="Two-Tone-_x2F_-Action-_x2F_-search" transform="translate(340.000000, 274.000000)">
                <g>
                  <polygon id="Path" className="st0" style={{ fill: 'none' }} points="0,0 24,0 24,24 0,24" />
                  <path
                    id="_xD83D__xDD39_-Primary-Color"
                    className="st1"
                    style={{ 'fillRule': 'evenodd', 'clipRule': 'evenodd', fill: '#5E5E5E' }}
                    d="M15.5,14h-0.8l-0.3-0.3c1-1.1,1.6-2.6,1.6-4.2C16,5.9,13.1,3,9.5,3
                    S3,5.9,3,9.5S5.9,16,9.5,16c1.6,0,3.1-0.6,4.2-1.6l0.3,0.3v0.8l5,5l1.5-1.5L15.5,14z M9.5,14C7,14,5,12,5,9.5S7,5,9.5,5
                    S14,7,14,9.5S12,14,9.5,14z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>

      </SvgIcon>

    </>
  );
}

export default FindProductsIcon;
