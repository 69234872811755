import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from "react-redux";
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import LinearProgress from '@material-ui/core/LinearProgress';
import { profileOperations } from "../state/ducks/profile";
import { sessionOperations } from "../state/ducks/session";
import Container from '@material-ui/core/Container';
import {Helmet} from "react-helmet";
import {SHOP_ONBOARDING_STANDALONE,  SUBSCRIPTION_STATUS_FAILED, SUBSCRIPTION_STATUS_UNPAID, PLAN_GROWTH_ID, PLAN_SCALE_ID } from '../constants';

import routes from '../routes';
import { 
    Redirect,
    Switch,
    Route,
    useLocation
} from "react-router-dom";

import BlankaAppBar from '../components/AppBar';
import SideBar from '../components/SideBar';
import AddPaymentMethodModal from '../components/modals/addPaymentMethodModal';
import PaymentFailedModal from '../components/modals/PaymentFailedModal';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: '#fff',
        width: '100%',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        //paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        width: '100%',
        maxWidth: '1400px'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',

    },
    fixedHeight: {
        height: 240,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
        zIndex: 1200
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: '16px'
    },

}));


function usePageViews(user) {
    let location = useLocation()
  
    useEffect(
      () => {
        //set as user
        if (user !== null){
            window.Intercom('update', {
                app_id: 'pugbkanz',
                email: user?.email,
                name: user?.name,
                user_id: user?.email,
                created_at: parseInt((new Date()).getTime()/1000)
            });

        
            window.FS.identify(user?.id, {
                displayName: user?.name,
                email: user?.email,
            });
            
            //ReactGA.set({ userId: user?.email });
        }

        window.gtag('event', 'page_view',{
            'page_title' : location.pathname,
            'page_location' : location.pathname
        });

       
      },
      [location]
    )
  }
  

function Main(props) {
    
    const classes = useStyles();
    const theme = useTheme();
    const { children, session, setToken, getProfile, user, isLoading } = props
    const [mobileOpen, setMobileOpen] = useState(false);
    usePageViews(user);

    // verifyToken, logout, clearToken, productLoading

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (<SideBar />);

    useEffect(() => {
        getProfile().then((response)=>{

        })
        .catch((err)=>{
            console.log('Error getting profile')
           
        });
    }, []);


    const showPaymentRequired = () => {

        if(user && !user?.shop[0].has_payment_method && user?.shop[0].shop_signup_typ==SHOP_ONBOARDING_STANDALONE){
            if(user.shop[0].plan == PLAN_GROWTH_ID || user.shop[0].plan == PLAN_SCALE_ID ){
                return true;
            }
        }

        return false;
    }

    const showFailedPaymentModal = () => {

        if(user && (user?.shop[0].subscription_status == SUBSCRIPTION_STATUS_FAILED || user?.shop[0].subscription_status == SUBSCRIPTION_STATUS_UNPAID)){
            return true;
        }

        return false;
    }


    if (user !== null){
        window.Intercom('boot', {
            app_id: 'pugbkanz',
            email: user?.email,
            user_id: user?.id,
            created_at: user?.date_joined,
            custom_attributes: {
                brand_plate_created: user?.shop[0].brand_plate_created,
                subscription_status: user?.shop[0].subscription_status,
                plan: user?.shop[0].plan_name,
            },
            company: {
                company_id: user?.shop[0].id,
                website: user?.shop[0].url,
                name: user?.shop[0].id,
                Plan: user?.shop[0].plan_name,
                brand_plate_created: user?.shop[0].brand_plate_created,
                subscription_status: user?.shop[0].subscription_status,
              }
        });
    }

    
    

    if(user && !user.loading && !user?.shop[0]?.onboarding_complete){
        return <Redirect to="/signup"/>;
    }

        
    return (


        <div className={classes.root}>


            { isLoading && 
                <LinearProgress />
            }

                <CssBaseline />

                <BlankaAppBar onDrawerToggle={handleDrawerToggle} />

                <nav className={classes.drawer} aria-label="navigation">

                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>

                    <div className={classes.toolbar} />

                    <Container maxWidth={false} className={classes.container}>
                       
                        {children}

                    </Container>

                 

                </main>
 
                <AddPaymentMethodModal open={showPaymentRequired()}></AddPaymentMethodModal>
                <PaymentFailedModal  open={showFailedPaymentModal()}></PaymentFailedModal>
           
        </div>

    );
}


Main.defaultProps = {
    user: {},
    isLoading: false,
    hasErrored: false
};

const mapStateToProps = (state) => ({
    session: state.session.session,
    user: state.profile.getProfile.profile,
    productLoading: state.products.getSupplierProducts.loading,
    isLoading: state.app.state.loading
});

const mapDispatchToProps = {
    setToken: sessionOperations.setToken,
    verifyToken: sessionOperations.verifyToken,
    logout: sessionOperations.logout,
    clearToken: sessionOperations.clearToken,
    getProfile: profileOperations.getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
