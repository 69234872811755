import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import HelpIcon from './icons/HelpIcon';

import routes from '../routes';

import Logo from '../images/logo.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  drawer: {
    width: drawerWidth,
    overflow: 'hidden',
    flexShrink: 0,
    backgroundColor: theme.palette.white,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: '0px',
    backgroundColor: theme.palette.white,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  listItem: {
    root: {
    },
  },
  categoryHeaderText: {
    fontSize: 12,
    letterSpacing: '2.4px',
    fontWeight: 400,
    color: theme.palette.gray,
    textTransform: 'uppercase',
  },
  categorySubText: {
    fontSize: 8,
    letterSpacing: '2.4px',
    fontWeight: 400,
    marginLeft: '10px',
    color: theme.palette.gray,
    textTransform: 'uppercase',
  },
  content: {
    flexGrow: 1,
    color: 'white',
    padding: theme.spacing(3),
  },
  icon: {
    color: theme.palette.gray,
    // minWidth: '40px',
  },
  sidebarList: {
    paddingTop: '20px',
  },
  nested: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
}));

export default function SideBar(props) {
  const classes = useStyles();

  const getIcon = (component, hover) => React.createElement(component, {
    hover,
  });

  return (
    <div style={{ zIndex: '1200' }}>
      <img alt="Logo" src={Logo} style={{ width: '100px', padding: '10px', margin: '0 auto' }} />
      <div className={classes.toolbar} />
      <Divider />
      <List className={classes.sidebarList}>
        {routes.filter((route) => route.menu).map((route, index) => (

          <div key={index}>
            <NavLink
              activeClassName="activeItem"
              className="navItem"
              exact={route.exact}
              to={route.path}
              style={{ textDecoration: 'none' }}
              key={index}
            >
              <ListItem button key={route.name}>
                <ListItemIcon>

                  {route.icon}

                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderText,
                  }}
                  primary={route.name}
                />

              </ListItem>
            </NavLink>

            { route.isParent

              && (
              <List className="submenu" component="div" disablePadding>
                <ListItem button className={classes.nested}>

                  <NavLink
                    activeClassName="activeItem"
                    className="navItem"
                    exact={route.exact}
                    to="/orders"
                    style={{ textDecoration: 'none' }}
                    key={index}
                  >
                    <ListItemText
                      classes={{
                        primary: classes.categorySubText,
                      }}
                      primary="Customer Orders"
                    />
                  </NavLink>

                </ListItem>
                <ListItem button className={classes.nested}>

                  <NavLink
                    activeClassName="activeItem"
                    className="navItem"
                    exact={route.exact}
                    to="/inventory-orders"
                    style={{ textDecoration: 'none' }}
                    key={index}
                  >
                    <ListItemText
                      classes={{
                        primary: classes.categorySubText,
                      }}
                      primary="Inventory Orders"
                    />
                  </NavLink>

                </ListItem>
              </List>
              )}

          </div>

        ))}

        <NavLink
          activeClassName=""
          className="navItem"
          to="#"
          style={{ textDecoration: 'none' }}
          key={99}
          onClick={(e) => { window.open('http://faq.blankabrand.com/'); }}
        >
          <ListItem button key="help">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.categoryHeaderText,
              }}
              primary="Help"
            />

          </ListItem>
        </NavLink>

      </List>
    </div>
  );
}
