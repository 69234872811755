import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { sessionOperations } from '../../state/ducks/session';

import LoadingButton from '../../components/buttons/loadingButton';
import Logo from '../../images/logo.png';

function PasswordReset(props) {
  const {
    sendResetLink,
  } = props;
  
  const [resetEmail, setResetEmail] = useState('');
  const [message, setMessage] = useState({
    message: '',
    status: 'success'
    }) 
  useEffect(() => {

  }, []);

  const setPasswordReset = () => {
   
    sendResetLink({ email: resetEmail })
      .then((response) => {
        if (response?.data.status === 'OK') {
          setMessage({
            message: 'Password reset link sent, please check your inbox',
            status: 'success'
          })
        } else {
          setMessage({
            message: 'We could not send your password reset email, please contact us',
            status: 'error'
          })
        }
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setPasswordReset();
  };

  return (
    <div className="password-reset-template">

      <Paper className="paper" variant="outlined">

        <div className="img-container">
          <img
            src={Logo}
            alt="Logo"
            className="logo"
          />
        </div>

        <div className="signup-container mb-10">
          <Typography className="signup-container__title text--nunito">
            Password Reset
          </Typography>

          <NavLink to="/login" className="signup-container__sign-in text--nunito">
            Return to login
          </NavLink>
        </div>


        <div className="form-container">
          <form className="form" noValidate autoComplete="off" onSubmit={onSubmit}>

           
            <TextField
              id="Email"
              className="input-fields"
              label="Email"
              value={resetEmail}
              required
              InputLabelProps={{ shrink: true }}
              name="email"
              type="email"
              variant="outlined"
              onChange={(e) => setResetEmail(e.target.value)}
            />
   
            <LoadingButton disabled={!resetEmail} className="button__reset" variant="contained" color="primary" onClick={setPasswordReset}>
              Reset Password
            </LoadingButton>
          </form>
            <p className={`text__${message.status}`}>{message.message}</p>
            {/* <p className={`text__${messagesStatus.success_status}`}>{successMessage}</p> */}
        </div>
      </Paper>
    </div>
  );
}

PasswordReset.propTypes = {
  sendResetLink: PropTypes.func,
};

PasswordReset.defaultProps = {
  sendResetLink: () => {},
};

const mapStateToProps = (state) => ({
  session: state.session.session,
});

const mapDispatchToProps = {
  sendNewPassword: sessionOperations.sendNewPassword,
  sendResetLink: sessionOperations.sendResetLink,
  clearError: sessionOperations.clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
