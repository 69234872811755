import { createMuiTheme } from '@material-ui/core/styles';

const COLOR_PRIMARY = '#d46e5a';
const COLOR_GRAY = '#5e5e5e';
const COLOR_MEDIUM_GRAY = '#979797';
const COLOR_BLUE = '#246071';

const theme = createMuiTheme({
  typography: {
    h3: {
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 400,
    },
    h5: {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: '2.4px',
    },
    h6: {
      fontSize: 12,
      letterSpacing: '2.4px',
      fontWeight: 400,
      color: COLOR_GRAY,
      textTransform: 'uppercase',
    },

    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {

    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_GRAY,
    },
    info: {
      main: COLOR_BLUE,
    },
    text: {
      primary: COLOR_MEDIUM_GRAY,
      secondary: COLOR_GRAY,
    },
    success: {
      main: '#4caf50',
    },
    warning: {
      main: '#ff9800',
    },

    gray: COLOR_GRAY,
    white: '#FFF',
    blue: COLOR_BLUE,
  },

  overrides: {
    MuiInput: {
      input: {
        borderRadius: '0px',
      },
    },
    MuiChip: {
      root: {
        borderRadius: '8px',
        height: '22px',
      },
      label: {
        color: '#FFF',
      },
    },
  },
  buttons: {
    primary: {
      backgroundColor: COLOR_PRIMARY,
      color: '#FFF',
    },
    secondaryOutline: {
      backgroundColor: 'transparent',
      color: COLOR_GRAY,
      border: `1px solid ${COLOR_GRAY}`,
    },
    info: {
      backgroundColor: COLOR_BLUE,
      color: '#FFF',
    },
  },
});

export default theme;
