import { combineReducers } from 'redux';
import * as types from './types';

const items = JSON.parse(localStorage.getItem('items'));
const initialState = {
  items: items || [],
  loading: true,
  error: false,
  merchant_hold_inventory: false,
};

const sampleitems = JSON.parse(localStorage.getItem('samplesitems'));
const sampleInitialState = {
  items: sampleitems || [],
  coupon: null,
  loading: true,
  error: false,
};

function cart(state = initialState, action) {
  switch (action.type) {
    case types.ADD_TO_CART:

      // localStorage.setItem('items', JSON.stringify([...state.items, action.product]));

      var { product } = action.payload;
      var { amount } = action.payload;

      var { id } = product;
      var updatedProduct = { ...product };
      var found = false;
      var newArray = [];

      state.items.map((item) => {
        if (item.id === id) {
          found = true;
          const newAmount = Number(item.toOrder) + Number(amount);
          newArray.push({ ...updatedProduct, toOrder: newAmount });
        } else {
          newArray.push(item);
        }
      });

      if (!found) {
        newArray.push({ ...product, toOrder: amount });
      }

      localStorage.setItem('items', JSON.stringify(newArray));

      return {
        ...state,
        loading: true,
        error: false,
        items: [...newArray],
      };
    case types.REMOVE_FROM_CART:

      var { id } = action;
      var filteredItems = state.items.filter((el) => el.id !== id);
      localStorage.setItem('items', JSON.stringify(filteredItems));

      return {
        ...state,
        loading: false,
        items: filteredItems,
      };

    case types.UPDATE_CART:

      var { product } = action.payload;
      var amount = product.toOrder;
      var { id } = product;
      var updatedProduct = { ...product };
      var newArray = [];

      state.items.map((item) => {
        if (item.id === id) {
          newArray.push({ ...updatedProduct });
        } else {
          newArray.push(item);
        }
      });

      localStorage.setItem('items', JSON.stringify(newArray));

      return {
        ...state,
        loading: false,
        items: newArray,
      };
    case types.UPDATE_CART_HOLD_INVENTORY:
      return {
        ...state,
        merchant_hold_inventory: action.payload.merchant_hold_inventory,
      };

    case types.CLEAR_CART:

      localStorage.removeItem('items');

      return {
        ...state,
        loading: false,
        error: true,
        items: [],
        merchant_hold_inventory: false,
      };

    default:
      return state;
  }
}

function samplesCart(state = sampleInitialState, action) {
  switch (action.type) {
    case types.SAMPLES_ADD_TO_CART:

      var { product } = action.payload;
      var { amount } = action.payload;

      var { id } = product;
      var updatedProduct = { ...product };
      var found = false;
      var newArray = [];

      state.items.map((item) => {
        if (item.id === id) {
          found = true;
          const newAmount = item.toOrder + amount;
          newArray.push({ ...updatedProduct, toOrder: newAmount });
        } else {
          newArray.push(item);
        }
      });

      if (!found) {
        newArray.push({ ...product, toOrder: amount });
      }

      localStorage.setItem('samplesitems', JSON.stringify(newArray));
      return {
        ...state,
        loading: true,
        error: false,
        items: [...newArray],
      };
    case types.SAMPLES_REMOVE_FROM_CART:

      var { id } = action;
      var filteredItems = state.items.filter((el) => el.id !== id);
      localStorage.setItem('samplesitems', JSON.stringify(filteredItems));

      return {
        ...state,
        loading: false,
        items: filteredItems,
      };

    case types.SAMPLES_UPDATE_CART:

      var { product } = action.payload;
      var amount = product.toOrder;
      var { id } = product;
      var updatedProduct = { ...product };
      var newArray = [];

      state.items.map((item) => {
        if (item.id === id) {
          newArray.push({ ...updatedProduct });
        } else {
          newArray.push(item);
        }
      });

      localStorage.setItem('samplesitems', JSON.stringify(newArray));

      return {
        ...state,
        loading: false,
        items: newArray,
      };

    case types.SAMPLES_CLEAR_CART:

      localStorage.setItem('samplesitems', JSON.stringify([]));

      return {
        ...state,
        loading: false,
        error: true,
        items: [],
      };

    default:
      return state;
  }
}

function shipping(state = { loading: false, error: false }, action) {
  switch (action.type) {
    case types.CART_UPDATE_SHIPPING:
      return {
        ...state,
        shipping: { ...action.payload.shipping },
        loading: false,
        error: false,
      };
    case types.CART_CLEAR_SHIPPING:
      return {
        ...state,
        shipping: null,
        loading: false,
        error: false,
        shipping_cost: 0,
        shipping_cost_loading: false,
      };
    case types.GET_SHIPPING_COST:
      return {
        ...state,
        shipping_cost_loading: true,
        shipping_cost: null,
      };

    case types.GET_SHIPPING_COST_SUCCESS:

      return {
        ...state,
        shipping_cost: action.data.shipping_cost,
        shipping_cost_loading: false,
        loading: false,
        error: false,
      };
    case types.GET_SHIPPING_COST_FAILED:
      return {
        ...state,
        shipping_cost_loading: false,
        error: true,
      };

    default:
      return state;
  }
}

function samplesCheckout(state = { loading: false, error: false, coupon: null }, action) {
  switch (action.type) {
    case types.SAMPLE_ORDER:
      return { ...state, loading: true, error: false };
    case types.SAMPLE_ORDER_SUCCESS:
      return { ...state, loading: false, coupon: null };
    case types.SAMPLE_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.CHECKOUT_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.data,
      };
    case types.RESET_COUPON:
      return {
        ...state,
        coupon: null,
      };

    default:
      return state;
  }
}

function brand_plate_checkout(state = { loading: false, error: false }, action) {
  switch (action.type) {
    case types.ORDER_BRAND_PLATE:
      return { ...state, loading: true, error: false };
    case types.ORDER_BRAND_PLATE_SUCCESS:
      return { ...state, loading: false };
    case types.ORDER_BRAND_PLATE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}

export default combineReducers({
  cart,
  samplesCart,
  samplesCheckout,
  shipping,
});
