import { combineReducers } from 'redux';
import * as types from './types';

function getProfile(state = { profile: null, loading: false, error: false }, action) {
  switch (action.type) {
    case types.FETCH_PROFILE:
      return { ...state, loading: true, error: false };

    case types.FETCH_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: action.data };
    case types.FETCH_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.CLEAR_FETCH_PROFILE_ERROR:
      return {
        ...state,
        error: false,
      };
    case types.UPDATE_SHOP_SUCCESS:

      // update shop part of profile
      var shop = action.data;
      var profile = { ...state.profile };
      profile.shop[0] = shop;
      return { ...state, loading: false, profile };
    case types.CLEAR_PROFILE:
      return {
        ...state, profile: null, loading: false, error: false,
      };
    default:
      return state;
  }
}

function updatePlan(state = { loading: false, error: false }, action) {
  switch (action.type) {
    case types.UPDATE_PLAN:
      return { ...state, loading: true, error: false };
    case types.UPDATE_PLAN_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.UPDATE_PLAN_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

function createProfile(state = { loading: false, error: false }, action) {
  switch (action.type) {
    case types.CREATE_PROFILE:
      return { ...state, loading: true, error: false };
    case types.CREATE_PROFILE_SUCCESS:
      return { ...state, loading: false, error: false };
    case types.CREATE_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}

export default combineReducers({
  getProfile,
  create_profile: createProfile,
  updatePlan,
});
