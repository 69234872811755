import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export const InventoryAddedNotification = ({ closeToast, toastProps }) => (
  <div>

    <span className="toast-success-text">Success!</span>
    <span className="toast-content-text">Inventory Added To</span>
    <Link className="toast-link" to="/checkout">Cart</Link>

  </div>
);

export const addSampleToCartSuccess = ({ closeToast, toastProps }) => (
  <div>
    <span className="toast-success-text">Success!</span>

    <span className="toast-content-text">Sample Added To</span>

    <Link className="toast-link" to="/checkout">Cart</Link>

  </div>
);

export const brandPlateSuccess = ({ closeToast, toastProps }) => (
  <div>
    <span className="toast-success-text">Success!</span>

    <span className="toast-content-text">Your Brand Plate has been ordered</span>

  </div>
);

export const addToStoreSuccess = ({ closeToast, toastProps }) => (
  <div>
    <span className="text--bold toast-success-text">Success!</span>

    <span className="toast-content-text">This product has been added</span>

  </div>
);

export const publishToStoreSuccess = ({ closeToast, toastProps }) => (
  <div>
    <span className="toast-success-text">Success!</span>

    <span className="toast-content-text">Product Published</span>

  </div>
);

export const ServerError = ({ closeToast, toastProps, message }) => (
  <div>
    <span className="toast-error -text">Error</span>

    <span className="toast-content-text">message</span>

  </div>
);

export const AuthenticationError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">Authentication Error!</h2>

    <p className="toast-content-text">Please Login Again</p>

  </div>
);

export const NetworkError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">Error!</h2>

    <p className="toast-content-text">Could not connect to server</p>

  </div>
);

export const InventoryWithSampleError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">Sorry!</h2>

    <p className="toast-content-text">

      You can't order samples and inventory at the same time. Please clear your cart to continue

    </p>

  </div>
);

export const NoLogoError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">Oops! You need to upload a logo first!</h2>

    <Link className="toast-link" to="/profile?action=upload-logo">Click here to upload one</Link>

  </div>
);

export const NoStoreConnectionError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">You need to connect your store before you can publish products</h2>
    <Link className="toast-link" to="/profile/connect">Click here to connect your store</Link>
  </div>

);

export const NoBillingMethodError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">Oops! You need to upload billing details first! Click here to add your details</h2>
    <Link className="toast-link" to="/profile/billing/update">Click here to add a billing method</Link>
  </div>

);

export const SampleWithInventoryError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">WHOOPS!</h2>

    <p className="toast-content-text">
      You can't order samples and inventory at the same time. Please clear your shopping bag to continue

    </p>

  </div>
);

export const SampleKitError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">WHOOPS!</h2>

    <p className="toast-content-text">
      sample kits are only available for
      <span classs="text--bold">SAMPLE ORDERS</span>

    </p>

  </div>
);

export const FreePlanLimitError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">Whoops! You’ve reached your FREE Plan Limit</h2>
  </div>
);

export const LogovisualizerError = ({ closeToast, toastProps, message }) => (
  <div>
    <h2 className="toast-error-text">Let's show you how your logo looks on these products first! In order to add Private Label products to "My Products" you first need to hit the "VISUALIZE WITH MY LOGO" button in the top right corner of this page. </h2>
  </div>
);

export const notifications = {
  noStoreConnectedError() { toast.error(NoStoreConnectionError); },
  noBillingMethodError() { toast.error(NoBillingMethodError); },
};
