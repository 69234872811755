import React from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';

import { sessionOperations } from '../../../state/ducks/session';
import { profileOperations } from '../../../state/ducks/profile';

import SignUpForm from '../../../components/modals/SignUpForm';

function StandaloneOnboardingStepOne(props) {
  const { nextStepStandalone } = props;
  return (
    <>
      <div className='signup-container mb-10'>
        <Typography className='signup-container__title text--nunito'>
          Sign up for free!
        </Typography>

        <NavLink to='/login' className='signup-container__sign-in text--nunito'>
          Or log in to your account
        </NavLink>
      </div>

      <SignUpForm
        standaloneOnboarding
        nextStepStandalone={nextStepStandalone}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  session: state.session.session,
  profile: state.profile.getProfile.profile,
  profileLoading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  createProfile: profileOperations.createProfile,
  updateShop: profileOperations.updateShop,
  getProfile: profileOperations.getProfile,
  login: sessionOperations.login,
  logout: sessionOperations.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneOnboardingStepOne);
