import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { productOperations } from '../../state/ducks/products';

import ProductItem from './productItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: 16,
    width: '50%',
    minWidth: '350px',
  },
  tableData: { padding: 8 },
}));

function MyProducts(props) {
  const classes = useStyles();
  const {
    products, getMyProducts,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await getMyProducts();
      setIsLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className={classes.root}>

      <Grid className="page-header mb-25" container>
        <Typography className="pb-25" variant="h6">My Products</Typography>
      </Grid>
      <Grid className={classes.paper} container spacing={2}>

        {products.length === 0 && !isLoading
                    && (
                      <Typography style={{ textAlign: 'left' }} className="pl-10 text--georgia" variant="h5" color="secondary">
                        Hey! You haven&apos;t added any products yet. Head over to the
                        {' '}
                        <Link to="/">
                          Find
                          Products
                        </Link>
                        {' '}
                        page to browse blanka&apos;s catalogue.
                      </Typography>
                    )}

        {products.map((product) => (
          <Grid item xs={12} md={12}>
            <ProductItem product={product} />
          </Grid>
        ))}

      </Grid>
    </div>
  );
}

MyProducts.propTypes = {
  products: PropTypes.array,
  getMyProducts: PropTypes.func,
};

MyProducts.defaultProps = {
  products: [],
  getMyProducts: () => {},
};

const mapStateToProps = (state) => ({
  products: state.products.getMyProducts.products,
  loading: state.products.getMyProducts.loading,
});

const mapDispatchToProps = {
  getMyProducts: productOperations.getMyProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProducts);
