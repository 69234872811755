import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

export default function FilterCard(props) {
  const {
    handleClick, children, categoryID, className, current,
  } = props;

  const checkActive = (categoryID) => {
    if (!current) {
      handleClick(categoryID);
    }
  };

  return (
    <Card
      disabled
      onClick={() => checkActive(categoryID)}
      className={`filter-card-template ${className} ${current ? 'is-active' : ''}`}
    >
      <Typography className="text--bold  text--nunito text__header">{children}</Typography>
    </Card>
  );
}

FilterCard.propTypes = {
  className: PropTypes.string,
  fetchFilterType: PropTypes.func,
  categoryID: PropTypes.number,
  current: PropTypes.number.isRequired,
};

FilterCard.defaultProps = {
  filterHeader: '',
  className: '',
  fetchFilterType: () => {},
  categoryID: 0,
};
