import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import {  makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../components/buttons/loadingButton';

import { cartOperations } from "../../state/ducks/cart";

const useStyles = makeStyles((theme) => ({

}));

function TotalsArea(props) {
    const classes = useStyles();
    const { shipping, items, checkCoupon,couponData, clearCoupon  } = props;
    const [code, setCode] = useState();
    const [coupon, setCoupon] = useState();
    const [loading, setLoading] = useState(false);
    const [couponMessage, setCouponMessage] = useState(false);

    

    useEffect(() => {

        if (couponData){
            setCoupon(couponData);
            setCode(couponData?.code);
        }

    }, [couponData]);

    const handleCheckCoupon = async () => {

        //is remove action
        if(coupon){
            setCoupon(null);
            setCode('');
            clearCoupon();
            return
        }

        try{

            setLoading(true);
            var response = await checkCoupon(code);

            if(!response.data.valid){
                setCouponMessage('Invalid code entered.');
                setLoading(false);
                setCode('');
                clearCoupon();
                setCoupon(null);
                return;
            }

            setCoupon(response.data)
            setLoading(false);

        }catch(e){
            console.log(e);
            setLoading(false);
        }
    }

    const getDiscount = (subtotal) =>{

        //check if subtract from total, absolute value so not under 0 
        if(coupon?.discount.amount_off){
            //if the discount is going to take more than the subtotal off return 0
            if(coupon?.discount.amount_off<subtotal){
                return subtotal;
            }else{
                return
            }
        }

        if(coupon?.discount.percent_off){
            console.log('percentage off');
            return subtotal + (subtotal*(coupon.discount.percent_off/100)); // convert percentage into decimal
        }  

        return 0.0

    }

    const calcDiscount = (subtotal) => {
        
        if(!coupon || coupon == null || !coupon.valid){
            return 0.0;
        }


        //check if subtract from total, absolute value so not under 0 
        if(coupon?.discount.amount_off){
            //if the discount is going to take more than the subtotal off return 0
            if((coupon?.discount.amount_off/100)>subtotal){
                return parseFloat(subtotal).toFixed(2);
            }else{
                return parseFloat(coupon.discount.amount_off/100).toFixed(2);//convert cents to decimal
            }
        }

        if(coupon?.discount.percent_off){
            return parseFloat((subtotal*(coupon.discount.percent_off/100))).toFixed(2); // convert percentage into decimal
        }

        return 0.0;
    }

    const getSubtotal = (items) => {
        var total = 0;
        items.forEach((item) => {
            total += (Number(item.cost) * item.toOrder);
        });
        return total.toFixed(2)

    }

    const calcSubTotal = (items) =>{
        var total = 0;
        items.forEach((item) => {
            total += (Number(item.cost) * item.toOrder);
        });

        return total - calcDiscount(total);
    }

    const calcFees = (items) =>{
        
        var total = calcSubTotal(items);
        
        if(!shipping.country) return ((total*0.029) + 0.3).toFixed(2);

        return (((total + calcShipping(shipping.country))*0.029) + 0.3).toFixed(2);
    }

    const calcShipping = () => {
        var shippingCost = 0;

        if(shipping.country?.code === 'CA' || shipping.country?.code === 'US'){
            shippingCost = 6;  
            
            let itemTotal = items.reduce((a,v) =>  a = a + v.toOrder , 0 );
            shippingCost += itemTotal * 2;


        }else{
            shippingCost = 9;  
            let itemTotal = items.reduce((a,v) =>  a = a + v.toOrder , 0 );
            shippingCost += itemTotal * 3;
        }
        return shippingCost
    }

    
    const calcTotal = (items) => {
        var total = 0;

        var total = calcSubTotal(items);
      
        if(shipping?.country){
            total += calcShipping()
            total = (total + (total*0.029) + 0.3);
        }else{
            total = (total + (total*0.029) + 0.3);
        }
        return parseFloat(total).toFixed(2);
    }


    return (
        
        <TableContainer className={classes.totalsContainer}>
            <Table className={classes.totalsTable} aria-label="">

                <TableBody>

                    <TableRow>
                        <TableCell colSpan={2}   >
                            <div className="coupon-row">
                                <TextField
                                        id="coupon"
                                        label="Discount Code"
                                        variant="outlined"
                                        value={code}
                                        InputProps={{readOnly: coupon?true:false}}
                                        required
                                        onChange={(e) => {setCode(e.target.value);setCouponMessage('')}}
                                    />
                        

                                {couponMessage &&
                                    <span className="text--error">{couponMessage}</span>
                                }
                                 
                            
                                <LoadingButton 
                                    disabled={!code} 
                                    onClick={handleCheckCoupon} 
                                    color="" 
                                    variant="" 
                                    loading={loading} 
                                    className={code ?'has-code':''} >
                                    {coupon ?'Remove':'Apply'} Discount
                                </LoadingButton>


                            </div>
                        

                        </TableCell>
                    </TableRow>

                    

                    <TableRow>
                        <TableCell>Subtotal</TableCell>
                        <TableCell>${getSubtotal(items)}</TableCell>
                    </TableRow>

                
                    <TableRow>
                        <TableCell>Discount</TableCell>
                        <TableCell>{coupon?.valid
                            ? `-$${calcDiscount(getSubtotal(items))}`
                            : "*"
                        }
                        </TableCell>
                    </TableRow>
                   

                    <TableRow>
                        <TableCell>Shipping</TableCell>
                        <TableCell>{shipping?.country
                            ? `$${calcShipping(shipping.country).toFixed(2)}`
                            : <span>*</span>
                        }</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Processing Fee</TableCell>
                        <TableCell>${calcFees(items)}</TableCell>
                    </TableRow>
                    

                    <TableRow className={classes.total}>
                        <TableCell>Total</TableCell>
                        <TableCell>${calcTotal(items)}</TableCell>
                    </TableRow>


                </TableBody>
            </Table>
        </TableContainer>
        
    );
}

TotalsArea.propTypes = {
    items: PropTypes.arrayOf,
    shipping: PropTypes.func,
}

TotalsArea.defaultProps = {
    shipping: () => {},
    items: []
};

const mapStateToProps = (state) => ({

    profile: state.profile.getProfile.profile,
    couponData: state.cart.samplesCheckout.coupon,
});

const mapDispatchToProps = {
    addShipping : cartOperations.samplesAddShipping,
    clearCart: cartOperations.samplesClearCart,
    checkout: cartOperations.samplesCheckout,
    checkCoupon: cartOperations.checkCoupon,
    clearCoupon: cartOperations.samplesClearCoupon
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalsArea);
