import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { cartOperations } from '../../state/ducks/cart';
import { productOperations } from '../../state/ducks/products';

import CheckoutItem from './CheckoutItem';
import InventoryCheckoutCart from './InventoryCheckoutCart';
import InventoryCheckoutBilling from './InventoryCheckoutBilling';
import InventoryCheckoutShipping from './InventoryCheckoutShipping';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: 16,
    width: '95%',
    // margin: 'auto'
    minWidth: '350px',
  },
  tableData: { padding: 8 },
  colorSwatch: {
    width: '50px',
    height: '50px',
  },
  totalsArea: {
    textAlign: 'right',
    marginTop: '20px',
  },
  total: {
    borderTop: `1px solid ${theme.palette.gray}`,

  },
  totalsTable: {
    width: '300px',
    marginLeft: 'auto',
    textAlign: 'right',
    marginBottom: '25px',
    '& td': {
      padding: '10px 0px',
      textAlign: 'right',
    },
  },
}));

function InventoryCheckout(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    items,
    shipping,
    addInventory,
    merchant_hold_inventory,
    clearShipping,
    clearCart,
  } = props;
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shipping?.postal && !shipping?.country.code) {
      return;
    }

  }, [shipping]);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = (steps=1) => {
    console.log(steps)
    setStep(step>0&&step-steps);
  };

  const handleConfirmCheckout = async () => {
    setLoading(true);
    const checkoutData = [];
  
    items.forEach((item) => {
      checkoutData.push({ id: item.id, quantity: item.toOrder, branded_box: item.add_branded_box });
    });

    try{
      await addInventory(checkoutData, shipping);
      setLoading(false);
      clearCart();
      clearShipping();
      history.push('/success');
    }catch{
      setLoading(false);
    }
    


  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <InventoryCheckoutCart items={items} nextStep={handleNext} />;
      case 1:
        return <InventoryCheckoutShipping handleBack={handleBack} items={items} nextStep={handleNext} exact />;
      case 2:
        return <InventoryCheckoutBilling handleBack={handleBack} items={items} handleConfirmCheckout={handleConfirmCheckout} loading={loading} />;

      default:
        return '';
    }
  };

  return (
    <div className={classes.root}>

      {items.length > 0
        ? getStepContent(step)
        : <h1>Cart Empty</h1>}

    </div>
  );
}

InventoryCheckout.propTypes = {
  items: PropTypes.arrayOf,

};

InventoryCheckout.defaultProps = {
  items: [],

};

const mapStateToProps = (state) => ({
  items: state.cart.cart.items,
  merchant_hold_inventory: state.cart.cart.merchant_hold_inventory,
  shipping: state.cart.shipping.shipping,
});

const mapDispatchToProps = {
  checkout: cartOperations.checkout,
  clearShipping: cartOperations.samplesClearShipping,
  clearCart: cartOperations.clearCart,
  addInventory: productOperations.addInventory,

};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCheckout);
