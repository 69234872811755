export const FETCH_PROFILE = 'profiles/FETCH_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'profiles/FETCH_PROFILE_COMPLETED';
export const FETCH_PROFILE_FAILED = 'profiles/FETCH_PROFILE_FAILED';
export const CLEAR_FETCH_PROFILE_ERROR = 'profiles/CLEAR_FETCH_PROFILE_ERROR';

export const UPDATE_SHOP = 'profiles/UPDATE_SHOP';
export const UPDATE_SHOP_SUCCESS = 'profiles/UPDATE_SHOP_SUCCESS';
export const UPDATE_SHOP_FAILED = 'profiles/UPDATE_SHOP_FAILED';

export const UPDATE_PAYMENT = 'profiles/UPDATE_PAYMENT';
export const UPDATE_PAYMENT_SUCCESS = 'profiles/UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILED = 'profiles/UPDATE_PAYMENT_FAILED';

export const ADD_PAYMENT = 'profiles/ADD_PAYMENT';
export const ADD_PAYMENT_SUCCESS = 'profiles/ADD_PAYMENT_SUCCESS';
export const ADD_PAYMENT_FAILED = 'profiles/ADD_PAYMENT_FAILED';

export const UPDATE_PLAN = 'profiles/UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'profiles/UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAILED = 'profiles/UPDATE_PLAN_FAILED';

export const CREATE_PROFILE = 'profiles/CREATE_PROFILE';
export const CREATE_PROFILE_SUCCESS = 'profiles/CREATE_PROFILE_COMPLETED';
export const CREATE_PROFILE_FAILED = 'profiles/CREATE_PROFILE_FAILED';
export const CLEAR_CREATE_PROFILE_ERROR = 'profiles/CLEAR_CREATE_PROFILE_ERROR';

export const CLEAR_PROFILE = 'profiles/CLEAR_PROFILE';
