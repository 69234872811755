import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';
import Logo from '../../../images/blanka-stacked-white.png';
import spotifyLogo from '../../../images/shopify-bag-icon.png';
import circleArrow from '../../../images/circle_arrow.png';

import { toast } from 'react-toastify';

import { profileOperations } from '../../../state/ducks/profile';

function StandaloneOnboardingStepFour(props) {
  const { profile, updateShop } = props;
  const history = useHistory();

  const [siteName, setSiteName] = useState('');

  const checker = (e) => {
    const string = e.target.value;

    setSiteName(string);

    if (string.includes('myshopify.com')) {
      const newString = string.slice(8);
      const finalString = newString.slice(0, newString.indexOf('.'));
    }
  };

  const isDisabled = () => {
    if (siteName) {
      return true;
    }
    return false;
  };

  const skipConnectStore = async () =>{
    await updateShop(profile.shop[0].id, { onboarding_step: 4 });
    history.push('/?onboarding_tour=standalone');
  }

  const launchApp = async () =>{
    await updateShop(profile.shop[0].id, { onboarding_step:4 });
    history.push('/?onboarding_tour=standalone');
  }


  const onSubmit = () => {
    var filteredName = siteName.replace('https://', '');
    filteredName = filteredName.split('.myshopify.com')[0];

    updateShop(profile.shop[0].id, { url: `${filteredName}.myshopify.com` })
      .then(() => {

      
        if (process.env.REACT_APP_ENVIRONMENT === 'staging' ) {
          window.location.href = `https://staging-admin.blankabrand.com/shopify/login?shop=${filteredName}.myshopify.com`;
        }

        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
          window.location.href = `https://blanka-dev.ngrok.io/shopify/login?shop=${filteredName}.myshopify.com`;
        }
        
        window.location.href = `https://admin.blankabrand.com/shopify/login?shop=${filteredName}.myshopify.com`;

      }).catch((error)=>{

       
        toast.error(error.response.data?.url[0])


      });
  };


  return (
    <div className="template-profile-store root">

        <div className="header-text-container">
          <Typography className="text__title text--center text__title-standalone text--nunito text--bold" color="textPrimary">
            Connect your Shopify account.
          </Typography>
        </div>

        {!profile?.shop[0].store_connected
        ? (<>
        <div className="card__content card__content-standalone">

        
          <div className="container">
            <img alt="Logo" src={Logo} className="img__blanka" />
            <img alt="Circle Arrow" src={circleArrow} className="img__circle-arrow" />
            <img alt="Shopify Logo" src={spotifyLogo} className="img__shopify" />
          </div>
          
              
                <div className="container__input">
                  <TextField  id="domain-name"  label="store name" required value={siteName} onChange={(e) => checker(e)} className="domain-input-name text--nunito" />
                  {' '}
                  <Typography className="text__shopify text--nunito"> .myshopify.com </Typography>
                </div>

                <div className="container">
                  <Button disabled={!isDisabled()} onClick={onSubmit} color="primary" variant="contained" className="button-primary text--nunito">
                    Connect
                    {' '}
                    <i className="fas fa-chevron-right" />
                  </Button>
                </div>

                <Typography className="text--nunito text__redirect-link">
                  Not ready to connect your store? <a onClick={() => skipConnectStore()}>Click Here.</a>
                </Typography>
            
        </div>
        </>
            )
            : <div className="store-connected-wrapper">
              <Typography variant="body2">YOUR SHOPIFY STORE IS CONNECTED</Typography>

              <div className="store-connection-string">
                <img alt="Shopify Logo" src={spotifyLogo} className="shopify-icon" />
                {profile.shop[0].url}  
                
                <CheckCircleIcon></CheckCircleIcon>
               
              </div>

              <Button  color="blue" variant="contained" className="button--blue"  onClick={()=>launchApp()}>LAUNCH THE APP</Button>

            </div>
        }
    </div>
  );
}

StandaloneOnboardingStepFour.propTypes = {
  profile: PropTypes.objectOf(),
  updateShop: PropTypes.func,
  addPayment: PropTypes.func,
};

StandaloneOnboardingStepFour.defaultProps = {
  profile: {},
  updateShop: () => {},
  addPayment: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updateShop: profileOperations.updateShop,
  updatePlan: profileOperations.updatePlan,
  addPayment: profileOperations.addPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneOnboardingStepFour);
