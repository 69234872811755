import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { growthPlanDetials, vipPlanDetails, scalePlanDetails } from './index';
import PlanModalCard from './PlanModalCard';
import { profileOperations } from '../../../state/ducks/profile';

function StandaloneOnboardingStepTwo(props) {
  const {
    isPlan,
  } = props;

  return (
    <div className="plan-wrapper-container">

      <PlanModalCard mt25 isPlan={isPlan} planName="GROWTH PLAN" planDetails={growthPlanDetials()} planNumber={2} planPrice="$29/mo" />

      <PlanModalCard center isPlan={isPlan} centerClassName="center" buttonAlign centerTitleText="text__title__center" planName="SCALE PLAN" planDetails={scalePlanDetails()} planNumber={3} planPrice="$59/mo" />

      <PlanModalCard mt25 isPlan={isPlan} planName="VIP PLAN" planDetails={vipPlanDetails()} planPrice="$99/mo" planNumber={4} />

    </div>
  );
}

StandaloneOnboardingStepTwo.propTypes = {
  isPlan: PropTypes.bool,
};

StandaloneOnboardingStepTwo.defaultProps = {
  isPlan: false,
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updateShop: profileOperations.updateShop,
  updatePlan: profileOperations.updatePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneOnboardingStepTwo);
