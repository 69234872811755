import * as types from './types';
import { apiService } from '../../middlewares';

export function orderBrandPlate(type) {
  return (dispatch) => apiService.post('/shop/order_brand_plate/', { type })
    .then((response) => {
      dispatch({
        type: types.ORDER_BRAND_PLATE_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.ORDER_BRAND_PLATE_FAILED,
        isLoading: false,
        error,
      });
    });
}

export function addToCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_TO_CART,
      payload: {
        product: { ...product, toOrder: amount },
        amount,
      },
    });
  };
}

export function checkCoupon(coupon) {
  return (dispatch) => apiService.post('/payments/check-coupon/', { code: coupon })
    .then((response) => {
      dispatch({
        type: types.CHECKOUT_COUPON_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.CHECKOUT_COUPON_FAILED,
        isLoading: false,
        error,
      });
    });
}

export function getShippingCost(countryCode, postal, weight) {
  return (dispatch) => {
    dispatch({
      type: types.GET_SHIPPING_COST,
    });

    return apiService.post('/inventory-orders/get-shipping-cost/', { country_code: countryCode, postal_code: postal, weight })
      .then((response) => {
        dispatch({
          type: types.GET_SHIPPING_COST_SUCCESS,
          data: response.data,
        });
        return response;
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: types.GET_SHIPPING_COST_FAILED,
          isLoading: false,
          error,
        });
        throw error;
      });
  };
}

export function clearShippingCost() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_SHIPPING_COST,
    });
  };
}

export function removeFromCart(id) {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_FROM_CART,
      id,
    });
  };
}

export function clearCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_CART,
    });
  };
}

export function updateCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CART,
      payload: {
        product,
      },
    });
  };
}

export function updateCartHoldOwnInventory(holdInventory) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CART_HOLD_INVENTORY,
      payload: {
        merchant_hold_inventory: holdInventory,
      },
    });
  };
}

export function checkout(products) {
  return (dispatch) => {
    dispatch({
      type: types.CHECKOUT_CART,
      product: products,
    });
  };
}

export function samplesAddToCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.SAMPLES_ADD_TO_CART,
      payload: {
        product: { ...product, toOrder: amount },
        amount,
      },
    });
  };
}

export function samplesRemoveFromCart(id) {
  return (dispatch) => {
    dispatch({
      type: types.SAMPLES_REMOVE_FROM_CART,
      id,
    });
  };
}

export function samplesUpdateCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.SAMPLES_UPDATE_CART,
      payload: {
        product,
      },
    });
  };
}

export function samplesClearCart(product, amount) {
  return (dispatch) => {
    dispatch({
      type: types.SAMPLES_CLEAR_CART,
    });
  };
}

export function samplesClearCoupon() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_COUPON,
    });
  };
}

export function samplesCheckout(order) {
  return (dispatch) => apiService.post('/sample-orders/new-order/',
    {
      items: order.items,
      shipping: order.shipping,
      discount_id: order.discount_id,
    })
    .then((response) => {
      dispatch({
        type: types.SAMPLE_ORDER_SUCCESS,
        payload: {
          data: response.data,
        },
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.SAMPLE_ORDER_FAILED,
        isLoading: false,
        error,
      });
      return error;
    });
}

export function samplesAddShipping(shipping) {
  return (dispatch) => {
    dispatch({
      type: types.CART_UPDATE_SHIPPING,
      payload: {
        shipping,
      },
    });
  };
}

export function samplesClearShipping(shipping) {
  return (dispatch) => {
    dispatch({
      type: types.CART_CLEAR_SHIPPING,
      payload: {
        shipping,
      },
    });
  };
}
