import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function TruckIcon(props) {
  return (
    <SvgIcon {...props}>
      <path id="_-Primary-Color" data-name="🔹-Primary-Color" className="cls-2" d="M17,8V4H3A2,2,0,0,0,1,6V17H3a3,3,0,0,0,6,0h6a3,3,0,0,0,6,0h2V12L20,8ZM6,18a1,1,0,1,1,1-1A1,1,0,0,1,6,18Zm9-3H8.22a3,3,0,0,0-4.44,0H3V6H15Zm3,3a1,1,0,1,1,1-1A1,1,0,0,1,18,18Zm-1-6V9.5h2.5l2,2.5Z" />
    </SvgIcon>
  );
}

export default TruckIcon;
