import { combineReducers } from 'redux';

import * as types from './types';

// import { errorMessage } from '../notifications/actions';

const token = JSON.parse(localStorage.getItem('token'));

const initialState = {
  isLoggedIn: !!token,
  loading: !token,
  error: false,
  token: {
    access_token: token ? token.access_token : '',
    refresh_token: token ? token.refresh_token : '',
  },
};

function session(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN:
      return { ...state, loading: true, error: false };

    case types.SET_TOKEN:

      localStorage.setItem('token', JSON.stringify(action.data));
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        token: {
          access_token: action.data.access_token,
          refresh_token: action.data.refresh_token,
        },
      };
    case types.LOGIN_SUCCESS:

      localStorage.setItem('token', JSON.stringify(action.data));
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        token: {
          access_token: action.data.access_token,
          refresh_token: action.data.refresh_token,
        },
      };
    case types.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.CLEAR_LOGIN_ERROR:
      return {
        ...state,
        error: false,
      };
    case types.CLEAR_TOKEN:
      localStorage.removeItem('token');
      return {
        ...state,
        loading: false,
        token: {
          access_token: '',
          refresh_token: '',
        },
      };
    case types.LOGOUT:

      localStorage.removeItem('token');
      localStorage.removeItem('items');
      localStorage.removeItem('samplesitems');
      // window.location.href = "https://blankabrand.com"

      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        token: {
          access_token: '',
          refresh_token: '',
        },
      };

    case types.PASSWORD_RESET:
      return { ...state, resetPasswordLoading: true, error: false };
    case types.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        error: false,
      };
    case types.PASSWORD_RESET_FAILED:
      return {
        ...state,
        resetPasswordLoading: false,
        isResetLinkSent: false,
        error: true,
      };

    case types.PASSWORD_CHANGE:
      return { ...state, changePasswordLoading: true, error: false };
    case types.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        changePasswordLoading: false,
        error: false,
      };
    case types.PASSWORD_CHANGE_FAILED:
      return {
        ...state,
        changePasswordLoading: false,
        error: true,
      };

    case types.REFRESH_TOKEN_GET:
      return { ...state, tokenLoading: true, error: false };
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: {
          // ...token,
          refresh_token: action.data.refresh_token,
          access_token: action.data.access_token,
        },
        error: false,
      };
    case types.REFRESH_TOKEN_ERROR:
      return {
        ...state,
        changePasswordLoading: false,
        error: true,
      };

    case types.UPDATE_USER:
      return { ...state, updateUserLoading: true, error: false };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        error: false,
      };
    case types.UPDATE_USER_FAILED:
      return {
        ...state,
        updateUserLoading: false,
        error: true,
      };

    default:
      return state;
  }
}

export default combineReducers({
  session,
});
