import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PlanWrapper from '../../../components/modals/plans/PlanWrapper';

import { profileOperations } from '../../../state/ducks/profile';

function ShopifyOnboardingStepThree(props) {
  const {
    profile, updateShop, nextStep,
  } = props;

  const handleFree = (id) => {
    updateShop(profile.shop[0].id, { onboarding_step: 4, plan: id })
      .then(() => {
        nextStep();

        window.location.href = '/?charge_approved=true';
      });
  };

  return (
    <div>

      <div className="pick-plan-container">
        <Typography className="text--bold text--nunito text__try-free-plan">Try any plan free for 14 days!</Typography>

        <Typography className="text--bold text__pick-plan text--georgia" variant="h4" color="secondary">Pick Your Plan</Typography>
      </div>

      <PlanWrapper />

      <div className="try-free-container display--flex flex-row-center pt-20">
        <Typography className="text--fs-13 pr-15 text--blue text--georgia text--bold text--upper">
          Free Plan
        </Typography>

        <li style={{ color: '#246071' }} className="pl-15"><span className="text--fs-12 text--blue text--nunito">Create 10 unbranded products</span></li>
      </div>

      <Link style={{ cursor: 'pointer', textTransform: 'uppercase', textDecoration: 'underline' }} onClick={() => handleFree(1)} className="text--fs-12 text--bold text--nunito text--blue text--upper">
        Get Started
        {' '}
        <i className="fas fa-chevron-right" />
      </Link>

    </div>
  );
}

ShopifyOnboardingStepThree.propTypes = {
  profile: PropTypes.objectOf(),
  updateShop: PropTypes.func,
  nextStep: PropTypes.func,
};

ShopifyOnboardingStepThree.defaultProps = {
  profile: {},
  updateShop: () => {},
  nextStep: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updateShop: profileOperations.updateShop,
  updatePlan: profileOperations.updatePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopifyOnboardingStepThree);
