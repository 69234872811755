import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/*

    CardComponent.propTypes = {
        message: string,
        variant: 'info' | 'error',
        onClose?: () => void,
    }

 */

const useStyles = makeStyles((theme) => ({
    close: {
        position: "absolute",
        top: 16,
        right: 16
    },
    error: {
        position: "relative",
        marginBottom: 16,
        backgroundColor: "rgb(220, 0, 78)"
    },
    info: {
        position: "relative",
        marginBottom: 16,
        backgroundColor: "#2979ff"
    },
    children: {
        color: "#fff",
        fontWeight: "500",
    }
}));

export default function CardComponent(props) {
    const classes = useStyles();

    return (
        <Card variant="outlined" className={props.variant === 'info' ? classes.info : classes.error}>
            <CardContent className={classes.children}>
                {props.message}
            </CardContent>
        </Card>
    )
}
