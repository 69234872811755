import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { toast } from 'react-toastify';
import { profileOperations } from '../../../state/ducks/profile';
import { notifications } from '../../notifications';
import { SHOP_ONBOARDING_SHOPIFY, SHOP_ONBOARDING_STANDALONE } from '../../../constants';

function PlanModalCard(props) {
  const {
    profile,
    updateShop,
    updatePlan,
    planName,
    planDetails,
    planPrice,
    planNumber,
    centerClassName,
    centerTitleText,
    isPlan,
    handleClose,
    getProfile,
    isModal,
    centerPadding,
    mt25,
    buttonAlign,
    center,
  } = props;

  const handleStandalone = (id) => {
    if (id > 1) {
      // if plan a paid plan go to payment step
      updateShop(profile.shop[0].id, {
        onboarding_step: 2,
        plan: id,
        subscription_status: 'pending',
      });
    } else {
      // skip directly to connection step (4) index 3
      updateShop(profile.shop[0].id, {
        onboarding_step: 3,
        plan: id,
        subscription_status: 'active',
      });
    }
  };

  const handleShopify = async (id) => {
    if (id > 1) {
      // if plan a paid plan go to payment step
      updateShop(profile.shop[0].id, { onboarding_step: 2, plan: id }).then(
        () => {
          updatePlan(id).then((response) => {
            if (response && response.status === 200) {
              window.location.href = response.data.confirmation_url;
            }
          });
        },
      );
    } else {
      // set to free plan and finish onboarding
      await updateShop(profile.shop[0].id, { onboarding_step: 3 });
      await updatePlan(id);
    }
  };

  const handlePaidModal = (id) => {
    if (
      !profile.shop[0].billing_address
      && profile.shop[0].shop_signup_type !== SHOP_ONBOARDING_SHOPIFY
    ) {
      notifications.noBillingMethodError();
      return;
    }

    updatePlan(id).then((response) => {
      if (
        response
        && response.status === 200
        && profile.shop[0]
        && profile.shop[0].shop_signup_type === SHOP_ONBOARDING_SHOPIFY
      ) {
        window.location.href = response.data.confirmation_url;
      } else if (response && response.status === 200 && profile.shop[0]) {
        getProfile().then(() => {
          toast.success('Success! Your account has been updated');
          handleClose();
        });
      }
    });
  };

  const getButtonText = (hasTrial) => {
    if (isPlan) {
      return 'Current';
    }
   
    if (hasTrial) {
      return 'Try For Free';
    }
    return 'Change Plan';
  };

  const paidCheck = (id) => {
    if (profile.shop[0].onboarding_step >= 3) {
      handlePaidModal(id);
      return;
    }

    if (profile.shop[0].shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
      handleShopify(id);
    } else if (
      profile.shop[0].shop_signup_type === SHOP_ONBOARDING_STANDALONE
    ) {
      handleStandalone(id);
    }
  };

  return (
    <Card
      className={`${
        mt25 ? 'mt-25 mb-25' : ''
      } payment_price_item plan-modal-card payment_price_item--${centerClassName}  ${
        isPlan ? 'payment_price_item--selected' : ''
      }`}
    >
      {planName === 'SCALE PLAN' ? (
        <div className="text--bold text--upper text--nunito ribbon ribbon-top-right">
          <span>Most Popular</span>
        </div>
      ) : (
        ''
      )}

      <CardContent>
        <Typography
          variant="h4"
          className={`text--bold text__title text--georgia ${centerTitleText} 
          ${isPlan ? 'text--white' : ''}
          ${center ? 'pt-25' : ''}
          `}
          color="textSecondary"
          gutterBottom
        >
          {planPrice}
        </Typography>
        <Typography
          variant="h5"
          className={`text--bold text__title text--georgia ${
            isPlan ? 'text--white' : ''
          }`}
          color="textSecondary"
          gutterBottom
        >
          {planName}
        </Typography>
        <div className={`${isPlan ? 'text--white' : ''}`}>{planDetails}</div>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => paidCheck(planNumber)}
          color="primary"
          variant="contained"
          disabled={isPlan}
          className={`button__plans button--primary ${
            isModal ? 'button__modal' : ''
          } ${buttonAlign ? 'button__modal-center-plan' : ''}`}
        >
          {getButtonText(profile?.shop[0].has_trial)}
          {' '}
          <i className="fas fa-chevron-right" />
        </Button>
      </CardActions>
    </Card>
  );
}

PlanModalCard.propTypes = {
  profile: PropTypes.objectOf(),
  updateShop: PropTypes.func,
  updatePlan: PropTypes.func,
  planName: PropTypes.string,
  planDetails: PropTypes.objectOf(),
  planPrice: PropTypes.string,
  planNumber: PropTypes.number,
  centerClassName: PropTypes.string,
  centerTitleText: PropTypes.string,
  isPlan: PropTypes.bool,
  handleClose: PropTypes.func,
  getProfile: PropTypes.func,
};

PlanModalCard.defaultProps = {
  profile: {},
  updateShop: () => {},
  updatePlan: () => {},
  planName: '',
  planDetails: {},
  planPrice: '',
  planNumber: 0,
  centerClassName: '',
  centerTitleText: '',
  isPlan: false,
  handleClose: () => {},
  getProfile: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updateShop: profileOperations.updateShop,
  updatePlan: profileOperations.updatePlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanModalCard);
