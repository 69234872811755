import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';

import LoadingButton from '../../components/buttons/loadingButton';
import { orderOperations } from '../../state/ducks/orders';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ConfirmOrderModal from '../../components/modals/ConfirmOrderModal';
import {TruckIcon} from '../../components/icons';
import {NON_BRANDED_BOX_ITEMS,PRODUCT_TYPE_UNBRANDED } from '../../constants';
import CheckIcon from '@material-ui/icons/Check';


const UNFULFILLED = 'UNFULFILLED';
const FULFILLED = 'FULFILLED';
const ORDERED = 'ORDERED';
const SHIPPED = 'SHIPPED';
const CANCELLED = 'CANCELLED';
const PAYMENT_ERROR = 'PAYMENT_ERROR';
const PAYMENT_REQUIRED = 'PAYMENT_REQUIRED';
const FULLFILLED_BY_MERCHANT = 'FULLFILLED_BY_MERCHANT';

function OrderItem(props) {
  const { order, onPlaceOrder, isReadOnly } = props;
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const dispatch = useDispatch();
  const [brandedBoxItems, setBrandedBoxItems] = useState([]);
  const [selectAllCheck, setSelectAllCheck] = useState(true);
  const [fulfillInventoryState, setFulfillInventoryState] = useState(false);
  const boxFee = 0.40;

  const getStatus = (status) => {
    switch (status) {
      case UNFULFILLED:
        return 'unfulfilled';
      case ORDERED:
        return 'unfulfilled';
      case SHIPPED:
        return 'fulfilled';
      case FULLFILLED_BY_MERCHANT:
        return 'fulfilled';
      case CANCELLED:
        return 'Cancelled';
      case PAYMENT_ERROR:
        return 'error';
      case PAYMENT_REQUIRED:
        return 'Payment Required';
      default:
        return '';
    }
  };


  useEffect(() => {
    console.log(order);
    setBrandedBoxItems(order.order_items);
    setFulfillInventoryState(order.use_own_inventory);


    selectAllInit();
  }, [order]);


  const calcLineItemTotal = (price, quantity) => (price * quantity).toFixed(2);


  const getCustomBoxData = (boxes) =>{
    let boxData = [];
    boxes.map((item)=>{
      if(item.branded_box){
        boxData.push({id:item.id})
      }
    })
    return boxData
  }

  const orderHasError = () => {
    if(order.has_error) {
      return true;
    } 
    return false;
  }

  const handleFulfillInventory = (event) => {
    setFulfillInventoryState(event.target.checked);

    //clear any selected branded boxes
    let selectAllItemList = [];
    order.order_items.map((item, index) => {
      if(isBrandedBoxAvailable(item.branded_box_available)){
        selectAllItemList.push({ ...item, branded_box: false });
      }
    })
    setBrandedBoxItems(selectAllItemList);

  }

  const handlePlaceOrder = async (id) => {
    setConfirmation(false);
    setLoading(true);
    try{
      await dispatch(orderOperations.payCustomerOrder(id,getCustomBoxData(brandedBoxItems), fulfillInventoryState));
      toast.success('Order has been successfully processed');
      setLoading(false);
    }catch(err){
      setLoading(false);
    }
    onPlaceOrder();

  };

  
  const handleCheckedChange = (event, id) => {
    const newItemsList = []; // copy existing items list
    setSelectAllCheck(false);
    brandedBoxItems.map((item, index) => { // loop over items
      if (item.id === id) { // if we find the item that needs updating
        newItemsList.push({ ...item, branded_box: event.target.checked }); // push it to the new list with update brand check property
      }else{
        newItemsList.push({...item});
      }
    });
    setBrandedBoxItems([...newItemsList]); // use spread operator to copy in the new list
  };

  const selectAll = (event) => {
    let selectAllItemList = [];
      order.order_items.map((item, index) => {
        if(isBrandedBoxAvailable(item.branded_box_availables, item.product_type)){
          selectAllItemList.push({ ...item, branded_box: event.target.checked });
        }
      })
      
    setBrandedBoxItems(selectAllItemList);
  }


  const selectAllInit = () => {
    let selectAllItemList = [];
      order.order_items.map((item, index) => {
        if(isBrandedBoxAvailable(item.branded_box_available, item.product_type)){
          selectAllItemList.push({ ...item, branded_box: true });
        }
      })
      
    setBrandedBoxItems(selectAllItemList);
  }


  const isChecked = (itemId) =>{
    let checked = false;
    const items = brandedBoxItems.map((item)=>{
      if (item.id === itemId && item.branded_box ){
        checked = true;
      }
    });
    return checked;
  }

 

  const getBoxFees = () => {
    let total = 0;
    brandedBoxItems.map((item, index) => {
      
      if (item.branded_box) {
        total += (item.quantity * boxFee);
      }
    })
    return total;
  }

  const getSubtotal = () =>{
    var subtotal = fulfillInventoryState ? 0 : order.order_sub_total;
    return Number(subtotal).toFixed(2);
  }

  const getShipping = () =>{
    return Number(order.order_shipping_total).toFixed(2);
  }

  const getFees = () => {
    let subtotal = Number(getSubtotal()) + Number(getShipping()) + Number(getBoxFees());
    let fees = (subtotal * 0.029) + .3;
    return Number(fees).toFixed(2);
  }

  
  /*
  Check list of skus that are not allow to have a branded box
  Checks to see if the root sku is present
  */
  const isBrandedBoxAvailable = (brandedBoxAvailable, product_type) => {

    
    if (!brandedBoxAvailable || product_type == PRODUCT_TYPE_UNBRANDED){
      return false;
    }  

    return true;
  }

 
  const hasBranded = (hasBranded) =>{
    return hasBranded ? <CheckIcon className="icon--check"/> : <></>;
  }

  const trackingStatus = () => {
    if(order.status == PAYMENT_REQUIRED) {
      return <Typography className="text--nunito text__tracking-header">PENDING</Typography>
    } else if (order.status === UNFULFILLED) {
      return <Typography className="text--nunito text__tracking-header">ORDER BEING PROCESSSED</Typography>
    }

    return <a target="_blank" href={`https://parcelsapp.com/en/tracking/${order.tracking_code}`} className="text__tracing-number" rel="noreferrer">
      {order.tracking_code}
    </a>
  }


  return (
    <Card className="order-card root">
      <CardContent container>
        <div className="order-card__header">
          <Typography className="text--header text--georgia text--bold">
            Order #
            {order.shopify_order_number}
          </Typography>
          <Typography className="text--header text--georgia text--bold">
            Date:
            {' '}
            {moment(order.created_at).format('DD-MM-yyy')}
          </Typography>

          <div className="order-card__status">
            <Typography variant="h5" className="text--header text--georgia text--bold">Status </Typography>
            <Chip label={getStatus(order.status)} className={`status-${getStatus(order.status).replace(' ', '-')}`} />
          </div>

        </div>

        <div className="order-card__details" xs={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell className="text--bold" align="left">Color</TableCell>
                  <TableCell className="text--bold" align="left">SKU</TableCell>
                  <TableCell className="text--bold" align="left">Product</TableCell>
                  
                  <TableCell className="text--bold " align="left">
                    Branded Boxes
                    {order.status == PAYMENT_REQUIRED &&
                    <div>
                      <Checkbox
                        disabled={fulfillInventoryState}
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={selectAllCheck}
                        onChange={(event) => {selectAll(event);setSelectAllCheck(event.target.checked)}}
                      />
                      Select All
                    </div>
                    }
                  </TableCell>
                  <TableCell className="text--bold" align="center">Unit Cost</TableCell>
                  <TableCell className="text--bold" align="center">Quantity</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {order?.order_items.map((item, index) => (
                  <TableRow>
                    <TableCell><div className="color-swatch" style={{ background: `${item.color_code}` }} /></TableCell>
                    <TableCell className="text--cell-details text--nunito text--upper">{item.sku}</TableCell>
                    <TableCell className="text--cell-details text--nunito text--upper">{item.title}</TableCell>
                    
                    <TableCell className="text--cell-details text--bold text--brandedbox" align="left">
                      
                        {(order.status == PAYMENT_REQUIRED && isBrandedBoxAvailable(item.branded_box_available,item.product_type))
                            ?<FormGroup>
                              <FormControlLabel control={<Checkbox
                                  disabled={isReadOnly || !isBrandedBoxAvailable(item.branded_box_available,item.product_type)||fulfillInventoryState}
                                  checked={isChecked(item.id)}
                                  onChange={(event) => handleCheckedChange(event, item.id)}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                              />} label="Add Branded Boxes $0.40/Ea" />
                            </FormGroup>
                            : <div className={`${!isBrandedBoxAvailable(item.branded_box_available, item.product_type) ? 'hidden':''} `}>  
                                <Typography className="text--label" className={`${!item.branded_box ? 'hidden':''} text--label`}>
                                  <CheckIcon className="icon--check"/> Add Branded Boxes $0.40/Ea
                                </Typography>
                              </div> 
                        }
                      
                      
                    </TableCell>
                    <TableCell className="text--cell-details" align="center">
                      ${item.cost}
                    </TableCell>

                    <TableCell className="text--cell-details text--bold" align="center">{item.quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </CardContent>

        <div className="tracking-number-container">
          <div className="flex--flex">
            <TruckIcon className="icon--truck"></TruckIcon>
            <Typography className="text__tracking-header text--upper text--nunito">Tracking Number:</Typography>  
            {trackingStatus()}
          </div>

          <div className="fulfill-inventory-container pb-10">
            {order.status == PAYMENT_REQUIRED 
              ?<FormGroup className="fulfill-form">
                <FormControlLabel 
                  control={
                    <Checkbox
                        disabled={isReadOnly}
                        checked={fulfillInventoryState}
                        onChange={(event) => handleFulfillInventory(event)} 
                        size="small" 
                        inputProps={{ 'aria-label': 'controlled' }}
                      
                      />
                  } 
                  label="FULFILL USING MY INVENTORY AT BLANKA" />
              </FormGroup>
              : <Typography className={`${!order.use_own_inventory ? 'hidden' : ''} text--label`}>{hasBranded(order.use_own_inventory)} FULFILL USING MY INVENTORY AT BLANKA</Typography>
            }
          </div>
        </div>

      <div className="totals-container">
        <div className="totals-container__values-container">
          <Typography className="text__totals text--nunito ">Subtotal</Typography>
          <Typography className="text__totals text--bold text--nunito">
            $
            {(Number(getSubtotal()) + Number(getBoxFees())).toFixed(2)}
          </Typography>
        </div>
        <div className="totals-container__values-container">
          <Typography className="text__totals text--nunito ">Shipping</Typography>
          <Typography className="text__totals text--bold text--nunito">
            $
            {getShipping()}
          </Typography>
        </div>
        <div className="pb-10 totals-container__values-container">
          <Typography className="text__totals text--nunito ">Processing Fee</Typography>
          <Typography className="text__totals text--bold text--nunito">
            $
            {Number(getFees()).toFixed(2)}
          </Typography>
        </div>
        <div className="totals-container__values-container totals-container--border-top">
       
          <Typography className="text__totals text--nunito text--left">Total</Typography>
          <Typography className="text__totals text--bold text--nunito">
            $
            {(Number(getSubtotal()) + Number(getShipping()) + Number(getFees()) + Number(getBoxFees())).toFixed(2)}
          </Typography>
       
        </div>
      </div>

      <CardActions disableSpacing>
          {order.status == PAYMENT_REQUIRED &&
            <LoadingButton loading={loading} disabled={orderHasError()} onClick={(e) => setConfirmation(true)} variant="contained" className="button--primary btn-submit-order" color="primary">
              Place Order
              {' '}
              <i className="fas fa-chevron-right" />
            </LoadingButton>
          }

          {orderHasError() && 
            <Typography 
              className="text--nunito text--error text__order-error"
            >
              Oops! There is an issue with your order.
              Please contact us to resolve this.
            </Typography>
          }
      </CardActions>

      <ConfirmOrderModal open={confirmation} handleClose={() => setConfirmation(false)} handleConfirm={() => handlePlaceOrder(order.id)} orderTotal={(Number(getSubtotal()) + Number(getShipping()) + Number(getFees())+Number(getBoxFees())).toFixed(2)} />
    </Card>
  );
}

export default OrderItem;
