import React from 'react';
import FindProducts from '../views/findProducts/index.jsx';
import MyProducts from '../views/myProducts';
import MyOrders from '../views/orders';
import Dashboard from '../views/dashboard/Dashboard';
import Login from '../views/login/Login';
import PasswordReset from '../views/passwordReset/PasswordReset';
import ForgotPassword from '../views/forgotPassword';
import ProfileMain from '../views/profile/profileMain';

// import ProfileUploadLogo from "../views/profile/UploadLogoModal";
import CheckoutMain from '../views/checkout/CheckoutMain';
import CheckoutSuccess from '../views/checkout/CheckoutSuccess';
import SampleCheckoutSuccess from '../views/checkout/SampleCheckoutSuccess';
import InventoryOrders from '../views/orders/inventoryOrders';

import BrandPlate from '../views/brandplate/brandPlate';
import ShopifyOnboarding from '../views/onboarding/shopify';

import {
  FindProductsIcon, MyBrandPlateIcon, MyAccountIcon, MyOrdersIcon, MyProductsIcon,
} from '../components/icons';

const routes = [
  /* {
        name: "Dashboard",
        path: "/",
        exact: true,
        component: Dashboard,
        menu: true,
        icon: 'dashboard',
    }, */
  {
    name: 'Find Products',
    path: '/',
    exact: true,
    component: FindProducts,
    menu: true,
    icon: <FindProductsIcon />,
  },
  {
    name: 'My Brand Plate',
    path: '/brand-plate',
    exact: true,
    component: BrandPlate,
    menu: true,
    icon: <MyBrandPlateIcon />,
  },
  {
    name: 'My Products',
    path: '/my-products',
    exact: true,
    component: MyProducts,
    menu: true,
    icon: <MyProductsIcon />,
  },
  {
    name: 'Orders',
    path: '/orders',
    exact: true,
    component: MyOrders,
    menu: true,
    icon: <MyOrdersIcon />,
    isParent: true,
  },
  {
    name: 'Inventory Orders',
    path: '/inventory-orders',
    exact: true,
    component: InventoryOrders,
    menu: false,
    icon: 'list',

  },
  {
    name: 'My Account',
    path: '/profile',
    component: ProfileMain,
    menu: true,
    exact: false,
    icon: <MyAccountIcon />,
  },
  /*
    {
        name: "Logo Upload",
        path: "/profile/logo-upload",
        component: ProfileUploadLogo,
        menu: false,
        exact: true,
        icon: 'account_box',
    }, */
  {
    name: 'Checkout',
    path: '/checkout',
    component: CheckoutMain,
    menu: false,
    exact: false,
    icon: 'account_box',
  },
  {
    name: 'Checkout Success',
    path: '/success',
    component: CheckoutSuccess,
    menu: false,
    exact: false,
  },
  {
    name: 'Checkout Success',
    path: '/thank-you',
    component: SampleCheckoutSuccess,
    menu: false,
    exact: true,
  },

];

export const authRoutes = [
  {
    name: 'Login',
    path: '/login',
    menu: false,
    component: Login,
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    name: 'Password Reset',
    path: '/password-reset',
    component: PasswordReset,
  },
];

export default routes;
