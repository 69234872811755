import PropTypes, { number } from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import OrderItem from './orderItem';

function OrdersList(props) {
  const {
    orders, isReadOnly, handlePlaceOrder, page, handleChange, count,
  } = props;

  return (
    <div className="orders__list">

      {orders.length > 0
        ? (
          <div style={{ width: '100%' }}>
            {orders.map((order) => <OrderItem isReadOnly={isReadOnly()} onPlaceOrder={handlePlaceOrder} order={order} />)}

            <div className={`mt-20 pt-20 pagination-container ${count <= 1 ? 'display--none' : ''}`}>
              <Pagination size="small" count={count} page={page} onChange={handleChange} />
            </div>
          </div>
        )
        : (
          <div style={{ width: '100%', paddingTop: '20px', paddingLeft: '10px' }}>
            <Typography className="ta-left text--georgia order__list__no-orders-text" variant="h5" color="secondary">You have no orders of this type.</Typography>
          </div>
        )}

    </div>
  );
}

OrdersList.propTypes = {
  count: number,
  orders: PropTypes.objectOf,
  isReadOnly: PropTypes.func,
  handlePlaceOrder: PropTypes.func,
  page: PropTypes.number,
  handleChange: PropTypes.func,
};

OrdersList.defaultProps = {
  count: 0,
  orders: {},
  isReadOnly: () => {},
  handlePlaceOrder: () => {},
  page: 0,
  handleChange: () => {},
};

export default OrdersList;
