import React, { useEffect } from "react";
import { connect } from "react-redux";
import {  makeStyles } from '@material-ui/core/styles';
import { cartOperations } from "../../state/ducks/cart";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InventoryTotalsArea from './InventoryTotalsArea'
import CreditCardForm from '../../components/creditCardForm';
import LoadingButton from '../../components/buttons/loadingButton';

import Card from '@material-ui/core/Card';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        justifyContent: 'left'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    header:{
        borderBottom: "1px solid " + theme.palette.gray,
        marginBottom: "50px",
    },
  
    button:{
        marginTop: '25px'
    },
    text:{
        textAlign: 'left',
        color: theme.palette.gray,
    },
    card:{
        padding: '40px',
        display: 'flex',
        flexDirection:'column',
        alignItems:'flex-start',


    },
    paymentInfo:{
        marginTop: '25px'
    }

}));

function InventoryCheckoutBilling(props) {
    const classes = useStyles();
    const { error, clearError, loading,  addShipping,nextStep,clearCart,shipping, items, profile,handleConfirmCheckout,  merchant_hold_inventory, handleBack } = props;
    const history = useHistory();   
    //const [loading, setLoading] = React.useState(false);



    return (
        <div className={classes.root, 'samples-checkout template-inventory-checkout__shipping'}>

            <Card className={classes.card} variant="outlined">

                <div className="shipping-card-left">
                <Typography variant="h5" color="secondary">Payment Information</Typography>   
                <Typography variant="body" color="secondary">All transactions are secure and encrypted</Typography>

          
                        <div className={classes.paymentInfo}>
                    
                        
                            <Typography variant="h6" color="secondary">Credit Card Number</Typography>

                            {profile?.shop[0]?.billing_address &&
                                <div className="cc-display">
                                    XXXX XXXX XXXX {profile?.shop[0]?.billing_address.credit_card_digits}
                                </div>
                            }

                            {!profile?.shop[0]?.billing_address &&
                                <CreditCardForm ></CreditCardForm>
                            }

                            <Typography style={{marginTop:'5px'}} variant="h6" color="secondary">To Update your credit card visit <Link to="/profile/billing">My Account</Link></Typography>
                        </div>

                </div>
                <div className="shipping-card-right">
                
                    <InventoryTotalsArea shipping={shipping} items={items} ></InventoryTotalsArea>
                    
                </div>

            </Card>

            <div className="checkout__actions">

                <div className="checkout__breadcrumbs">
                        <p className="back"  onClick={(e)=>handleBack(2)}>Cart </p>
                        <ChevronRightIcon></ChevronRightIcon>
                   
                        <p className="back" onClick={(e)=>handleBack()} on >Shipping </p>
                        <ChevronRightIcon></ChevronRightIcon>
                   
                
                        <p className="text--bold">Payment</p>
                    </div>

                <div className="checkout-button-container">
                    <LoadingButton loading={loading} disabled={!profile?.shop[0]?.billing_address||loading} onClick={handleConfirmCheckout} variant="contained" color="primary" className=" button--primary"  >Complete Purchase</LoadingButton>
                </div>

            </div>

           
        </div>
    );
}

InventoryCheckoutBilling.defaultProps = {
    shipping: {},
    isLoading: false,
    hasErrored: false,
    profile: {},
};

const mapStateToProps = (state) => ({
    shipping: state.cart.shipping.shipping,
    profile: state.profile.getProfile.profile,
    merchant_hold_inventory: state.cart.cart.merchant_hold_inventory,
});

const mapDispatchToProps = {
    addShipping : cartOperations.samplesAddShipping,
    clearCart: cartOperations.samplesClearCart,
    checkout: cartOperations.samplesCheckout,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCheckoutBilling);
