import * as types from './types';

import { CLEAR_PROFILE } from '../profile/types';

import { apiService } from '../../middlewares';

export function login(data) {
  return (dispatch) => apiService.post('/token/', data)
    .then((response) => {
      dispatch({
        type: types.LOGIN_SUCCESS,
        data: {
          access_token: response.data.access,
          refresh_token: response.data.refresh,
        },
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.LOGIN_FAILED,
        isLoading: false,
        error,
      });
    });
}

export function getNewRefreshToken(refreshToken) {
  return (dispatch) => {
    dispatch({ type: types.REFRESH_TOKEN_GET });
    return apiService.post('/token/refresh/', { refresh: refreshToken })
      .then((response) => {
        dispatch({
          type: types.REFRESH_TOKEN_SUCCESS,
          data: {
            access_token: response.data.access,
            refresh_token: response.data.refresh,
          },
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: types.REFRESH_TOKEN_ERROR,
          isLoading: false,
          error,
        });
        throw error;
      });
  };
}

export function verifyToken(data) {
  return (dispatch) => apiService.post('/token/verify/', { token: data })
    .then((response) => {
      dispatch({
        type: types.SET_TOKEN,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.LOGOUT,
        isLoading: false,
        error,
      });
      throw error;
    });
}

export function setToken(accessToken, refreshToken) {
  return (dispatch) => {
    dispatch({
      type: types.SET_TOKEN,
      data: {
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    });
  };
}

export function clearError() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_LOGIN_ERROR,
    });
  };
}

export function validateResetToken(data) {
  return (dispatch) => apiService.post('/auth/password-reset/verify/', data)
    .then((response) => {
      dispatch({
        type: types.PASSWORD_RESET_TOKEN_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.PASSWORD_RESET_TOKEN_FAILED,
        isLoading: false,
        error,
      });
    });
}

export function sendResetLink(data) {
  return (dispatch) => apiService.post('/auth/password-reset/', data)
    .then((response) => {
      if (response.data.status !== 'OK') {
        throw response;
      }

      dispatch({
        type: types.PASSWORD_RESET_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.PASSWORD_RESET_FAILED,
        isLoading: false,
        error,
      });
    });
}

export function sendNewPassword(data) {
  return (dispatch) => apiService.post('/auth/password-reset/confirm/', data)
    .then((response) => {
      dispatch({
        type: types.PASSWORD_CHANGE_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.PASSWORD_CHANGE_FAILED,
        isLoading: false,
        error,
      });
    });
}

export function changePassword(data) {
  return (dispatch) => apiService.put('/auth/password/change/', data)
    .then((response) => {
      dispatch({
        type: types.PASSWORD_CHANGE_SUCCESS,
        data: response.data,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.PASSWORD_CHANGE_FAILED,
        isLoading: false,
        error,
      });
      throw error;
    });
}

export function updateUser(data) {
  return (dispatch) => apiService.put('/users/', data)
    .then((response) => {
      dispatch({
        type: types.UPDATE_USER_SUCCESS,
        data: response,
      });
      return response;
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_USER_FAILED,
        isLoading: false,
        error,
      });
    });
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: types.LOGOUT,
    });
    dispatch({
      type: CLEAR_PROFILE,
    });
  };
}

export function clearToken() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_TOKEN,
    });
  };
}
