import React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { sessionOperations } from "../../state/ducks/session";
import CardComponent from "../../components/card";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: '#eee',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        background: '#fff',
        width: '40%',
        height: 'fill-content',
        padding: '40px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',

    },
}));

function PasswordReset(props) {
    const classes = useStyles();
    const { sendResetLink, history } = props;
    const [isSent, setIsSent] = React.useState(false);

    React.useEffect(() => {
        if(props.session.error) {
            props.clearError()
        }
    }, [])

    const [email, setEmail] = React.useState('admin@example.com');
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const setPasswordReset = () => {
        setIsSent(false);
        sendResetLink({ "email": email }).then(response => {
            if (response && response.status === 200) {
                setIsSent(true);
            }
        });
    }

    const goToLogin = () => {
        history.push('/login');
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setPasswordReset();
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} variant="outlined" >
            
                {props.session.error
                    ? (
                        <CardComponent message="Account not found" />
                    )
                    : null
                }
                {isSent
                    ? (
                        <CardComponent
                            message="Password reset instructions sent, please check your email"
                            variant="info"
                        />
                    )
                    : null
                }
                <form className={classes.form} noValidate autoComplete="off" onSubmit={onSubmit}>
                    <TextField id="email" label="Email" value={email} onChange={handleEmailChange} />
                    <Button style={{marginTop:10}} variant="contained" color="primary" onClick={setPasswordReset}>Send Reset Link</Button>
                    <Button style={{marginTop:10}} variant="contained" color="primary" onClick={goToLogin}>Return to Login</Button>
                </form>
            </Paper >
        </div>
    );
}

const mapStateToProps = ( state ) => ( {
    session: state.session.session,
} );

const mapDispatchToProps = {
    sendResetLink: sessionOperations.sendResetLink,
    clearError: sessionOperations.clearError,
};


export default connect( mapStateToProps, mapDispatchToProps )(PasswordReset);
