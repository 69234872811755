import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  NavLink,
  useHistory,
  Redirect,
} from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import queryString from 'query-string';

import { sessionOperations } from '../../state/ducks/session';

import LoadingButton from '../../components/buttons/loadingButton';
import Logo from '../../images/logo.png';

function PasswordChange(props) {
  const history = useHistory();
  const {
    sendNewPassword, session,
  } = props;
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState();
  const [confirmPassword, setConfirmPassword] = useState('');
  const token = useRef('');
  const email = useRef('');

  useEffect(() => {
    const { token: tokenString, email: emailString } = queryString.parse(props.location.search);
    token.current = tokenString;
    email.current = emailString;
  }, []);

  const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);

    if (!e.target.value) {
      setMessage('Password strenth too low');
      return;
    }

    if (e.target.value.length < 8) {
      setMessage('Password must be atleast 8 characters');
      return;
    }

    if (e.target.value !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    setMessage('');
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);

    if (e.target.value !== password) {
      setMessage('Passwords do not match');
      return;
    }

    if (e.target.value.length < 8) {
      setMessage('Password must be atleast 8 characters');
      return;
    }

    setMessage('');
  };

  const isFormValid = () => {
    if (!password || password.length < 8 || password !== confirmPassword) {
      return false;
    }
    return true;
  };

  const setPasswordReset = () => {
    sendNewPassword({
      password,
      token: token.current,
      email: email.current,
    })
      .then((response) => {
        if (response.status === 200) {
          history.push('/login?password_reset=true');
        }
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setPasswordReset();
  };

  if (session.isLoaggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="password-reset-template">

      <Paper className="paper" variant="outlined">

        <div className="img-container">
          <img
            src={Logo}
            alt="Logo"
            className="logo"
          />
        </div>

        <div className="signup-container mb-10">
          <Typography className="signup-container__title text--nunito">
            Password Reset
          </Typography>

          <NavLink to="/login" className="signup-container__sign-in text--nunito">
            Return to login
          </NavLink>
        </div>

        <div className="form-container">
          <form className="form" noValidate autoComplete="off" onSubmit={onSubmit}>

            <TextField
              id="password"
              required
              InputLabelProps={{ shrink: true }}
              label="Password"
              value={password}
              type="password"
              name="password"
              variant="outlined"
              className="input-fields"
              onChange={handlePasswordChange}
              // onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <TextField
              id="confirm-password"
              label="Confirm Password"
              required
              className="input-fields"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              value={confirmPassword}
              type="password"
              name="confirm_password"
              style={{ marginTop: 10 }}
              onChange={handleConfirmPasswordChange}
            />

            <LoadingButton disabled={!isFormValid()} className="button__reset" variant="contained" color="primary" onClick={setPasswordReset}>
              Reset Password
            </LoadingButton>
          </form>
          <p className="text__error">{message}</p>
        </div>
      </Paper>
    </div>
  );
}

PasswordChange.propTypes = {
  sendNewPassword: PropTypes.func,
  clearError: PropTypes.func,
  session: PropTypes.objectOf,
};

PasswordChange.defaultProps = {
  sendNewPassword: () => {},
  clearError: () => {},
  session: {},
};

const mapStateToProps = (state) => ({
  session: state.session.session,
});

const mapDispatchToProps = {
  sendNewPassword: sessionOperations.sendNewPassword,
  sendResetLink: sessionOperations.sendResetLink,
  clearError: sessionOperations.clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
