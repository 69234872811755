import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { cartOperations } from '../../state/ducks/cart';
// import { productOperations } from '../../state/ducks/products';
import {
  PRODUCT_TYPE_UNBRANDED, BRANDED_BOX_COST, PRODUCT_TYPE_CUSTOM, PRODUCT_TYPE_SUPPLIER,
} from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  table: {
    borderCollapse: 'collapse',
    margin: 16,
    width: '50%',
    // margin: 'auto'
    minWidth: '350px',
  },
  tableData: { padding: 8 },
  colorSwatch: {
    width: '50px',
    height: '50px',
  },
  quantityCell: {
    width: '50px',
  },
}));

function CheckoutItem(props) {
  const classes = useStyles();
  const { product, removeItem, updateCart } = props;
  const [amount, setAmount] = useState(product.toOrder);
  const [cost, setCost] = useState((product.supplier_product.cost * product.toOrder).toFixed(2));

  const [isChecked, setIsChecked] = useState(product.supplier_product.branded_box_available);

  useEffect(() => {
    const updatedProduct = { ...product };

    if (product.supplier_product.branded_box_available) {
      updatedProduct.add_branded_box = true;
    } else {
      updatedProduct.add_branded_box = false;
    }

    updateCart(updatedProduct);
  }, []);

  const handleChange = (event) => {
    setAmount(event.target.value);

    if (isChecked) {
      const brandedBoxCost = event.target.value * BRANDED_BOX_COST;

      console.log(brandedBoxCost);

      setCost(((event.target.value * product.supplier_product.cost) + brandedBoxCost).toFixed(2));
    } else {
      setCost((event.target.value * product.supplier_product.cost).toFixed(2));
    }

    const updatedProduct = { ...product };
    updatedProduct.toOrder = Number(event.target.value);
    updateCart(updatedProduct);
  };

  const handleRemove = () => {
    removeItem(product.id);
  };

  const handleBrandedBoxCheck = (event) => {
    setIsChecked(event.target.checked);
    const updatedProduct = { ...product };
    updatedProduct.add_branded_box = event.target.checked;

    /*
    const brandedBoxCost = event.target.value * BRANDED_BOX_COST;
    if (isChecked) {
      setCost((cost + brandedBoxCost).toFixed(2));
    } else {
      setCost((cost - brandedBoxCost).toFixed(2));
    }
    */
    updateCart(updatedProduct);
  };

  const isBrandedBoxAvailable = () => {
    if (!product.supplier_produc.branded_box_available || product.supplier_produc.product_type == PRODUCT_TYPE_UNBRANDED) {
      return false;
    }

    return true;
  };

  const getTag = () => {
    if (product && product.supplier_product.product_type === PRODUCT_TYPE_SUPPLIER) {
      return <div className="tag tag--blanka">Private Label</div>;
    } if (product && product.supplier_product.product_type === PRODUCT_TYPE_UNBRANDED) {
      return <div className="tag tag--unbranded">Unbranded</div>;
    } if (product && product.supplier_product.product_type === PRODUCT_TYPE_CUSTOM) {
      return <div className="tag tag--custom">Custom Product</div>;
    }
    return false;
  };

  return (

    <TableRow>
      <TableCell><div className={classes.colorSwatch} style={{ backgroundColor: product.supplier_product.color_code }} /></TableCell>

      <TableCell className="name-tag-cell">
        {product.supplier_product.name}
        {' '}
        {product.supplier_product.color_name ? ` - ${product.supplier_product.color_name}` : '' }

        {getTag()}

      </TableCell>
      <TableCell>
        {product.supplier_product.sku}
      </TableCell>
      <TableCell>
        {product.supplier_product.branded_box_available
        && (
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                disabled={!product.supplier_product.branded_box_available}
                checked={isChecked}
                onChange={(event) => handleBrandedBoxCheck(event, product.id)}

              />
              )}
            label="Add Branded Boxes $0.40/Ea"
          />
        </FormGroup>
        )}
      </TableCell>
      <TableCell align="right" style={{ width: '100px' }} size="small">
        <FormControl fullWidth className={classes.margin}>

          <Input
            id="standard-adornment-amount"
            value={amount}
            type="number"
            onChange={handleChange}
            startAdornment={<InputAdornment position="start" />}
          />
        </FormControl>
      </TableCell>
      <TableCell align="right">{product.supplier_product.cost}</TableCell>

      <TableCell align="right">{cost}</TableCell>
      <TableCell align="right">
        <IconButton aria-label="delete" onClick={handleRemove}>
          <ClearIcon />
        </IconButton>
      </TableCell>

    </TableRow>

  );
}

CheckoutItem.propTypes = {
  product: PropTypes.objectOf(),
  removeItem: PropTypes.func,
  updateCart: PropTypes.func,
};

CheckoutItem.defaultProps = {
  product: {},
  removeItem: () => {},
  updateCart: () => {},
};

const mapStateToProps = (state) => ({
  items: state.cart.cart.items,
});

const mapDispatchToProps = {
  updateCart: cartOperations.updateCart,
  removeItem: cartOperations.removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItem);
