import React, { useState } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function HeaderCartIcon(props) {
  const { hasItems } = props;

  return (
    <>
      <>
        {hasItems
          ? (
            <SvgIcon {...props}>
              <g id="Icons">
                <g id="Two-Tone" transform="translate(-679.000000, -420.000000)">
                  <g id="Action" transform="translate(100.000000, 100.000000)">
                    <g id="Two-Tone-_x2F_-Action-_x2F_-shopping_x5F_basket" transform="translate(578.000000, 318.000000)">
                      <g>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="#5E5E5E"
                          d="M22,9h-4.8l-4.4-6.6C12.6,2.2,12.3,2,12,2s-0.6,0.1-0.8,0.4
                                    L6.8,9H2c-0.5,0-1,0.5-1,1c0,0.1,0,0.2,0,0.3l2.5,9.3c0.2,0.8,1,1.5,1.9,1.5h13c0.9,0,1.7-0.6,1.9-1.5l2.5-9.3V10
                                    C23,9.5,22.5,9,22,9z M12,4.8L14.8,9H9.2L12,4.8z M18.5,19h-13l-2.2-8h17.4L18.5,19z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <polygon fillRule="evenodd" clipRule="evenodd" fill="#D56F5B" points="17.5,17 4.5,17 2.3,9 19.7,9 " />
            </SvgIcon>
          )
          : (
            <SvgIcon {...props}>
              <g id="Icons">
                <g id="Two-Tone" transform="translate(-679.000000, -420.000000)">
                  <g id="Action" transform="translate(100.000000, 100.000000)">
                    <g id="Two-Tone-_x2F_-Action-_x2F_-shopping_x5F_basket" transform="translate(578.000000, 318.000000)">
                      <g>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          fill="#5E5E5E"
                          d="M22,9h-4.8l-4.4-6.6C12.6,2.2,12.3,2,12,2s-0.6,0.1-0.8,0.4
                          L6.8,9H2c-0.5,0-1,0.5-1,1c0,0.1,0,0.2,0,0.3l2.5,9.3c0.2,0.8,1,1.5,1.9,1.5h13c0.9,0,1.7-0.6,1.9-1.5l2.5-9.3V10
                          C23,9.5,22.5,9,22,9z M12,4.8L14.8,9H9.2L12,4.8z M18.5,19h-13l-2.2-8h17.4L18.5,19z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <polygon fill="none" points="17.5,17 4.5,17 2.3,9 19.7,9 " />
            </SvgIcon>
          )}
      </>
    </>
  );
}

export default HeaderCartIcon;
