import PropTypes from 'prop-types';
import React, { useState, useEffect,useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash.debounce'
import { Link, useHistory } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Card from '@material-ui/core/Card';
import InventoryTotalsArea from './InventoryTotalsArea';

import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import FormGroup from '@material-ui/core/FormGroup';


import { cartOperations } from '../../state/ducks/cart';
import { COUNTRIES } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    justifyContent: 'left',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },

  button: {
    marginTop: '25px',
  },
  text: {
    textAlign: 'left',
    color: theme.palette.gray,
  },
  card: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

  },
  form: {

  },
  doubleLine: {

  },
  inputLeft: {
    // marginRight: '10px',
  },

}));

function InventoryCheckoutShipping(props) {
  const classes = useStyles();
  const { addShipping, items, nextStep, getShippingCost,shipping_cost, handleBack, clearShipping } = props;
  const history = useHistory();
  const [shipping, setShipping] = useState({ ...props.shipping });
  const [shippingCost, setShippingCost] = useState();
  const [shipToMe, setShipToMe] = useState(shipping.country?true:false);
  const [blankaHoldsInv, setBlankaHoldsInv] = useState(shipping.country?false:true);
  const [shippingLoading, setShippingLoading] = useState();


  

  const onShippingChange = useCallback(
    
    debounce(()=> {
      setShippingLoading(true);
      let weight = 0
      items.forEach((item) => {
        weight += (item.supplier_product.weight*item.toOrder);
      });

      getShippingCost(shipping.country.code,shipping.zip_code, weight)
      .then((res)=>{
        console.log(res);
        setShippingLoading(false);
      })
      .catch(err=>{
        console.log('error in shipping');
        setShippingLoading(false);
      })
    },800)
  );


 


  useEffect(() => {
    if (!shipping?.zip_code || !shipping?.country?.code || shipping?.zip_code.length<3) {
      return;
    }
    onShippingChange();
  }, [shipping])
  

  const handleSubmit = () => {
    if(shipToMe){
      addShipping(shipping);
    }
    nextStep();
  };

  const shippingValid = () => {

    if(blankaHoldsInv){
      return true;
    }

    if (!shipping.first_name || !shipping.last_name || !shipping.address_one || !shipping.city || !shipping.country || !shipping.zip_code || !shipping.phone || !shipping_cost) {
      return false;
    }

    return true;
  };


  const handleZipChange = debounce((value=>{
    setShipping({ ...shipping, zip_code: value })
  }),1000)

  


  const handleResetShipping = (e) =>{
    setBlankaHoldsInv(e.target.checked);
    setShipToMe(!e.target.checked);
    clearShipping();
    setShipping({});
    setShipping({zip_code: '',country:''});
  }
  



  return (
    <div className={classes.root, 'samples-checkout template-inventory-checkout__shipping'}>

      <Card className={classes.card} variant="outlined">

        <div className="shipping-card-left">
          <Typography variant="h5" color="secondary" className="text--left text--bold text--peach text--nunito text--fs-15">WHERE DO YOU WANT YOUR INVENTORY STORED?</Typography>


        <FormGroup>
          <FormControlLabel onChange={(e)=>handleResetShipping(e)} checked={blankaHoldsInv} control={<Checkbox  />} label="STORE MY INVENTORY AT THE BLANKA WAREHOUSE" />
          <FormControlLabel onChange={(e)=>{setShipToMe(e.target.checked);setBlankaHoldsInv(!e.target.checked)}} checked={shipToMe}  control={<Checkbox />} label="SHIP MY INVENTORY TO ME " />
        </FormGroup>

        <Collapse in={shipToMe}>

        <Typography variant="h4" color="secondary" className="text--left text--medium-gray text--georgia-bold text--fs-24 mt-15">Shipping Information</Typography>
          <form id="shipping-form" className={classes.form} noValidate autoComplete="off">
            <div className="doubleLine">
              <TextField
                id="first-name"
                label="First Name"
                variant="outlined"
                className={classes.inputLeft}
                value={shipping.first_name}
                required
                onChange={(e) => setShipping({ ...shipping, first_name: e.target.value })}
              />

              <TextField
                id="last-name"
                label="Last Name"
                variant="outlined"
                value={shipping.last_name}
                required
                onChange={(e) => setShipping({ ...shipping, last_name: e.target.value })}
              />
            </div>
            <div className="singleLine">

              <TextField
                name="company"
                id="company"
                label="Company (Optional)"
                variant="outlined"
                value={shipping.company}
                onChange={(e) => setShipping({ ...shipping, company: e.target.value })}
              />

            </div>
            <div className="singleLine">
              <TextField
                name="address_one"
                id="address-one"
                required
                label="Address Line One"
                variant="outlined"
                value={shipping.address_one}
                onChange={(e) => setShipping({ ...shipping, address_one: e.target.value })}
              />

            </div>
            <div className="singleLine">
              <TextField
                name="address_two"
                id="address-two"
                label="Address Line Two"
                value={shipping.address_two}
                variant="outlined"
                onChange={(e) => setShipping({ ...shipping, address_two: e.target.value })}
              />

            </div>
            <div className="tripleLine">
              <TextField
                name="city"
                id="city"
                label="City"
                required
                variant="outlined"
                value={shipping.city}
                onChange={(e) => setShipping({ ...shipping, city: e.target.value })}
              />
              <TextField
                name="state"
                id="state"
                label="State"
                required
                variant="outlined"
                value={shipping.state}
                onChange={(e) => setShipping({ ...shipping, state: e.target.value })}
              />

              <Autocomplete
                className={classes.formControl}
                id="combo-box-demo"
                options={COUNTRIES}
                name="country"
                required
                getOptionLabel={(option) => option.name}
                value={shipping.country}
                onChange={(event, newValue) => {
                  setShipping({ ...shipping, country: newValue });
      
                }}
                renderInput={(params) => <TextField required {...params} label="Country" variant="outlined" />}
              />

            </div>

            <div className="doubleLine">
              <TextField
                name="zip_code"
                required
                id="zip code"
                label="Zip Code"
                variant="outlined"
                value={shipping.zip_code}
                onChange={(e) => setShipping({ ...shipping, zip_code: e.target.value })}
              />
              <TextField
                name="Phone"
                id="Phone"
                required
                label="Phone"
                variant="outlined"
                value={shipping.phone}
                onChange={(e) => setShipping({ ...shipping, phone: e.target.value })}
              />

            </div>

          </form>

          </Collapse>


        </div>

     
        <div className="shipping-card-right">

          <InventoryTotalsArea shipping={shipping} shippingLoading={shippingLoading} items={items} />

        </div>

      </Card>

      <div className="checkout__actions">

        <div className="checkout__breadcrumbs">
          <p className="back" onClick={(e)=>handleBack()}>Cart </p>
          <ChevronRightIcon />
          <p className="text--bold">Shipping </p>
          <ChevronRightIcon />
          <p>Payment</p>
        </div>

        <div className="checkout-button-container">
          <Button disabled={!shippingValid()} onClick={handleSubmit} variant="outlined" color="primary" className=" button button--primary">Continue To Payment</Button>
        </div>

      </div>

    </div>
  );
}

InventoryCheckoutShipping.propTypes = {
  addShipping: PropTypes.func,
  items: PropTypes.objectOf,
};

InventoryCheckoutShipping.defaultProps = {
  addShipping: () => {},
  items: {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  shipping: state.cart.shipping.shipping,    
  shipping_cost: state.cart.shipping.shipping_cost,
});

const mapDispatchToProps = {
  addShipping: cartOperations.samplesAddShipping,
  clearCart: cartOperations.samplesClearCart,
  clearShipping: cartOperations.samplesClearShipping,
  getShippingCost: cartOperations.getShippingCost,
  checkout: cartOperations.samplesCheckout,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryCheckoutShipping);
