import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { profileOperations } from '../../../state/ducks/profile/index';
import { sessionOperations } from '../../../state/ducks/session';
import StandaloneOnboardingStepOne from './standaloneOnboardingStepOne';
import StandaloneOnboardingStepTwo from './standaloneOnboardingStepTwo';
import StandaloneOnboardingStepThree from './standaloneOnboardingStepThree';
import StandaloneOnboardingStepFour from './standaloneOnboardingStepFour';
import ProgressStepper from '../../../components/progressStepper';

import Logo from '../../../images/logo.png';

function getSteps() {
  return ['Pick your plan', 'Payment Info'];
}

function StandaloneOnboarding(props) {
  const {
    profile,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const steps = getSteps();

  useEffect(() => {
     setActiveStep(profile?profile?.shop[0].onboarding_step:0);
  }, [profile]);

  const handleNext = () => {
    setLoading(true);
    setActiveStep((activeStep) => activeStep + 1);
  };

  const activeStepCheck = () => {
    if (activeStep > 2 || activeStep === 0 ) {
      return false;
    }

    return true;
  }

  const handleBack = () => {
    if(activeStep !== 1){
      setActiveStep((activeStep) => activeStep - 1);
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <StandaloneOnboardingStepOne loading={loading} nextStepStandalone={handleNext} />;
      case 1:
        return <StandaloneOnboardingStepTwo loading={loading} nextStepStandalone={handleNext} />;
      case 2:
        return <StandaloneOnboardingStepThree loading={loading} handleBack={handleBack} nextStepStandalone={handleNext} />;
      case 3:
        return <StandaloneOnboardingStepFour loading={loading} handleBack={handleBack} nextStepStandalone={handleNext} />;
      default:
        return '';
    }
  };

  return (
    <div className="root template-onboarding">
      <Paper className="paper" variant="outlined">

        <div className="welcome">
          <Typography className="text--bold text--nunito text--upper" variant="h4">Welcome To </Typography>
          <img
            src={Logo}
            alt="Logo"
            className="logo"
          />
        </div>

        <div className="modal-content">
          {getStepContent(activeStep)}
        </div>

        <div className={activeStepCheck() ? 'stepper pt-10' : 'stepper__hidden'}>
          <ProgressStepper alternativeLabel activeStep={activeStep-1 } steps={steps} handleBack={handleBack}/>
        </div>
      </Paper>
    </div>
  );
}

StandaloneOnboarding.propTypes = {
  getProfile: PropTypes.func,
  updateShop: PropTypes.func,
};

StandaloneOnboarding.defaultProps = {
  getProfile: () => {},
  updateShop: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  session: state.session.session,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  updateShop: profileOperations.updateShop,
  logout: sessionOperations.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneOnboarding);
