import React from 'react';

export const growthPlanDetials = () => (
  <div className="plan-details-container">
    <ul>
      <li>Create 10 branded products</li>
      <li>Free product mock-ups</li>

    </ul>
  </div>
);

export const vipPlanDetails = () => (
  <div className="plan-details-container">
    <ul>
      <li className="text--bold">Everything in Scale Plan + MORE</li>
      <li>Dedicated account manager</li>
      <li>Dedicated phone support</li>
      <li>Branded inserts in orders</li>
    </ul>
  </div>
);

export const scalePlanDetails = () => (
  <div className="plan-details-container">
    <ul>
      <li>Create unlimited branded products</li>
      <li>Free product mock-ups</li>
    </ul>
  </div>
);
