import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import OrderInventoryItemRow from './orderInventoryItemRow.jsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: 'none',
    marginTop: '25px',

    boxShadow: 'none',

  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  orderImage: {
    width: '100%',
  },
}));

function OrderItem(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { order } = props;

  return (
    <Card className={classes.root}>
      <CardContent container>

        <div className="order-card__header">
          <Typography variant="h5">
            Order #
            {order.id}
          </Typography>
          <Typography variant="h5">{moment(order.created_at).format('DD-MM-YYYY')}</Typography>
          <Typography variant="h5">
            Status:
            {' '}
            {order.status}
          </Typography>
        </div>

        <div className="order-card__details" xs={12} md={12}>

          {order.items.map((item) => <OrderInventoryItemRow order={item} product={item.product} />)}

        </div>

      </CardContent>
    </Card>
  );
}

OrderItem.propTypes = {
  order: PropTypes.objectOf,
};

OrderItem.defaultProps = {
  order: {},
};

export default OrderItem;
