export const FETCH_LIST = 'orders/FETCH_LIST';
export const FETCH_LIST_SUCCESS = 'orders/FETCH_LIST_COMPLETED';
export const FETCH_LIST_FAILED = 'orders/FETCH_LIST_FAILED';
export const CLEAR_FETCH_LIST_ERROR = 'orders/CLEAR_FETCH_LIST_ERROR';

export const FETCH_FUTURE_LIST = 'orders/future/FETCH_LIST';
export const FETCH_FUTURE_LIST_SUCCESS = 'orders/future/FETCH_LIST_COMPLETED';
export const FETCH_FUTURE_LIST_FAILED = 'orders/future/FETCH_LIST_FAILED';
export const CLEAR_FUTURE_FETCH_LIST_ERROR = 'orders/CLEAR_FUTURE_FETCH_LIST_ERROR';

export const FETCH_COMPLETED_LIST = 'orders/future/FETCH_COMPLETED_LIST';
export const FETCH_COMPLETED_LIST_SUCCESS = 'orders/future/FETCH_COMPLETED_LIST_SUCCESS';
export const FETCH_COMPLETED_LIST_FAILED = 'orders/future/FETCH_COMPLETED_LIST_FAILED';
export const CLEAR_COMPLETED_FETCH_LIST_ERROR = 'orders/CLEAR_COMPLETED_FETCH_LIST_ERROR';

export const FETCH_SCHEDULED_LIST = 'orders/FETCH_SCHEDULED_LIST';
export const FETCH_SCHEDULED_LIST_SUCCESS = 'orders/FETCH_SCHEDULED_LIST_COMPLETED';
export const FETCH_SCHEDULED_LIST_FAILED = 'orders/FETCH_SCHEDULED_LIST_FAILED';
export const CLEAR_SCHEDULED_FETCH_LIST_ERROR = 'orders/CLEAR_SCHEDULED_FETCH_LIST_ERROR';
export const CONFIRM_SCHEDULED_LIST_SUCCESS = 'orders/CONFIRM_SCHEDULED_LIST_SUCCESS';
export const RERUN_SCHEDULED_LIST_SUCCESS = 'orders/RERUN_SCHEDULED_LIST_SUCCESS';

export const UPDATE_ORDER = 'orders/UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'orders/UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR = 'orders/UPDATE_ORDER_ERROR';

export const FETCH_INVENTORY_ORDERS_SUCCESS = 'orders/FETCH_INVENTORY_ORDERS_SUCCESS';
export const FETCH_INVENTORY_ORDERS_FAILED = 'orders/FETCH_INVENTORY_ORDERS_FAILED';

export const PAY_CUSTOMER_ORDER = 'orders/PAY_CUSTOMER_ORDER';
export const PAY_CUSTOMER_ORDER_SUCCESS = 'orders/PAY_CUSTOMER_ORDER_SUCCESS';
export const PAY_CUSTOMER_ORDER_FAILED = 'orders/PAY_CUSTOMER_ORDER_FAILED';
