import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import { cartOperations } from '../../state/ducks/cart';
import { profileOperations } from '../../state/ducks/profile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },

  button: {
    marginTop: '25px',
  },
  text: {
    textAlign: 'left',
    color: theme.palette.gray,
  },
  card: {
    padding: '40px',
  },

}));

function SampleCheckoutSuccess(props) {
  const classes = useStyles();
  const {
    error, clearError, loading, clearCart,
  } = props;

  useEffect(() => {

  }, []);

  return (
    <div className={classes.root}>

      <Grid className={classes.header} container>

        <Typography>Check Out</Typography>

      </Grid>

      <Card className={classes.card} variant="outlined">

        <Typography className={classes.text} variant="h5" style={{ marginBottom: '10px' }}>Thank you for your sample order.</Typography>
        <Typography className={classes.text} variant="body1">
          In the meantime, feel free to browse more of
          {' '}
          <Link to="/">our products</Link>
          {' '}
          or check out our
          {' '}
          <a target="__blank" href="https://blankabrand.com/beyond-the-brand-beauty-blog/">blog</a>
          {' '}
          to
          learn more about private labelling.

        </Typography>

      </Card>
    </div>
  );
}

SampleCheckoutSuccess.defaultProps = {
  items: [],
  isLoading: false,
  hasErrored: false,
  profile: {},
};

const mapStateToProps = (state) => ({
  items: state.cart.cart.items,
  profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
  addPayment: profileOperations.addPayment,
  updateShop: profileOperations.updateShop,
  getProfile: profileOperations.getProfile,
  clearCart: cartOperations.clearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleCheckoutSuccess);
