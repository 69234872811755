import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';

import {
  CardElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import LoadingButton from '../../components/buttons/loadingButton';
import { profileOperations } from '../../state/ducks/profile';

function ProfileBillingUpdate(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [postal, setPostal] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState('');
  const history = useHistory();
  const { getProfile, addPayment, profile } = props;

  useEffect(() => {
    async function fetchData() {
      await getProfile();
    }
    fetchData();
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleChange = (event) => {
    setPostal(event.target.value);
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          postal_code: postal,
        },
        name,
      },
    });

    if (error) {
      toast.error(`Card Error: ${error}`);
    } else {
      addPayment({ payment_id: paymentMethod.id, last_four: paymentMethod.card.last4, postal })
        .then((response) => {
          if (response && response.status === 201) {
            // updateShop(profile.shop[0].id,);
            // props.handleClose();
            props.getProfile();
            toast.success('Payment Method Updated');
            history.push('/profile');
            setLoading(false);
          } else {
            toast.error('Card Error, Please Contact Us');
            setLoading(false);
          }
        });
    }
  };

  return (
    <div className="profile-billing-update root">
      <Grid className="paper" container spacing={2}>

        <Card className="card" variant="outlined">

          <Typography className="text__payment-header text--bold text--georgia">Payment Information</Typography>
          <Typography className="card__secure-message text--nunito" variant="body" color="textPrimary">
            All transactions are secure and encrypted
          </Typography>

          <div className="stripe">
            <TextField className="text__name" onChange={handleNameChange} id="outlined-basic" label="Name On Card" variant="outlined" />
            <TextField className="text__postal-zip" onChange={handleChange} id="outlined-basic" label="POSTAL/ZIP CODE" variant="outlined" />
            <CardElement
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                      marginTop: '25px',
                      maxWidth: '300px',
                      border: '1px solid #5e5e5e',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </div>

          <LoadingButton loading={loading} disabled={!stripe} onClick={handleSubmit} color="primary" variant="contained" className="button button--primary">
            Save
          </LoadingButton>
        </Card>
      </Grid>
    </div>
  );
}

ProfileBillingUpdate.propTypes = {
  getProfile: PropTypes.func,
  addPayment: PropTypes.func,
};

ProfileBillingUpdate.defaultProps = {
  getProfile: () => {},
  addPayment: () => {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  error: state.profile.getProfile.error,
  loading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  clearError: profileOperations.clearError,
  addPayment: profileOperations.addPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBillingUpdate);
