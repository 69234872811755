import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const STARTING_INVENTORY = 10;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '60%',
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
  },
  orderForm: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',

  },
  input: {
    marginRight: '20px',
  },

}));

export default function ProductInventoryModal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    open, handleClose, product, onOrder,
  } = props;

  const [inventory, setInventory] = React.useState(STARTING_INVENTORY);
  const [cost, setCost] = React.useState(Number(product.supplier_product.cost * STARTING_INVENTORY).toFixed(2));

  const handleChange = (event) => {
    if (event.target.value > 0) {
      setInventory(event.target.value);
      setCost(Number(product.supplier_product.cost * event.target.value).toFixed(2));
    }
  };

  const handleOrder = () => {
    onOrder(inventory);

    // toast.success(<InventoryAddedNotification/>);
    handleClose();
  };

  return (
    <div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={clsx(classes.modal, 'product-modal')}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>

            <h3>Order Inventory</h3>

            <form className={classes.orderForm} noValidate autoComplete="off">
              <TextField
                className={classes.input}
                value={inventory}
                type="number"
                InputLabelProps={{ shrink: true }}
                id="outlined-basic"
                label="Inventory"
                variant="outlined"
                onChange={handleChange}
                InputProps={{
                  min: '0',
                }}
              />
              <TextField
                type="number"
                InputLabelProps={{ shrink: true }}
                id="outlined-cost"
                label="Cost"
                variant="outlined"
                readonly
                disabled
                value={cost}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,

                }}
              />
            </form>

            <div className="modal__footer">

              <Button onClick={handleOrder} size="full" variant="outlined" color="secondary" className="button--outline">Add to Cart</Button>
              <Button onClick={handleClose} size="full" variant="outlined" color="primary" className="button--red-outline">Cancel</Button>

            </div>

          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ProductInventoryModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  product: PropTypes.arrayOf(),
  onOrder: PropTypes.func,
};

ProductInventoryModal.defaultProps = {
  open: false,
  handleClose: () => {},
  product: [],
  onOrder: () => {},
};
