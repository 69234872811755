import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { productOperations } from '../../state/ducks/products';

import ProductItem from './productItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: '50px',
  },

}));

function BlankProductsList(props) {
  const classes = useStyles();
  const { getBlankProducts, products, catgeoryFilter } = props;

  useEffect(() => {
    async function fetchData() {
      await getBlankProducts(catgeoryFilter);
    }
    fetchData();
  }, [catgeoryFilter, products, getBlankProducts]);

  return (

    <>
      {products.map((product) => (
        <Grid item xs={12} md={6} lg={3} key={product.id}>
          <ProductItem product={product} />
        </Grid>
      ))}
    </>
  );
}

BlankProductsList.propTypes = {
  getBlankProducts: PropTypes.func,
  products: PropTypes.arrayOf,
};

BlankProductsList.defaultProps = {
  products: [],
  getBlankProducts: () => {},
};

const mapStateToProps = (state) => ({
  products: state.products.blank_products.products.results,
});

const mapDispatchToProps = {
  getBlankProducts: productOperations.getBlankProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlankProductsList);
