import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { profileOperations } from "../../state/ducks/profile";
import Modal from '@material-ui/core/Modal';
import clsx from 'clsx';
import PlanModalCard from "components/modals/plans/PlanModalCard";
import Link from '@material-ui/core/Link';
import { growthPlanDetials, vipPlanDetails, scalePlanDetails } from "components/modals/plans/index";
import {PLAN_FREE_ID} from '../../constants'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1' },
  },
}));

function ChangePlanModal(props) {
    const classes = useStyles();
    const { getProfile, profile, updatePlan, open, handleClose } = props;

    const isPlan = (id ) =>{
        if(id === profile?.shop[0].plan){
            return true
        }
        return false;
    }

    const handleFree = async (id) => {
        await updatePlan(id);
        await getProfile();
        handleClose()
    };

    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='Plan Modal'
          aria-describedby='Pick your plan'
          className={clsx(
            classes.modal,
            'onboarding-modal',
            'change-plan-modal'
          )}>
          <div className={clsx(classes.paper, 'change-plan-modal-wrapper')}>
            <div>
              <Typography
                onClick={handleClose}
                className='close-button modal__close modal__close--change-plan'>
                [x] close
              </Typography>
            </div>

            <div>
              <Typography
                className='welcome text--georgia text--bold pt-30'
                color='secondary'>
                Pick Your Plan{' '}
              </Typography>
            </div>

            <div className='modal-content mb-10 pb-30'>
              <div
                className={`plans ${profile?.shop[0].plan === PLAN_FREE_ID
                  ? 'plan-free-active'
                  : ''}`}>
                <PlanModalCard
                  isModal={true}
                  handleClose={handleClose}
                  isPlan={isPlan(2)}
                  planName='GROWTH PLAN'
                  planDetails={growthPlanDetials()}
                  planNumber={2}
                  planPrice='$29/mo'
                />

                <PlanModalCard
                  centerClassName={
                    profile?.shop[0].plan === PLAN_FREE_ID ? 'center' : ''
                  }
                  enterClassName=''
                  isModal={true}
                  handleClose={handleClose}
                  isPlan={isPlan(3)}
                  centerTitleText='text__title__center'
                  planName='SCALE PLAN'
                  planDetails={scalePlanDetails()}
                  planNumber={3}
                  planPrice='$59/mo'
                />

                <PlanModalCard
                  isModal={true}
                  handleClose={handleClose}
                  isPlan={isPlan(4)}
                  planName='VIP PLAN'
                  planDetails={vipPlanDetails()}
                  planPrice='$99/mo'
                  planNumber={4}
                />
              </div>
            </div>

            <div className='pb-30'>
              <div className='try-free-container display--flex flex-row-center'>
                <Typography className='pr-10 text--fs-13 text--blue text--georgia text--bold text--upper'>
                  Free Plan
                </Typography>

           
                <li style={{ color: '#246071' }}>
                  <span className='pl-5 text--fs-12 text--blue text--nunito'>
                  Create 10 unbranded products
                  </span>
                </li>
              </div>

              <div className='try-free-container display--flex flex-row-center'>
                {profile?.shop[0].plan === 1 ? (
                  <Link
                    style={{ cursor: 'pointer', textTransform: 'uppercase' }}
                    onClick={handleClose}
                    className='text--fs-12 text--bold text--nunito text--blue text--upper'>
                    Keep Free Plan >
                  </Link>
                ) : (
                  <Link
                    style={{
                      cursor: 'pointer',
                      textTransform: 'uppercase',
                      textDecoration: 'underline',
                    }}
                    onClick={() => handleFree(1)}
                    className='text--fs-12 text--bold text--nunito text--blue text--upper'>
                    Get Started
                    {' '}
                    <i className="fas fa-chevron-right" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
}

ChangePlanModal.defaultProps = {
    profile: {},
    shop: {}
};

const mapStateToProps = (state) => ({
    profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
    getProfile: profileOperations.getProfile,
    updateShop: profileOperations.updateShop,
    updatePlan: profileOperations.updatePlan,
};


export default connect(mapStateToProps, mapDispatchToProps)(ChangePlanModal);
