import React, { useState } from 'react';
import {  toast } from 'react-toastify';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FreePlanLimitModal from "../../components/modals/freePlanLimitModal";
import ImageCarousel from './imageCarousel';

import { PRODUCT_TYPE_SUPPLIER, PLAN_GROWTH_ID, PLAN_FREE_ID, PLAN_SCALE_ID, PRODUCT_CATEGORY_SAMPLE_KIT_ID,PRODUCT_TYPE_UNBRANDED } from '../../constants';
import { FreePlanLimitError, LogovisualizerError, notifications } from '../../components/notifications'




const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop:'100px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    paddingTop: '40px',
    width: '75%',
    maxWidth:'1600px',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    minHeight: '700px'
  },
  heading: {
    fontSize: 12,
    fontWeight: 400,
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: 'unset',
  }

}));

export default function ProductModal(props) {
  const classes = useStyles();  
  const {open, handleClose, product, onAddToStore, handleSampleOrder, onClose, profile } = props;
  const [expanded, setExpanded] = useState('panel1');
  const [openFreePlanLimitModal, setOpenFreePlanLimitModal] = React.useState(false);



  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const orderSamples = () => {
      handleSampleOrder();
  }

  const addToStore = () => {
      
      if(product.product_type === PRODUCT_TYPE_SUPPLIER && profile.shop[0].plan === PLAN_FREE_ID) {
        
        setOpenFreePlanLimitModal(true);
        return;
        
      } else if (product.product_type === PRODUCT_TYPE_SUPPLIER && ( profile.shop[0].plan === PLAN_GROWTH_ID || profile.shop[0].plan === PLAN_SCALE_ID )) {
        toast.error(LogovisualizerError);
        return;
        
      }else if(product.categories.includes(PRODUCT_CATEGORY_SAMPLE_KIT_ID)){

        toast.error("Oops, sample kits are only available for sample orders!");
        return;
      }
    
      onAddToStore(product.id)
  }

  const isSupplierProduct = (product) =>{
    if(product.product_type === PRODUCT_TYPE_SUPPLIER){
      return true;
    }
    return false;
  }

  const isUnbranded = (product) =>{
    if(product.product_type === PRODUCT_TYPE_UNBRANDED){
      return true;
    }
    return false;
  }
  

  const isSampleKit = (product) =>{
    
    if(product?.categories.includes(PRODUCT_CATEGORY_SAMPLE_KIT_ID)){
      return true
    }

    return false;
  }

  const calcProfit = () =>{
    return (product.suggested_cost-product.cost).toFixed(2);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={clsx(classes.modal, 'product-modal')}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={`${classes.paper} product-modal__wrapper`}>

            <Typography onClick={onClose} className="modal__close">[x] close</Typography>


            <div className="modalLeft" style={{ paddingTop: '70px' }}>
              {product?.product_images.length > 1 ? <ImageCarousel productImageArray={[{image: product.image} , ...product.product_images]}/> : <img src={product.image}/> }
            </div>
            
            <div className="modalRight">
               <Typography variant="h5" className="text__product-name text--bold text--upper text--nunito" >{product.name} &#xb7; {product.color_name}</Typography>
            
                <div className="accordion">

                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="heading">Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {product.description === "" ?
                        <Typography className="text--nuntio">
                          N/A
                        </Typography>
                      :
                        <Typography>
                          <div dangerouslySetInnerHTML={{ __html: product.description }} />
                        </Typography>
                      }
                    </AccordionDetails>
                  </Accordion>
                  
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="heading">Benefits</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {product.benefits === "" ?
                        <Typography className="text--nuntio">
                          N/A
                        </Typography> 
                      :
                        <Typography>
                          <div dangerouslySetInnerHTML={{ __html: product.benefits }} />
                        </Typography>
                      }
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className="heading">Application</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {product.application === "" ?
                        <Typography className="text--nuntio">
                          N/A
                        </Typography>
                        :
                        <Typography>
                          <div dangerouslySetInnerHTML={{ __html: product.application }} />
                        </Typography>
                      }
                    </AccordionDetails>
                  </Accordion>

                  <Accordion  expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography className="heading">Ingredients</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {product.ingredients === "" ?
                          <Typography className="text--nuntio">
                            N/A
                          </Typography> 
                        : 
                          <Typography>
                            <div dangerouslySetInnerHTML={{ __html: product.ingredients }} /> 
                          </Typography>
                      }
                    </AccordionDetails>
                  </Accordion>
                  
                </div>
              
                <div className="modal__options">
                  {product.variants?.map((variant) =>
                      <div>{variant.name}</div>
                  )}
                </div>

                <div className="modal__price">
                  <Typography className="text__profit" variant="h6">UNIT PROFIT</Typography>
                  <Typography className="text--bold text__dollar" variant="h5"  >${calcProfit()} USD</Typography>                    
                  <Typography   className="text--nunito text--upper text--sub">Based on ${product.suggested_cost} MSRP</Typography>
                  <Typography  className="text--nunito text--upper text--sub">unit cost ${product.cost} USD</Typography>
                </div>

                <div className="modal__actions">
                
                 
                  <Button size="large" onClick={orderSamples} style={{ letterSpacing: '6px', paddingTop: '10px', paddingBottom: '10px' } }variant="outlined" color="primary" className="mb-5  button button--red-outline">
                    Order Samples {!isUnbranded(product) && <span>*</span>}
                  </Button>
                 
                 
                  
                  <Button disabled={isSupplierProduct(product)||isSampleKit(product)}  onClick={addToStore} size="large" variant="contained" color="primary" className="button button--primary">
                    Add To My products
                  </Button>
                  
                  {!isUnbranded(product) &&
                    <Typography  className="sample-note">*Note: All sample orders are shipped with unbranded products</Typography>
                  }

            
                  

                </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <FreePlanLimitModal handleClose={() => setOpenFreePlanLimitModal(false)} open={openFreePlanLimitModal} />

    </div>
  );
}