import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Redirect,
} from 'react-router-dom';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import LoadingButton from '../../components/buttons/loadingButton';

import { profileOperations } from '../../state/ducks/profile';

function ProfilePassword(props) {
  const { getProfile, profile, changePassword } = props;

  const [siteName, setSiteName] = useState('');

  // useEffect(() => {
  // }, [profile]);

  const hasUsablePassword = () => {
    if (profile && profile.has_usable_password) {
      return true;
    }
    return false;
  };

  return (
    <div className="template-profile-password root">

      <Card className="card" variant="outlined">
        <div className="header-text-container">
          <Typography className="text__title text--georgia text--bold" color="textPrimary">
            Password
          </Typography>

          <Typography className="text__secure-message text--nunito" variant="body" color="textPrimary">
            All passwords are secure and encrypted
          </Typography>

          {hasUsablePassword() && (
            <>
              <Typography className="text__current-password text--nunito text--upper">Current Password</Typography>

              <div className="password-display text--nunito">
                *************
              </div>

              <div className="update-password__container">
                <Link className="update-password text--nunito" to="/profile/password/update">CHANGE YOUR PASSWORD</Link>
              </div>
            </>
          )}

          {!hasUsablePassword()
            && (

              <div className="flex--flex">
                <LoadingButton
                  className="button button--blue"
                  variant="contained"
                  component={Link}
                  to="/profile/password/update"
                >
                  Set App Password
                </LoadingButton>
              </div>

            )}

        </div>
      </Card>
    </div>
  );
}

ProfilePassword.defaultProps = {
  profile: {},
};

const mapStateToProps = (state) => ({
  profile: state.profile.getProfile.profile,
  error: state.profile.getProfile.error,
  loading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  getProfile: profileOperations.getProfile,
  clearError: profileOperations.clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePassword);
