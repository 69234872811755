import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { orderOperations } from '../../state/ducks/orders';
import OrderItem from './orderItem';
import OrderFilters from './OrderFilters';
import OrdersList from './OrderList';
import { ORDER_STATUS_PAYMENT_REQUIRED, ORDER_STATUS_UNFULFILLED } from '../../constants';

function MyOrders(props) {
  const {
    getOrders, orders, payCustomerOrder,
  } = props;
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const pageSize = 50;

  const handleChange = (event, newPage) => {
    setPage(newPage);
    getOrders(filter, newPage);

    // console.log(orders.count / pageSize);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await getOrders(ORDER_STATUS_PAYMENT_REQUIRED);
      setLoading(false);
    }
    fetchData();
    setFilter(ORDER_STATUS_PAYMENT_REQUIRED);
  }, []);

  const handlePlaceOrder = async () => {
    try {
      setLoading(true);
      await getOrders(filter);
      setLoading(false);
    } catch (e) {
      // do nothing
    }
  };

  const isReadOnly = () => {
    if (filter === ORDER_STATUS_PAYMENT_REQUIRED) {
      return false;
    }
    return true;
  };

  const handleFilter = async (newFilter) => {
    if (filter !== newFilter) {
      setFilter(newFilter);
      setLoading(true);
      await getOrders(newFilter);
      setLoading(false);
    }
  };

  return (
    <div className="orders-view root">
      <Grid className="page-header" container>
        <Typography className="pb-25" variant="h6">Customer Orders</Typography>
      </Grid>
      <Grid className="paper" container>

        <OrderFilters onFilter={handleFilter} activeFilter={filter} />

        { !loading
          ? <OrdersList count={Math.ceil(orders.count / pageSize)} handleChange={handleChange} page={page} isReadOnly={isReadOnly} handlePlaceOrder={handlePlaceOrder} orders={orders.results} />
          : (
            <div className="orders__skeleton_container">
              <div className="orders__skeleton">
                <div className="orders__skeleton--split">
                  <Skeleton variant="rect" animation="wave" height={50} width={200} />
                  <Skeleton variant="rect" animation="wave" height={50} width={400} />
                  <Skeleton variant="rect" animation="wave" height={50} width={200} />
                </div>
                <Skeleton variant="rect" animation="wave" height={200} />
                <Skeleton variant="rect" animation="wave" height={50} />
              </div>
              <div className="orders__skeleton">
                <div className="orders__skeleton--split">
                  <Skeleton variant="rect" animation="wave" height={50} width={200} />
                  <Skeleton variant="rect" animation="wave" height={50} width={400} />
                  <Skeleton variant="rect" animation="wave" height={50} width={200} />
                </div>
                <Skeleton variant="rect" animation="wave" height={200} />
                <Skeleton variant="rect" animation="wave" height={50} />
              </div>
            </div>
          )}

      </Grid>
    </div>
  );
}

MyOrders.propTypes = {
  getOrders: PropTypes.func,
  orders: PropTypes.objectOf,
};

MyOrders.defaultProps = {
  getOrders: () => {},
  payCustomerOrder: () => {},
  orders: [],
};

const mapStateToProps = (state) => ({
  orders: state.orders.getOrders.orders,

});

const mapDispatchToProps = {
  getOrders: orderOperations.getOrders,
  payCustomerOrder: orderOperations.payCustomerOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);

// <Pagination count={10} page={page} onChange={handleChange} />
