import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
  CardElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { profileOperations } from '../state/ducks/profile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  header: {
    borderBottom: `1px solid ${theme.palette.gray}`,
    marginBottom: '50px',
  },
  card: {
    width: '100%',
    padding: '20px',
    textAlign: 'left',
  },
  button: {
    marginTop: '25px',
  },
  stripe: {
    maxWidth: '600px',

  },

}));

function CreditCardForm(props) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [postal, setPostal] = useState('');
  const [name, setName] = useState('');
  const {
    addPayment, getProfile,
  } = props;

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleChange = (event) => {
    setPostal(event.target.value);
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          postal_code: postal,
        },
        name,
      },
    });

    if (error) {
      console.log('[error]', error);
    } else {
      addPayment({ payment_id: paymentMethod.id, last_four: paymentMethod.card.last4, postal })
        .then((response) => {
          if (response && response.status === 201) {
            toast.success('Payment Method Updated');
            getProfile();
          } else {
            toast.success('Card Error, Please Contact Us');
          }
        });
    }
  };

  return (
    <div className={classes.root}>

      <div className={classes.stripe}>
        <TextField style={{ marginTop: '', width: '53%', marginRight: '10px' }} onChange={handleNameChange} id="outlined-basic" label="Name On Card" variant="outlined" />
        <TextField style={{ marginTop: '', marginBottom: '25px', width: '45%' }} onChange={handleChange} id="outlined-basic" label="Postal/ZIP Code" variant="outlined" />
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                  marginTop: '25px',
                  maxWidth: '300px',
                  border: '1px solid #5e5e5e',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />

      </div>
      <Button disabled={!stripe} onClick={handleSubmit} color="primary" variant="contained" className={classes.button}>Save Card</Button>
    </div>
  );
}

CreditCardForm.propTypes = {
  addPayment: PropTypes.func,
  getProfile: PropTypes.func,
};

CreditCardForm.defaultProps = {
  addPayment: () => {},
  getProfile: () => {},
};

const mapStateToProps = (state) => ({
  error: state.profile.getProfile.error,
  loading: state.profile.getProfile.loading,
});

const mapDispatchToProps = {
  clearError: profileOperations.clearError,
  addPayment: profileOperations.addPayment,
  getProfile: profileOperations.getProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
