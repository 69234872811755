import React from 'react';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

export default function LoadingButton(props) {
  const {
    loading, disabled, onClick, children, variant, color, className, type,
  } = props;

  /*
    if loading,
      hide children text to keep width of button
      show spinner
    else
      show children
  */

  const handleClick = (event) => {
    try {
      if (!loading) {
        onClick(event);
      }
    } catch (e) {
      onClick(event);
    }
  };

  const isLoading = () => {
    if (loading) {
      return 'loading';
    }

    return '';
  };

  return (

    <Button {...props} disabled={disabled} onClick={handleClick} variant={variant} color={color} className={`loading-button ${className} ${isLoading()}`} type={type}>
      {loading
        && (
          <>
            <div className="loading-button__icon-wrapper">
              <CircularProgress className="loading-button__icon" color="inherit" size={25} />
            </div>
            <div className="loading-button__text">
              {children}
            </div>
          </>
        )}
      {!loading
              && <>{children}</>}
    </Button>
  );
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

LoadingButton.defaultProps = {
  loading: false,
  disabled: false,
  onClick: () => {},
};
