import React, { useEffect } from "react";
import { connect } from "react-redux";
import {  makeStyles } from '@material-ui/core/styles';
import { cartOperations } from "../../state/ducks/cart";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import InventoryCheckout from './InventoryCheckout';
import SampleCheckout from './SampleCheckout';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    header:{
        borderBottom: "1px solid " + theme.palette.gray,
        marginBottom: "50px",
        'p':{
            letterSpacing: '4.8px'
        }
    },
    table: {
        borderCollapse: "collapse",
        margin: 16,
        width: '95%',
        //margin: 'auto'
        minWidth: '350px'
    },
    tableData: { padding: 8 },
    colorSwatch:{
        width: '50px',
        height: '50px'
    },
    totalsArea: {
        textAlign:'right',
        marginTop: '20px'
    },
    total:{
        borderTop: "1px solid " + theme.palette.gray,

    },
    totalsTable:{
        width: '300px',
        marginLeft: 'auto',
        textAlign: 'right',
        marginBottom: '25px',
        "& td": {
            padding: '10px 0px',
            textAlign: 'right'
        }
    }
}));

function CheckoutMain(props) {
    const classes = useStyles();
    const { cart, profile} = props;
   
    const getCheckoutType = () => {

        //if no billing, send them to billing section
        if(!profile?.shop[0].billing_address){
            //return <CheckoutPayment/>;
        }   

        //check what type of checkout should be show
        if(cart.cart.items.length>0){
            return <InventoryCheckout/>; //inventory
        }else if(cart.samplesCart.items.length>0){
            return <SampleCheckout/>;
        }
       
        return <Typography variant="h4">Cart Empty</Typography>
    }
   

    return (
        <div className={classes.root}>
            <Grid className={classes.header} container spacing={2} >
                <Typography className="text--nunito text--ls-wide">CHECKOUT</Typography>
            </Grid>

            {getCheckoutType()} 
        
        </div>
    );
    
}

CheckoutMain.defaultProps = {
    items: [],
    isLoading: false,
    hasErrored: false,
    profile:{}
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    session: state.session.session,
    profile: state.profile.getProfile.profile,
});

const mapDispatchToProps = {
    checkout : cartOperations.checkout,
    
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutMain);
