import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import axios from 'axios';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as reducers from './ducks';
import { middlewareConfig, createLogger } from './middlewares';

const BASE_URL = process.env.REACT_APP_API_URL;

const client = axios.create({ // all axios can be used, shown in axios documentation
  baseURL: BASE_URL,
  responseType: 'json',
});

export default function configureStore(initialState) {
  const rootReducer = combineReducers(reducers);

  let middleware = [];
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENVIRONMENT === 'staging') {
    middleware = [thunkMiddleware, axiosMiddleware(client, middlewareConfig), createLogger(true)];
  } else {
    middleware = [thunkMiddleware, axiosMiddleware(client, middlewareConfig)];
  }

  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(...middleware),
    ),
  );
}
