export const ADD_TO_CART = 'cart/ADD_TO_CART';
export const REMOVE_FROM_CART = 'cart/REMOVE_FROM_CART';
export const CLEAR_CART = 'cart/CLEAR_CART';
export const UPDATE_CART = 'cart/UPDATE_CART';
export const CHECKOUT_CART = 'cart/CHECKOUT_CART';

export const UPDATE_CART_HOLD_INVENTORY = 'cart/UPDATE_CART_HOLD_INVENTORY';

export const CART_UPDATE_SHIPPING = 'cart/CART_UPDATE_SHIPPING';
export const CART_CLEAR_SHIPPING = 'cart/CART_CLEAR_SHIPPING';

export const CHECKOUT_COUPON_SUCCESS = 'cart/CHECKOUT_COUPON_SUCCESS';
export const CHECKOUT_COUPON_FAILED = 'cart/CHECKOUT_COUPON_FAILED';
export const RESET_COUPON = 'cart/RESET_COUPON';

export const SAMPLES_ADD_TO_CART = 'cart/SAMPLES_ADD_TO_CART ';
export const SAMPLES_REMOVE_FROM_CART = 'cart/SAMPLES_REMOVE_FROM_CART';
export const SAMPLES_CLEAR_CART = 'cart/SAMPLES_CLEAR_CART';
export const SAMPLES_UPDATE_CART = 'cart/SAMPLES_UPDATE_CART';
export const SAMPLES_CHECKOUT_CART = 'cart/SAMPLES_CHECKOUT_CART';

export const SAMPLE_ORDER = 'cart/SAMPLE_ORDER';
export const SAMPLE_ORDER_SUCCESS = 'cart/SAMPLE_ORDER_SUCCESS';
export const SAMPLE_ORDER_FAILED = 'cart/SAMPLE_ORDER_FAILED';

export const ORDER_BRAND_PLATE = 'cart/ORDER_BRAND_PLATE';
export const ORDER_BRAND_PLATE_SUCCESS = 'cart/ORDER_BRAND_PLATE_SUCCESS';
export const ORDER_BRAND_PLATE_FAILED = 'cart/ORDER_BRAND_PLATE_FAILED';

export const GET_SHIPPING_COST = 'cart/GET_SHIPPING_COST';
export const GET_SHIPPING_COST_SUCCESS = 'cart/GET_SHIPPING_COST_SUCCESS';
export const GET_SHIPPING_COST_FAILED = 'cart/GET_SHIPPING_COST_FAILED';
export const RESET_SHIPPING_COST = 'cart/RESET_SHIPPING_COST';
