export const FETCH_SUPPLIER_PRODUCTS = 'products/FETCH_SUPPLIER_PRODUCTS_LIST';
export const FETCH_SUPPLIER_PRODUCTS_SUCCESS = 'products/FETCH_SUPPLIER_PRODUCTS_COMPLETED';
export const FETCH_SUPPLIER_PRODUCTS_FAILED = 'products/FETCH_SUPPLIER_PRODUCTS_FAILED';
export const CLEAR_FETCH_SUPPLIER_PRODUCTS_ERROR = 'products/CLEAR_FETCH_SUPPLIER_PRODUCTS_ERROR';

export const FETCH_CUSTOM_PRODUCTS = 'products/FETCH_CUSTOM_PRODUCTS_LIST';
export const FETCH_CUSTOM_PRODUCTS_SUCCESS = 'products/FETCH_CUSTOM_PRODUCTS_COMPLETED';
export const FETCH_CUSTOM_PRODUCTS_FAILED = 'products/FETCH_CUSTOM_PRODUCTS_FAILED';
export const CLEAR_FETCH_CUSTOM_PRODUCTS_ERROR = 'products/CLEAR_FETCH_CUSTOM_PRODUCTS_ERROR';

export const FETCH_BLANK_PRODUCTS = 'products/FETCH_BLANK_PRODUCTS_LIST';
export const FETCH_BLANK_PRODUCTS_SUCCESS = 'products/FETCH_BLANK_PRODUCTS_COMPLETED';
export const FETCH_BLANK_PRODUCTS_FAILED = 'products/FETCH_BLANK_PRODUCTS_FAILED';
export const CLEAR_FETCH_BLANK_PRODUCTS_ERROR = 'products/CLEAR_FETCH_BLANK_PRODUCTS_ERROR';

export const FETCH_MY_PRODUCTS = 'products/FETCH_MY_PRODUCTS_LIST';
export const FETCH_MY_PRODUCTS_SUCCESS = 'products/FETCH_MY_PRODUCTS_COMPLETED';
export const FETCH_MY_PRODUCTS_FAILED = 'products/FETCH_MY_PRODUCTS_FAILED';
export const CLEAR_FETCH_MY_PRODUCTS_ERROR = 'products/CLEAR_FETCH_MY_PRODUCTS_ERROR';

export const PUBLISH_PRODUCT = 'products/PUBLISH_PRODUCT';
export const PUBLISH_PRODUCT_SUCCESS = 'products/PUBLISH_PRODUCT_SUCCESS';
export const PUBLISH_PRODUCT_FAILED = 'products/PUBLSH_PRODUCT_FAILED';

export const ADD_PRODUCT_TO_STORE = 'products/ADD_PRODUCT_TO_STORE';
export const ADD_PRODUCT_TO_STORE_SUCCESS = 'products/ADD_PRODUCT_TO_STORE_SUCCESS';
export const ADD_PRODUCT_TO_STORE_FAILED = 'products/ADD_PRODUCT_TO_STORE_FAILED';

export const PUBLISH_TO_STORE = 'products/PUBLISH_TO_STORE';
export const PUBLISH_TO_STORE_SUCCESS = 'products/PUBLISH_TO_STORE_SUCCESS';
export const PUBLISH_TO_STORE_FAILED = 'products/PUBLISH_TO_STORE_FAILED';

export const UNPUBLISH_PRODUCT = 'products/UNPUBLISH_PRODUCT';
export const UNPUBLISH_PRODUCT_SUCCESS = 'products/UNPUBLISH_PRODUCT_SUCCESS';
export const UNPUBLISH_PRODUCT_FAILED = 'products/UNPUBLISH_PRODUCT_FAILED';

export const ADD_INVENTORY = 'products/ADD_INVENTORY_STORE';
export const ADD_INVENTORY_SUCCESS = 'products/ADD_INVENTORY_SUCCESS';
export const ADD_INVENTORY_FAILED = 'products/ADD_INVENTORY_FAILED';
