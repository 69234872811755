import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

function ImageCarousel(props) {
  const { productImageArray } = props;

  return (
    <Carousel autoPlay={false} infiniteLoop dynamicHeight showArrows={false} showStatus={false} renderIndicator={false}>
      {productImageArray.map((image) => (
        <div key={image}>
          <img src={image.image} alt="Product" />

        </div>
      ))}
    </Carousel>
  );
}

ImageCarousel.propTypes = {
  productImageArray: PropTypes.arrayOf,
};

ImageCarousel.defaultProps = {
  productImageArray: [],
};

export default ImageCarousel;
