import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FilterCard from './FilterCard';

import {
  FILTER_ALL,
  FILTER_EYE,
  FILTER_LIP,
  FILTER_FACE,
  FILTER_BRUSH,
  FILTER_SKIN_CARE,
} from '../../constants';

/*
Notes: I changed id to catgeoryID on the filter card props. id is already a reverseved word for html elements as well catgeory ID
is more descriptive

Use child props to pass in text
*/

export default function FilterMenu(props) {
  const { fetchFilter, catgeoryFilter } = props;

  // Please remove: we do not need to keep a local state since the current filter is being passed as a prop
  // const [current, setCurrent] = useState(FILTER_ALL);

  const fetchFilterType = (id) => {
    fetchFilter(id);
  };

  // check if the current filter ID is equal to the ID passed in
  const isActive = (filter, currentFilter) => {
    if (filter === currentFilter) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex-row-center filter-menu-template">
      <FilterCard current={isActive(FILTER_ALL, catgeoryFilter)} className="mr-10 img img__all" handleClick={fetchFilterType} categoryID={FILTER_ALL}>
        ALL
      </FilterCard>
      <FilterCard current={isActive(FILTER_EYE, catgeoryFilter)} className="mr-10 img img__eye" handleClick={fetchFilterType} categoryID={FILTER_EYE}>
        EYES
      </FilterCard>
      <FilterCard current={isActive(FILTER_LIP, catgeoryFilter)} className="mr-10 img img__lip" handleClick={fetchFilterType} categoryID={FILTER_LIP}>
        LIPS
      </FilterCard>
      <FilterCard current={isActive(FILTER_FACE, catgeoryFilter)} className="mr-10 img img__face" handleClick={fetchFilterType} categoryID={FILTER_FACE}>
        FACE
      </FilterCard>
      <FilterCard current={isActive(FILTER_BRUSH, catgeoryFilter)} className="mr-10 img img__brush" handleClick={fetchFilterType} categoryID={FILTER_BRUSH}>
        BRUSHES
      </FilterCard>
      <FilterCard current={isActive(FILTER_SKIN_CARE, catgeoryFilter)} className="img img__skincare" handleClick={fetchFilterType} categoryID={FILTER_SKIN_CARE}>
        SKINCARE
      </FilterCard>
    </div>
  );
}

FilterMenu.propTypes = {
  catgeoryFilter: PropTypes.string,
  fetchFilter: PropTypes.func,
};

FilterMenu.defaultProps = {
  catgeoryFilter: '',
  fetchFilter: () => {},
};
