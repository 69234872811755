import PropTypes from 'prop-types';
import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

function ProgressStepper(props) {
  const { activeStep, steps, handleBack } = props;

  const isActive = (step) => {
    if (step === activeStep) {
      return true;
    }
    return false;
  };

  const handleClick = (isActive) => {
    if (!isActive) {
      handleBack();
    }
  };

  const isDisabled = () => {
    if (activeStep !== 1) {
      return false;
    }
    return true;
  };

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps = {};
        const labelProps = {};

        return (
          <Step key={label} disabled={!isDisabled()} {...stepProps} active={isActive(index)}>
            <StepLabel
              active={isActive(index)}
              className={!isActive(index) ? 'pointer' : ''}
              onClick={(e) => handleClick(isActive(index))}
              {...labelProps}
            >
              {label}

            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

ProgressStepper.defaultProps = {
  activeStep: 0,
  steps: [],
};

ProgressStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf,
};

export default ProgressStepper;
